import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody,Row,Col, } from 'reactstrap';
import imgUrl from "../assets/images/imgUrl.js";

const ModalExample = (props) => {
  const {
    
    className,
   
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div className="modalExample">
     
      <p style={{fontSize: "16px" , letterSpacing: "1px" , textDecoration:"underline" , cursor:"pointer" }} 
                onClick={toggle}>
                  {/* <Button
                        onClick={() => showDashboardTemplate("4")}
                        className="explorealltempbtn btnexploresmall"
                      >
                        <span>EXPLORE</span>
                      </Button> */}
                      <img
                  className=""
                  alt=""
                  src={imgUrl.right_orangearrow}
                  style={{ width: "10px", marginRight: "4px" , marginTop:"-5px" }}
                />{" "}
                
                WHO IS RTI INTERNATIONAL?
                
                </p>
      <div className="modalPartner">       
       <Modal isOpen={modal} toggle={toggle} className={className} size="lg">
       
       
        <ModalBody style={{paddingTop:"0rem" , paddingBottom: "0rem"}}>
           <Row>
             <Col sm="5" className="modalCol">
              <div className="modalLogo">

                  <img
                      className="img-fluid"
                      alt="rtiLogo"
                      src={imgUrl.RTI_whiteLogo}
                    />
              </div>
            </Col>
            <Col sm="7" className="modalCol2">
            <div className="modalContent">
            {/* <button type="button" class="close" aria-label="Close">
           
            <span aria-hidden="true">&times;</span>
            </button> */}
             <ModalHeader toggle={toggle}></ModalHeader>
             <p className="modalHeading">RTI International</p>
            <p className="modalPara">
              RTI International leverages multidisciplinary technical expertise, 
              research capacity, and programmatic experience to prevent and manage the negative health, 
              economic, and social impacts of NCDs. RTI’s Center for Global Noncommunicable Diseases is 
              committed to working towards achieving the target outlined in the United Nations Sustainable
              Development Goals of reducing premature mortality from NCDs by one third by 2030.
            </p>  
            <p className="modalSectionSite">
              <a href="https://www.rti.org/" target="_blank">Visit the RTI HomePage</a>
            </p>
            </div> 
            </Col>
          </Row>
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter> */}
      </Modal>
      </div>   
    </div>
  );
}

export default ModalExample;