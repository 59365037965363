import React, { useEffect, useState } from "react";

import { Container, Row, Col, Button } from "reactstrap";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

function RegionDistribution(props) {
    const regionList = props.regionList ? props.regionList : [];
    const [regionArr, setRegionArr] = useState([]);
    const [cancerList, setCancerList] = useState([]);
    const [cvdList, setCVDList] = useState([]);
    const [diabetesList, setDiabetesList] = useState([]);
    const [respiratoryList, setRespiratoryList] = useState([]);
    const [otherList, setOtherList] = useState([]);

    const colors = ['#6aa84f','#2986cc','#f1c232','#cc0000','#67074E'];

    const RegionData = (list) => {
        const regionArray = [],
        cancerArr = [], cvdArr = [], diabetesArr = [], respiratoryArr = [], otherArr = [];
        list.map((region) => {
            regionArray.push(region.Region_name);
            region.NCDs.map((ncds) => {
                if(ncds.ncd_id === 1) { cancerArr.push(ncds["no of programs"] ? ncds["no of programs"] : 0)  }
                if(ncds.ncd_id === 2) { cvdArr.push(ncds["no of programs"] ? ncds["no of programs"] : 0)  }
                if(ncds.ncd_id === 3) { diabetesArr.push(ncds["no of programs"] ? ncds["no of programs"] : 0)  }
                if(ncds.ncd_id === 4) { respiratoryArr.push(ncds["no of programs"] ? ncds["no of programs"] : 0)  }
                if(ncds.ncd_id === 5) { otherArr.push(ncds["no of programs"] ? ncds["no of programs"] : 0)  }
            })
        });
        setRegionArr(regionArray);
        setCancerList(cancerArr);
        setCVDList(cvdArr);
        setDiabetesList(diabetesArr);
        setRespiratoryList(respiratoryArr);
        setOtherList(otherArr);
    }

    useEffect(() => {
        RegionData(regionList);
    },[regionList])

    const options = {
        chart: {
            backgroundColor: '#343332',
			type: 'bar',
			margin: [60, 120, 120, 120]
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: regionArr,
            labels: {
                style: {
                    color: '#ffffff',
                    fontSize: '12px',
                    fontFamily: 'BrandonGrotesque-Regular'
                }
            }
        },
        yAxis: {
            min: 0,
            tickInterval: 10,
            title: {
                text: ' ',
            },
            labels: {
				style: {
					color: '#ffffff'
				}
			}
        },
        legend: {
            reversed: true,
            itemStyle: {
                color: "#fff",
                fontFamily: 'BrandonGrotesque-Regular'
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        credits: {
			enabled: false
		},
        series:  [   {
                name: 'Other',
                data: otherList,
                color:'#52B335'
            }, {
                name: 'Respiratory',
                data: respiratoryList,
                color:'#015C84'
            },{
                name: 'Diabetes',
                data: diabetesList,
                color:'#FFBA18'
            },{
                name: 'CVDs',
                data: cvdList,
                color:'#C41111'
            },{
                name: 'Cancer',
                data: cancerList,
                color:'#930C62'
            }]
    }

    

    return (
        <>
            <Row>
                <Col lg="12" className="chartHeaderText">
                    <div className="">
                        <p className="chart-title mb-0">PROGRAM DISTRIBUTION BY NCDs AND WHO REGION</p>
                    </div>
                </Col>
                <Col lg="12" className="chart-holder">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </Col>
            </Row>
        </>
    )
}

export default RegionDistribution;
