import React, { useEffect, useState } from "react";

import { Container, Row, Col, Button } from "reactstrap";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

function NcdDistribution({ ncdProgramCount, ncdNames, filterNCDs }) {
  const [getNCDNames,setNCDAllNames] = useState([]);
  const [getProgramCount,setProgramCount] = useState([]);

  const [ncdNamesArr,setNcdNames] = useState([]);
  const [progCount,setProgCount] = useState([]);
  const [filterColorArr,setColorArr] = useState([]);
  const CancerArr = ['Cancer', 'Cardiovascular Disease', 'Diabetes', 'Respiratory Diseases', 'Other'];

  const AllData = () => {
    const ProgramCount = ncdProgramCount ? ncdProgramCount : [];
    const NCDNames = ncdNames ? ncdNames : [];

    setNCDAllNames(NCDNames);
    setProgramCount(ProgramCount);

  }

  const filterNCDFun = (getNCDArr) => {
    setColorArr([])
    if(getNCDArr && getNCDArr.length > 0) {
      let Count = getNCDArr[0].NCDs && getNCDArr[0].NCDs.map((a) => a["no of programs"]);
      let Names = getNCDArr[0].NCDs && getNCDArr[0].NCDs.map((b) => b.ncd_name);
      if(Names && Names.length < 5 && Names.length > 0) {
        filterColor(Names);
      }
      setNcdNames(Names);
      setProgCount(Count);
    }
  }

  const filterColor = (arr) => {
    let colorArr = [];
    if(arr.includes(CancerArr[0])) {
      colorArr.push("#930C62")
    } 
    if (arr.includes(CancerArr[1])) {
      colorArr.push("#C41111")
    } 
    if (arr.includes(CancerArr[2])) {
      colorArr.push("#FFBA18")
    } 
    if (arr.includes(CancerArr[3])) {
      colorArr.push("#015C84")
    } 
    if (arr.includes(CancerArr[4])) {
      colorArr.push("#52B335")
    }
    setColorArr(colorArr);
  }

  useEffect(() => {
    filterNCDFun(filterNCDs)
  },[filterNCDs])

  useEffect(() => {
    AllData()
  },[ncdProgramCount, ncdNames])

  const options = {
    chart: {
      backgroundColor: "#343332",
      type: "bar",
      margin: [60, 120, 80, 120],
    },
    title: {
      text: "",
    },

    xAxis: {
      categories: getNCDNames,
      labels: {
        style: {
          color: "#ffffff",
          fontFamily: "Sentinel-Book",
          fontSize: "14px",
        },
      },
    },
    yAxis: {
      min: 0,
      tickInterval: 10,
      title: {
        text: "No. of programs",
        style: {
          color: "#ffffff",
          fontFamily: "BrandonGrotesque-Regular",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    legend: {
      enabled: false,
      //reversed: true
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        //stacking: 'normal',
        colorByPoint: true,
        colors: ["#930C62", "#C41111", "#FFBA18", "#015C84", "#52B335"],
      },
    },

    series: [
      {
        name: "",
        data: getProgramCount,
      },
    ],
  };

  const filterOptions = {
    chart: {
      backgroundColor: "#343332",
      type: "bar",
      margin: [60, 120, 80, 120],
    },
    title: {
      text: "",
    },

    xAxis: {
      categories: ncdNamesArr && ncdNamesArr.length>0 ? ncdNamesArr : [],
      labels: {
        style: {
          color: "#ffffff",
          fontFamily: "Sentinel-Book",
          fontSize: "14px",
        },
      },
    },
    yAxis: {
      min: 0,
      tickInterval: 10,
      title: {
        text: "No. of programs",
        style: {
          color: "#ffffff",
          fontFamily: "BrandonGrotesque-Regular",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    legend: {
      enabled: false,
      //reversed: true
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        //stacking: 'normal',
        colorByPoint: true,
        colors: filterColorArr.length > 0 && filterColorArr.length < 5 ? filterColorArr : ["#930C62", "#C41111", "#FFBA18", "#015C84", "#52B335"],
      },
    },

    series: [
      {
        name: "",
        data: progCount && progCount.length > 0 ? progCount : [],
      },
    ],
  };

  return (
    <>
      <Row>
        <Col lg="12" className="chartHeaderText">
          <div className="">
            <p className="chart-title mb-0">PROGRAM DISTRIBUTION BY NCDs</p>
          </div>
        </Col>
        <Col lg="12" className="chart-holder">
          {/* <div className="">
						Chart holder
					</div> */}
          <HighchartsReact highcharts={Highcharts} options={progCount && progCount.length>0 ? filterOptions : options} />
        </Col>
      </Row>
    </>
  );
}

export default NcdDistribution;
