import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import classnames from "classnames";
import imgUrl from "../assets/images/imgUrl.js";
import { Redirect, useHistory } from "react-router-dom";
import AppNavbar from "../components/navbar/AppNavbar";
import AppNavbarDashboard from "../components/navbar/AppNavbarDashboard";
import SecondaryNavbar from "../components/navbar/SecondaryNavbar";
//import SecondaryNavbarDashboard from "../components/navbar/SecondaryNavbarDashboard";
import Dashboard from "../components/Dashboard";
//import { Helmet } from "react-helmet";

function LandingPage({setAuth}) {
  const [activeTab, setActiveTab] = useState("1");
  const [activeBiTab, setActiveBiTab] = useState(false);
  const [updateNavbar, setUpdateNavbar] = useState(1);

  let landingPageIs = localStorage.getItem("landingPageIs");
  let isOnClick = localStorage.getItem("onClick");
  let history = useHistory();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const topicOptions = [
    { key: "Cancer" },
    { key: "Cardiovascular Disease" },
    { key: "Diabetes" },
    { key: "Other" },
    { key: "Respiratory Diseases" },
  ];

  const regionOptions = [
    { key: "Africa" },
    { key: "Americas" },
    { key: "Eastern Mediterranean" },
    { key: "Europe" },
    { key: "Global Commitment" },
    { key: "South-East Asia" },
    { key: "Western Pacific" },
  ];

  const countryOptions = [
    { key: "Afghanistan" },
    { key: "Albania" },
    { key: "Algeria" },
    { key: "Angola" },
    { key: "Armenia" },
    { key: "Bangladesh" },
    { key: "Belarus" },
    { key: "Benin" },
    { key: "Bhutan" },
    { key: "Bolivia (Plurinational State of)" },
    { key: "Bosnia and Herzegovina" },
    { key: "Botswana" },
    { key: "Brazil" },
    { key: "Burkina Faso" },
    { key: "Burundi" },
    { key: "Cabo Verde" },
    { key: "Cambodia" },
    { key: "Cameroon" },
    { key: "Central African Republic" },
    { key: "Chad" },
    { key: "Chile" },
    { key: "China" },
    { key: "Colombia" },
    { key: "Comoros" },
    { key: "Congo" },
    { key: "Côte d'Ivoire" },
    { key: "Democratic People's Republic of Korea" },
    { key: "Democratic Republic of the Congo" },
    { key: "Djibouti" },
    { key: "Ecuador" },
    { key: "Egypt" },
    { key: "El Salvador" },
    { key: "Equatorial Guinea" },
    { key: "Eritrea" },
    { key: "Ethiopia" },
    { key: "Gabon" },
    { key: "Gambia" },
    { key: "Georgia" },
    { key: "Ghana" },
    { key: "Global Commitment" },
    { key: "Guatemala" },
    { key: "Guinea" },
    { key: "Guinea-Bissau" },
    { key: "Guyana" },
    { key: "Haiti" },
    { key: "Honduras" },
    { key: "Hong Kong" },
    { key: "Hungary" },
    { key: "India" },
    { key: "Indonesia" },
    { key: "Japan" },
    { key: "Jordan" },
    { key: "Kazakhstan" },
    { key: "Kenya" },
    { key: "Kiribati" },
    { key: "Kosovo" },
    { key: "Kyrgyzstan" },
    { key: "Lao People's Democratic Republic" },
    { key: "Lebanon" },
    { key: "Lesotho" },
    { key: "Liberia" },
    { key: "Madagascar" },
    { key: "Malawi" },
    { key: "Malaysia" },
    { key: "Mali" },
    { key: "Mauritania" },
    { key: "Mauritius" },
    { key: "Mexico" },
    { key: "Micronesia (Federated States of)" },
    { key: "Mongolia" },
    { key: "Morocco" },
    { key: "Mozambique" },
    { key: "Myanmar" },
    { key: "Namibia" },
    { key: "Nepal" },
    { key: "Nicaragua" },
    { key: "Niger" },
    { key: "Nigeria" },
    { key: "Pakistan" },
    { key: "Panama" },
    { key: "Papua New Guinea" },
    { key: "Paraguay" },
    { key: "Peru" },
    { key: "Philippines" },
    { key: "Poland" },
    { key: "Republic of Moldova" },
    { key: "Romania" },
    { key: "Rwanda" },
    { key: "Samoa" },
    { key: "Sao Tome and Principe" },
    { key: "Senegal" },
    { key: "Seychelles" },
    { key: "Sierra Leone" },
    { key: "Singapore" },
    { key: "Solomon Islands" },
    { key: "Somalia" },
    { key: "South Africa" },
    { key: "South Sudan" },
    { key: "Sri Lanka" },
    { key: "Sudan" },
    { key: "Swaziland" },
    { key: "Syrian Arab Republic" },
    { key: "Taiwan, Province of China" },
    { key: "Tajikistan" },
    { key: "Thailand" },
    { key: "Timor-Leste" },
    { key: "Togo" },
    { key: "Tunisia" },
    { key: "Turkmenistan" },
    { key: "Tuvalu" },
    { key: "Uganda" },
    { key: "Ukraine" },
    { key: "United Arab Emirates" },
    { key: "United Republic of Tanzania" },
    { key: "Uzbekistan" },
    { key: "Vanuatu" },
    { key: "Venezuela (Bolivarian Republic of)" },
    { key: "Viet Nam" },
    { key: "West Bank and Gaza" },
    { key: "Yemen" },
    { key: "Zambia" },
    { key: "Zimbabwe" },
  ];

  const strategyOptions = [
    { key: "Community Awareness and Linkage to Care" },
    { key: "Financing" },
    { key: "Health Service Delivery" },
    { key: "Health Service Strengthening" },
    { key: "Licensing Agreements" },
    { key: "Manufacturing" },
    { key: "Medicine Donation" },
    { key: "Price Schemes" },
    { key: "Product Development Research" },
    { key: "Regulation and Legislation" },
    { key: "Supply Chain" },
  ];

  const beneficiaryOptions = [
    { key: "Children" },
    { key: "Elderly" },
    { key: "Marginalized/indigenous people" },
    { key: "Other" },
    { key: "People with low incomes" },
    { key: "Rural populations" },
    { key: "Youth" },
  ];

  const dataSourceOptions = [
    { key: "Access Observatory" },
    { key: "AO/GHP" },
    { key: "GHP" },
    { key: "LIVE PORTAL" },
  ];

  const [topicSelected, setTopicSelected] = useState([]);
  const [regionSelected, setRegionSelected] = useState([]);
  const [countrySelected, setCountrySelected] = useState([]);
  const [strategySelected, setStrategySelected] = useState([]);
  const [beneficiarySelected, setBeneficiarySelected] = useState([]);
  const [dataSourceSelected, setDataSourceSelected] = useState([]);
  const [launchDashboard, setLaunchDashboard] = useState(0);
  //const [clearFilterVariable, setClearFilter] = useState(0);

  //Placeholder
  const [topicPlaceholder, setTopicPlaceholder] = useState("Topics");
  const [regionPlaceholder, setRegionPlaceholder] = useState("Regions");
  const [countryPlaceholder, setCountryPlaceholder] = useState("Countries");
  const [strategyPlaceholder, setStrategyPlaceholder] = useState("Strategies");
  const [beneficiaryPlaceholder, setBeneficiaryPlaceholder] = useState(
    "Key Demographics"
  );
  const [dataSourcePlaceholder, setDataSourcePlaceholder] = useState(
    "Data Sources"
  );

  // Layout Views
  //const [defaultViewIsActive, setDefaultView] = useState("active");
  //const [minimalViewIsActive, setMinimalView] = useState("");
  //const [detailedViewIsActive, setDetailedView] = useState("");
  const [layoutSelected, setLayoutSelected] = useState("default");

  const topicDDRef = useRef(null);
  const regionDDRef = useRef(null);
  const countryDDRef = useRef(null);
  const strategyDDRef = useRef(null);
  const beneficiaryDDRef = useRef(null);
  const dataSourceDDRef = useRef(null);

  // Add option from dropdown
  function onTopicSelect(val) {
    setTopicSelected(val);

    //console.log(topicSelected);
    setTopicPlaceholder("+");
  }

  function onRegionSelect(val) {
    setRegionSelected(val);
    setRegionPlaceholder("+");
  }

  function onCountrySelect(val) {
    setCountrySelected(val);
    setCountryPlaceholder("+");
  }

  function onStrategySelect(val) {
    setStrategySelected(val);
    setStrategyPlaceholder("+");
  }

  function onBeneficiarySelect(val) {
    setBeneficiarySelected(val);
    setBeneficiaryPlaceholder("+");
  }

  function onDataSourceSelect(val) {
    setDataSourceSelected(val);
    setDataSourcePlaceholder("+");
  }

  // Remove option from dropdown
  function clearFilter(type) {
    // setClearFilter((prevClearFilterVariable) => prevClearFilterVariable + 1);
    //console.log(topicSelected);

    if (type === "topics") {
      if (topicSelected.length === 0) {
        topicDDRef.current.resetSelectedValues();
        setTopicPlaceholder("Topics");
      }
    } else if (type === "region") {
      if (regionSelected.length === 0) {
        regionDDRef.current.resetSelectedValues();
        setRegionPlaceholder("Regions");
      }
    } else if (type === "country") {
      if (countrySelected.length === 0) {
        countryDDRef.current.resetSelectedValues();
        setCountryPlaceholder("Countries");
      }
    } else if (type === "strategy") {
      if (strategySelected.length === 0) {
        strategyDDRef.current.resetSelectedValues();
        setStrategyPlaceholder("Strategies");
      }
    } else if (type === "beneficiary") {
      if (beneficiarySelected.length === 0) {
        beneficiaryDDRef.current.resetSelectedValues();
        setBeneficiaryPlaceholder("Beneficiaries");
      }
    } else if (type === "dataSource") {
      if (dataSourceSelected.length === 0) {
        dataSourceDDRef.current.resetSelectedValues();
        setDataSourcePlaceholder("Data Sources");
      }
    }
  }

  if (landingPageIs === "dashboard" && isOnClick === "navbarClick") {
    setLaunchDashboard((prevLaunchDashboard) => prevLaunchDashboard + 1);
    localStorage.setItem("onClick", true);
  }

  function showDashboard() {
    setLaunchDashboard((prevLaunchDashboard) => prevLaunchDashboard + 1);
    setLandingPage("dashboard");

    localStorage.setItem("activePage", "ncdDashboard");
    setUpdateNavbar(updateNavbar + 1);
  }

  function showDashboardTemplate(templateID) {
    console.log(templateID);
    if (templateID === "1") {
      setTopicSelected([{ key: "Cancer" }]);
      setRegionSelected([{ key: "Africa" }]);

      setLaunchDashboard((prevLaunchDashboard) => prevLaunchDashboard + 1);
      setLandingPage("dashboard");
    } else if (templateID === "2") {
      setCountrySelected([{ key: "India" }]);
      setBeneficiarySelected([{ key: "Children" }]);
      setLaunchDashboard((prevLaunchDashboard) => prevLaunchDashboard + 1);
      setLandingPage("dashboard");
    } else if (templateID === "3") {
      setTopicSelected([{ key: "Diabetes" }]);
      setStrategySelected([{ key: "Medicine Donation" }]);
      setLaunchDashboard((prevLaunchDashboard) => prevLaunchDashboard + 1);
      setLandingPage("dashboard");
    }
  }

  function changeView(view) {
    console.log(view);
    if (view === "default") {
      //setDefaultView("active");
      // setMinimalView("");
      // setDetailedView("");
      setLayoutSelected("default");
    } else if (view === "minimal") {
      // setDefaultView("");
      // setMinimalView("active");
      // setDetailedView("");
      setLayoutSelected("minimal");
    } else {
      // setDefaultView("");
      // setMinimalView("");
      // setDetailedView("active");
      setLayoutSelected("default"); // change after detailed layout is completed
    }
  }

  const setLandingPage = (page) => {
    localStorage.setItem("landingPageIs", page);
    localStorage.setItem("onClick", true);
  };

  const routeChange = (page) => {
    let path = `/overview-dashboard`;
    localStorage.setItem("landingPageIs", page);
    // localStorage.setItem("onClick", "navbarClick");
    if (page === "dashboard") {
      setActivePage("ncdDashboard");
      history.push(path);
    } else {
      setActivePage("home");
    }
  };

  const setActivePage = (page) => {
    localStorage.setItem("activePage", page);
  };

  const activeDashboard = () => {
    const pageUrl = window.location.href;

    let pathArray = pageUrl.split("/");
    console.log("pageUrl Array", pathArray[4]);
    if (
      pathArray &&
      (pathArray[3] == "programs" ||
        pathArray[3] == "members" ||
        pathArray[3] == "ncds" ||
        pathArray[3] == "countries")
    ) {
      setActiveBiTab(true);
    } else {
      setActiveBiTab(false);
    }
  };

  const dashboardLink = () => {
    let path = `/overview-dashboard`;
    history.push(path);
  };

  useEffect(() => {
    activeDashboard();
  }, []);

  // if (landingPageIs == "dashboard") {
  //   setLaunchDashboard((prevLaunchDashboard) => prevLaunchDashboard + 1);
  // }

  return (
    <>
      {(isOnClick === true && landingPageIs === "dashboard") ||
      launchDashboard > 0 ||
      activeBiTab ? (
        <>
          <AppNavbarDashboard key={updateNavbar} />
          <Dashboard
            //allMembers={memberOptions}
            //memberSelected={memberSelected}
            allTopics={topicOptions}
            topicsSelected={topicSelected}
            allRegions={regionOptions}
            regionSelected={regionSelected}
            allCountries={countryOptions}
            countrySelected={countrySelected}
            allStrategies={strategyOptions}
            strategySelected={strategySelected}
            allBeneficiaries={beneficiaryOptions}
            beneficiarySelected={beneficiarySelected}
            allDataSource={dataSourceOptions}
            dataSourceSelected={dataSourceSelected}
            //clearFilterVariable={clearFilterVariable}
            layoutSelected={layoutSelected}
          />
        </>
      ) : (
        <>
          <AppNavbar key={updateNavbar} />
          <SecondaryNavbar />
          <div className="mastheader-holder masterWrap">
            <Container>
              <Row>
                <Col lg="12">
                  <div className="heading">
                    <h1 className="partial-underline">AA Open Platform</h1>
                  </div>
                  <div className="mast-description">
                    <p>
                      {/* We are actively addressing NCD prevention, treatment and
                      care through{" "}
                      <span className="">
                        over 100 public health programmes
                      </span>{" "}
                      by strengthening health systems in low-and-middle-income
                      countries and bringing together the expertise of the
                      biopharmaceutical sector with over{" "}
                      <span className="">20 member companies.</span> 
                      The Open Platform (AAOP) is a knowledge resource and
                      dashboard that provides a centralized information
                      repository of action on non-communicable diseases (NCDs).
                      It is designed to inform users about NCD programs,
                      catalyze biopharmaceutical industry NCD action in low- and
                      middle-income countries (LMICs) and guide members and
                      partners to adapt their work amid the COVID-19 pandemic.
                      Since its launch in December 2018, the AAOP has
                      demonstrated the industry’s commitment to accountability
                      and measurement.*/}
                      The Open Platform (AAOP) is a knowledge resource and dashboard that provides a centralized information repository of action on non-communicable diseases (NCDs). It is designed to inform users about NCD programs, catalyze biopharmaceutical industry NCD action in low- and middle-income countries (LMICs), and guide members and partners to adapt their work amid the COVID-19 pandemic. Since its launch in December 2018 and up until its last update in 2021, the AAOP demonstrated the industry’s commitment to accountability and measurement.
                    </p>
                    <p style={{fontStyle:'italic'}}>
                    While it will no longer receive updates or new data, it continues to serve as a valuable resource, providing access to all information and data gathered up until 2021.
                    </p>
                    <p>
                    For the most recent updates and developments, please visit <a target="_blank" href="https://globalhealthprogress.org/">Global Health Progress,</a> an interactive resource hub highlighting the innovative support of the pharmaceutical industry for the Sustainable Development Goals (SDGs). 
                    </p>
                  </div>
                </Col>
               {/* <Col lg="5" className="mastimgholder">
                  <div className="mast-imagecontainer">
                    <div>
                      <img
                        className="stoptblogoimg"
                        alt="stopTB"
                        src={imgUrl.homeheroimg2}
                      />
                    </div>
                  </div>
                </Col> */}
              </Row>
            </Container>
          </div>

          <div className="dashboardtemplate-holder d-none">
            <Container>
              <div className="mt-4 mb-0 exploreallbtnholder">
                <Row>
                  <Col lg="12">
                    <div className="text-center">
                      {/* <a href="" className="explorealltempbtn">
              LAUNCH DASHBOARD
            </a> */}
                      <Button
                        className="explorealltempbtn"
                        onClick={showDashboard}
                        style={{ marginTop: "0.5rem" }}
                      >
                        LAUNCH DASHBOARD
                      </Button>
                      <p className="text-center db-description mt-5">
                        Explore more ways to customize our data with our
                        dashboard gallery or build your own...
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col lg="12">
                  <div className="customtabsholder">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          <span>DASHBOARD GALLERY</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          <span>BUILD MY OWN DASHBOARD</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div className="w-100">
                          <div className="dashboardtemplate-holder">
                            <Container>
                              <Row>
                                <Col lg="12">
                                  <div className="heading">
                                    <p className="text-center db-description">
                                      A quick way to explore and understand our
                                      vast repository of data with example data
                                      sets.
                                    </p>
                                  </div>
                                </Col>
                                <Col lg="12">
                                  <div className="mt-4 mb-4">
                                    <Row>
                                      <Col
                                        lg="4"
                                        md="6"
                                        className="dbtempcardholder"
                                      >
                                        <div className="dbtemplate-card">
                                          <div>
                                            <img
                                              className="img-fluid"
                                              alt="runningman"
                                              src={imgUrl.progone}
                                            />
                                          </div>
                                          <div className="dbtemplateinfo-container">
                                            <h3>Cancer Programmes in Africa</h3>
                                          </div>
                                          <span
                                            className="viewmore-link"
                                            onClick={() =>
                                              showDashboardTemplate("1")
                                            }
                                          >
                                            Launch Dashboard
                                          </span>
                                        </div>
                                      </Col>
                                      <Col
                                        lg="4"
                                        md="6"
                                        className="dbtempcardholder"
                                      >
                                        <div className="dbtemplate-card">
                                          <div>
                                            <img
                                              className="img-fluid"
                                              alt="runningman"
                                              src={imgUrl.progtwo}
                                            />
                                          </div>
                                          <div className="dbtemplateinfo-container">
                                            <h3>
                                              All programmes in India for
                                              children
                                            </h3>
                                          </div>
                                          <span
                                            className="viewmore-link"
                                            onClick={() =>
                                              showDashboardTemplate("2")
                                            }
                                          >
                                            Launch Dashboard
                                          </span>
                                        </div>
                                      </Col>
                                      <Col
                                        lg="4"
                                        md="6"
                                        className="dbtempcardholder"
                                      >
                                        <div className="dbtemplate-card">
                                          <div>
                                            <img
                                              className="img-fluid"
                                              alt="runningman"
                                              src={imgUrl.progthree}
                                            />
                                          </div>
                                          <div className="dbtemplateinfo-container">
                                            <h3>
                                              Diabetes programmes for all
                                              countries deploying medicine
                                              donation
                                            </h3>
                                          </div>
                                          <span
                                            className="viewmore-link"
                                            onClick={() =>
                                              showDashboardTemplate("3")
                                            }
                                          >
                                            Launch Dashboard
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>

                              <div className="mt-5 mb-5 exploreallbtnholder">
                                <Row>
                                  <Col lg="12" className="mt-2">
                                    <div className="text-center">
                                      <a
                                        href="/home"
                                        className="explorealltempbtn"
                                      >
                                        EXPLORE MORE DASHBOARDS
                                      </a>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Container>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId="2">
                        <div className="dashboardtemplate-holder">
                          <Container>
                            <Row>
                              <Col lg="12">
                                <div className="heading">
                                  <p
                                    className="text-center db-description"
                                    style={{ margin: "0.5rem" }}
                                  >
                                    Customize the Access Accelerated Open
                                    Programme data to your specific needs.
                                  </p>
                                  <p
                                    className="text-center db-description"
                                    style={{ marginTop: "0rem" }}
                                  >
                                    Please fill in the blanks in the following
                                    question:
                                  </p>
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mt-5 mb-5">
                                  <Row className="d-flex align-items-center justify-content-center">
                                    <Col lg="4" className="peopleimgholder">
                                      <div>
                                        <img
                                          className="img-fluid"
                                          alt="aapeople"
                                          src={imgUrl.aapeople}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="8">
                                      <div className="">
                                        <p className="builddbtext">
                                          I'm looking for data about...
                                        </p>
                                        <div className="builddbformcontainer row">
                                          <Multiselect
                                            id="topic-filter"
                                            options={topicOptions}
                                            displayValue="key"
                                            //groupBy="cat"
                                            showCheckbox={true}
                                            value={topicSelected}
                                            onSelect={(val) => {
                                              onTopicSelect(val);
                                            }}
                                            onRemove={() => {
                                              clearFilter("topics");
                                            }}
                                            placeholder={topicPlaceholder}
                                            ref={topicDDRef}
                                          />

                                          <span className="mr-20px">
                                            across
                                          </span>
                                          <Multiselect
                                            id="region-filter"
                                            options={regionOptions}
                                            displayValue="key"
                                            showCheckbox={true}
                                            value={regionSelected}
                                            onSelect={(val) => {
                                              onRegionSelect(val);
                                            }}
                                            onRemove={() => {
                                              clearFilter("region");
                                            }}
                                            placeholder={regionPlaceholder}
                                            ref={regionDDRef}
                                          />

                                          <span className="mr-20px">from</span>
                                          <Multiselect
                                            id="country-filter"
                                            options={countryOptions}
                                            displayValue="key"
                                            showCheckbox={true}
                                            value={countrySelected}
                                            onSelect={(val) => {
                                              onCountrySelect(val);
                                            }}
                                            onRemove={() =>
                                              clearFilter("country")
                                            }
                                            placeholder={countryPlaceholder}
                                            ref={countryDDRef}
                                          />

                                          <span className="mr-20px">
                                            deploying
                                          </span>
                                          <Multiselect
                                            id="strategy-filter"
                                            options={strategyOptions}
                                            displayValue="key"
                                            showCheckbox={true}
                                            value={strategySelected}
                                            onSelect={(val) => {
                                              onStrategySelect(val);
                                            }}
                                            onRemove={() =>
                                              clearFilter("strategy")
                                            }
                                            placeholder={strategyPlaceholder}
                                            ref={strategyDDRef}
                                          />

                                          <span className="mr-20px">for</span>
                                          <Multiselect
                                            id="beneficiary-filter"
                                            options={beneficiaryOptions}
                                            displayValue="key"
                                            showCheckbox={true}
                                            value={beneficiarySelected}
                                            onSelect={(val) => {
                                              onBeneficiarySelect(val);
                                            }}
                                            onRemove={() =>
                                              clearFilter("beneficiary")
                                            }
                                            placeholder={beneficiaryPlaceholder}
                                            ref={beneficiaryDDRef}
                                          />
                                          <span className="mr-20px">from</span>

                                          <Multiselect
                                            id="data-source-filter"
                                            options={dataSourceOptions}
                                            displayValue="key"
                                            showCheckbox={true}
                                            value={dataSourceSelected}
                                            onSelect={(val) => {
                                              onDataSourceSelect(val);
                                            }}
                                            onRemove={() =>
                                              clearFilter("dataSource")
                                            }
                                            placeholder={dataSourcePlaceholder}
                                            ref={dataSourceDDRef}
                                          />
                                        </div>
                                        {/* <p className="infotext">
                                          {" "}
                                          <img
                                            className="img-fluid d-inline bulbimg"
                                            alt="bulb"
                                            src={imgUrl.bulb}
                                          />{" "}
                                          HOVER FOR MORE INFO AND CUSTOMIZE THE
                                          DATA YOU WOULD LIKE TO VISUALIZE
                                        </p> */}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>

                        <Row>
                          <Col lg="12">
                            <div className="mt-0">
                              <div className="d-flex justify-content-center dashboardRadioBtn">
                                <FormGroup
                                  tag="fieldset"
                                  className="form-check-inline"
                                >
                                  <FormGroup check className="">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="radio1"
                                        defaultChecked
                                        onClick={() => changeView("default")}
                                      />{" "}
                                      Default View
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check className="">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="radio1"
                                        onClick={() => changeView("minimal")}
                                      />{" "}
                                      Minimal View
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check className="">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="radio1"
                                        onClick={() =>
                                          changeView("lowBandwidth")
                                        }
                                      />{" "}
                                      Low Bandwidth View
                                    </Label>
                                  </FormGroup>
                                </FormGroup>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div className="mb-5 mt-4 launchdbbtnholder">
                          <Row>
                            <Col lg="12">
                              <div className="text-center">
                                {/* <a href="" className="explorealltempbtn">
          LAUNCH CUSTOM DASHBOARD
        </a> */}
                                <Button
                                  className="explorealltempbtn"
                                  onClick={showDashboard}
                                >
                                  LAUNCH CUSTOM DASHBOARD
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div
            id="section1"
            className="dashboardtemplate-holder resourceholder section1Dash landingPagenewui"
          >
            <Container>
              <div className="mt-4 mb-0 exploreallbtnholder">
                <Row>
                  <Col lg="6" md="4">
                    <div className="text-center">
                      <div className="portalBanner" onClick={dashboardLink} style={{cursor: 'pointer'}}>
                      <img
                        className="img-fluid"
                        alt="Analytical Dashboard"
                        src={imgUrl.ncdDashboard}
                        style={{ width: "100%" }}
                      />
                      </div>
                    </div>
                  </Col>
                  <Col lg="6" md="8" className="resourceinfo">
                    <div className="text-left">
                      <p className="text-left sectionsubtitle partial-underline">
                        Program Archive
                      </p>
                      {/* <p className="text-left sectiontitle">Program Portal</p> */}
                      <p className="text-left sectiontitle">NCD Dashboard</p>
                      <p className="text-left db-description">
                        Interactive data about our members’ programs that expand access to NCD prevention, treatment and care.
                      </p>
                      <p className="text-left db-description">
                      Launch the <b>NCD Dashboard</b> to easily and quickly inform yourself about the different programs or deep-dive into the program data, view profiles, and compare the information with the <b>Analytical Dashboard</b>.
                      </p>
                      <p className="text-left db-description" style={{fontStyle:'italic'}}>Please note that the NCD Dashboard is no longer receiving updates or new data. The data presented was last updated in 2021.</p>

                      <div className="ncd-dashboard-section">
                        <p className="mt-2">
                          <a
                            href="/programs"
                            target=""
                            className="explorealltempbtn btnexploresmall sourcelink text-uppercase mr-4"
                            onClick={() => setLandingPage("dashboard")}
                            style={{ color: "#fff", fontSize: "14px" }}
                          >
                            ANALYTICAL DASHBOARD
                          </a>
                        </p>

                        <p>
                          <Button
                            className="explorealltempbtn btnexploresmall text-uppercase"
                            // onClick={showDashboard}
                            onClick={() => routeChange("dashboard")}
                            style={{ marginTop: "0.5rem" }}
                          >
                            NCD Dashboard
                          </Button>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <div
            id="section4"
            className="dashboardtemplate-holder resourceholder landingPagenewui"
          >
            <Container>
              <div className="mt-4 mb-0 exploreallbtnholder">
                <Row>
                  <Col lg="6" md="8" className="resourceinfo order-xs-2">
                    <div className="text-left">
                      <p className="text-left sectionsubtitle partial-underline digitalhealthsubtitle">
                        Partner
                      </p>
                      <p className="text-left sectiontitle">Partner Projects</p>
                      <p className="text-left db-description">
                      Access Accelerated drives sustainable solutions at scale for NCDs. Together with our members and five implementing partners, we follow the universal call for greater collective action in pursuit of the UN SDGs.
                      </p>
                      <p className="text-left db-description">
                      The work has been captured by RTI in an M&E framework to show our collective impact.
                      </p>
                      <p>
                        <a
                          href="/partner-module"
                          target=""
                          className="explorealltempbtn btnexploresmall btn text-white downloadPDF_btn text-uppercase"
                          onClick={() => setActivePage("partner-module")}
                        >
                          Partner Framework
                        </a>
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="4" className="order-xs-1">
                    <div className="text-center ">
                      <div className="text-center portallogodiv4"></div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <div
            id="section2"
            className="dashboardtemplate-holder mb-5 resourceholder landingPagenewui landingNewSec2bg"
          >
            <Container>
              <div className="mt-4 mb-0 exploreallbtnholder">
                <Row>
                  
                  <Col lg="6" md="4" className="order-xs-1">
                    <div className="text-center ">
                      <div className="text-center portallogodiv2"></div>
                    </div>
                  </Col>
                  <Col lg="6" md="8" className="resourceinfo order-xs-2">
                    <div className="text-left">
                      <p className="text-left sectionsubtitle partial-underline digitalhealthsubtitle">
                        COVID-19 Analysis
                      </p>
                      <p className="text-left sectiontitle">COVID-19 Portal</p>
                      <p className="text-left db-description">
                        The COVID-19 pandemic, is an ongoing global pandemic of
                        the coronavirus disease 2019 (COVID-19) caused by severe
                        acute respiratory syndrome coronavirus 2 (SARS-CoV-2).
                      </p>
                      <p className="text-left db-description">
                        We provide a number of analytical tools, which can be
                        found in our dedicated COVID-19 Portal
                      </p>
                      <p className="text-left db-description" style={{fontStyle:'italic'}}>
                      Please note that the COVID-19 portal is no longer receiving updates or new data. The data presented was last updated in 2021.
                      </p>
                      <p>
                        <a
                          href="/covid-19-portal"
                          target=""
                          className="explorealltempbtn btnexploresmall btn text-white downloadPDF_btn text-uppercase"
                          onClick={() => setActivePage("covid-19-portal")}
                        >
                          COVID-19 Portal
                        </a>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <div
            id="section3"
            className="dashboardtemplate-holder mb-5 resourceholder landingPagenewui"
          >
            <Container>
              <div className="mt-4 mb-0 exploreallbtnholder">
                <Row>
                  <Col lg="6" md="8" className="resourceinfo">
                    <div className="text-left">
                      <p className="text-left sectionsubtitle partial-underline">
                        Documents
                      </p>
                      <p className="text-left sectiontitle">Resource Library</p>
                      <p className="text-left db-description">
                        A repository of key analytical and strategic documents
                        that guide our work, including reports, academic
                        research and global/local NCD policies and strategies
                      </p>

                      <p>
                        <a
                          href="https://aaopenplatform.accessaccelerated.org/resource-library"
                          target=""
                          className="explorealltempbtn btnexploresmall btn text-white downloadPDF_btn text-uppercase"
                          onClick={() => setActivePage("resource-library")}
                        >
                          {" "}
                          Resource Library{" "}
                        </a>
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="4">
                    <div className="text-center">
                      <div className="portallogodiv3">
                        <div className=""></div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          {/*<div className="feedbackIconDiv">
            <a
              href="https://forms.office.com/r/dkKdjFgGeK"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-fluid"
                alt="runningman"
                src={imgUrl.feedback_icon}
                style={{ width: "90%" }}
              />
            </a>
      </div>*/}
          {/* <div className="cookies-footer hide">
              <div className="cookies-inside-content">
                <p className="cookies-text">
                  This website uses cookies to ensure you get the best experience
                  on our website.
              </p>
                <a className>LEARN MORE</a>

                <button className="btn cookies-btn">DECLINE</button>
                <button className="btn cookies-btn">ACCEPT COOKIES</button>
              </div>
            </div> */}
        </>
      )}
    </>
  );
}

export default LandingPage;
