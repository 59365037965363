const imgUrl = {
    'aalogo_new' : require('./aaop_logo_new.svg'), 
    'aalogo' : require('./aalogo.svg'),
    'runningman' : require('./runningman.jpg'),  
    'aapeople' : require('./aaop_platform_people.png'), 
    'bulb' : require('./light-bulb.svg'),
    'layoutone' : require('./aaop_dash_layout1.png'),  
    'layouttwo' : require('./aaop_dash_layout2.png'),  
    'layoutthree' : require('./aaop_dash_layout3.png'),
    'progone' : require('./prog1.jpg'),
    'progtwo' : require('./prog2.jpg'),
    'progthree' : require('./prog3.jpg'),  
    'supplychain' : require('./supplychain.png'),  
    'supplychain1' : require('./supplychain1.png'),
    'digitalhealth' : require('./digitalhealth.png'),  
    'digitalhealth1' : require('./digitalhealth1.png'),
    'primaryhealth' : require('./primaryhealth.png'),  
    'primaryhealth1' : require('./primaryhealth1.png'), 
    'homeheroimg' : require('./home_hero_aaop.jpg'),  
    'coronamask' : require('./coronamask.jpg'),  
    'blanksvg' : require('./blanksvg.svg'),  
    'ghanaflag' : require('./ghanaflag.png'),  
    'kenyaflag' : require('./kenyaflag.png'),  
    'vietnamflag' : require('./vietnamflag.png'),  
    'downloadarrow' : require('./downloadarrow.svg'),  
    'pp_logo' : require('./pp_logo.svg'),
    'aa_pp_logo' : require('./aa_pp_logo.svg'),
    'bristol_Myers_Squibb' : require('./bristol_Myers_Squibb.png'),
    'aa_in_action' : require('./aa_in_action.png'),
    'ao_badge_small' : require('./ao-badge-small.png'),
    'group_4974' : require('./group_4974.svg'),
    'group_4975' : require('./group_4975.svg'),
    'group_4976' : require('./group_4976.svg'),
    'dureLogo' : require('./dureLogo.png'),
    'ncd_img' : require('./ncd_img.svg'),
    'donut' : require('./donut.svg'),
    'homeheroimg2' : require('./hero_banner_2img.png'), 
    'left_arrow_orange' : require('./left-arrow-orange.svg'), 
    'homeIcon' : require('./homeIcon.svg'),
    'openMagazine' : require('./open-magazine.svg'),
    'pdf_img' : require('./pdf_img.png'),
    'download_img_icon' : require('./download_img_icon.svg'),
    'csv_icon' : require('./csv-file-format-extension.png'),
    'pdf_icon' : require('./pdf-file-format-symbol.png'),
    'left_arrow_white' : require('./left-arrow.png'),
    'right_arrow_orange' : require('./right-arrow.png'),
    'feedback_icon' : require('./feedback.svg'),
    'close_icon' : require('./close-icon.svg'),
    'user_white' : require('./user.png'),
    'member_prog_logo' : require('./member_prog_logo.svg'),
    'bene_pop' : require('./bene_pop.svg'),
    'mem_companies' : require('./mem_companies.svg'),
    'add_ncds' : require('./add_ncds.svg'),
    'lmi_country' : require('./lmi_country.svg'),
    'map_holder_img' : require('./map_holder_img.svg'),
    'ab_orange' : require('./ab_orange.svg'),
    'ao_circle' : require('./ao-circle.png'),
    'astella_logo_white' : require('./astella_logo_white.png'),
    'ab_white' : require('./ab_white.png'),
    'ao_orange_small' : require('./ao_orange_small.png'),
    'close_btn' : require('./close_btn.png'),
    'right_arrow_black' : require('./right_arrow_black.svg'),
    'chart_light_img' : require('./chart_light_img.svg'),
    'right_arrow_white' : require('./right_arrow_white.svg'),
    'slider_arrow_right' : require('./slider_arrow_right.svg'),
    'slider_arrow_left' : require('./slider_arrow_left.svg'),
    'aa_arrow_orange' : require('./aa_arrow_orange.png'),
    'icon_aa' : require('./icons/aa.svg'),
    'icon_ad' : require('./icons/ad.svg'),
    'icon_ao' : require('./icons/ao.svg'),
    'icon_benpop' : require('./icons/benpop.svg'),
    'icon_countries' : require('./icons/countries.svg'),
    'icon_ncd' : require('./icons/ncd.svg'),
    'icon_population' : require('./icons/population.svg'),
    'icon_programs' : require('./icons/programs.svg'),
    'astellas_png' : require('./astellas_png.png'),
    'kenya_flag_rectangle' : require('./kenya_flag_rectangle.png'),
    'rightIcon' : require('./play.png'),
    'areaChart' : require('./area-chart.png'),
    'ncdDashboard' : require('./ncd_dashboard.png'),
    'report_partner' : require('./reportpartner.png'),
    'download_white_arrow' : require('./icons/downloadarrow.svg'),
    'collective_img' : require('./collectiveimg.png'),
    'collective_map' : require('./collective_map.png'),
    'geography_image' : require('./geography_img.png'),
    'ncd_logo' : require('./partner/ncdlogo.png'),
    'ccan_logo' : require('./partner/ccanlogo.png'),
    'world_logo' : require ('./partner/worldlogo.png'),
     'worldbank_logo' : require ('./partner/world-bank-logo.png'),
     'path_logo' : require ('./partner/pathlogo.png'),
     'patient_centric' : require ('./partnerframework_logo/patient_centric.png'),
     'programs' : require ('./partnerframework_logo/Programs.png'),
     'partnerships' : require ('./partnerframework_logo/Partnership.png'),
     'right_orangearrow' : require ('./icons/orange_arrow.png'),
     'right_bigarrow' : require('./icons/right_bigarrow.png'),
     'RTI_whiteLogo' : require('./partner/RTI_whitelogo.png'),
     'Close_Button' : require('./icons/Closebutton.png'),  
     'Framework_arrow' : require('./partnerframework_logo/framework_arrow.png'),
     'partners_map' : require('./partner/partnersmap.png'),
     'pathImg' : require('./partnerlogo/path.png'),
     'ccanImg' : require('./partnerlogo/ccan.png'),
     'ncdImg' : require('./partnerlogo/ncd.png'),
     'whfImg' : require('./partnerlogo/whf.png'),
     'worldBankImg' : require('./partnerlogo/worldBank.png'),
     'aaopLogoWhite' : require('./aaop_logo_new_white.svg'),
     'header_bg' : require('./light-blue-color-solid-crop.png')
}

export default imgUrl;