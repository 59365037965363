import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Nav, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";

import imgUrl from "../../assets/images/imgUrl.js";
import apiBaseUrl from "../../config";

const SecondaryNavbar = ({setAuth}) => {
  let history = useHistory();
  const [name, setName] = useState("");

  const getProfile = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await fetch(`${apiBaseUrl}dashboard`, {
        method: "POST",
        headers: { jwt_token: token }
      });
      console.log(res);
      // if (!res.ok) {
      //   if ([401, 403].includes(res.status)) {
      //     console.log("You are not authorize person")
      //       // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      //       // localStorage.removeItem("token");
      //       // localStorage.removeItem("userName");
      //       setAuth(false);
      //       setName("");
      //       // history.push('/home');
      //   }
      // }
      const parseData = await res.json();
      setName(parseData.username);
    } catch (err) {
      console.log("Error occurs");
      console.error(err.message);
    }
  };

  const logout = async e => {
    e.preventDefault();
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("userName");
      localStorage.removeItem("DataObj");
      setAuth(false);
      setName("");
      let path = `/home`;
      history.push(path);
      // toast.success("Logout successfully");
    } catch (err) {
      console.error(err.message);
    }
  };

  const LoginRedirect = () => {
    let path = `/login`;
    
    history.push(path);
  }

  const setActivePage = (page) => {
    localStorage.setItem("activePage", page);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="secondary-navholder">
      <Container>
        <Row>
          <Col className="d-flex justify-content-between">
            <Nav className="container justify-content-center">
              <NavLink href="https://aaopenplatform.accessaccelerated.org/">
                <img
                  className=""
                  alt=""
                  src={imgUrl.left_arrow_white}
                  style={{ width: "10px", marginRight: "4px" }}
                />{" "}
                Back to the Open Platform
              </NavLink>
              <NavLink
                href="/faqs"
                className="pr-2 faqs-navLink"
                onClick={() => setActivePage("faqs-page")}
              >
                FAQs
              </NavLink>
            </Nav>
            {/* <Nav className="justify-content-end">

              <NavLink className="text-dark uploadTab signupbtn downloadbtnicon pr-4">
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                  <img
                  className=""
                  alt=""
                  src={imgUrl.user_white}
                  style={{ width: "18px"}}
                />{" "}
              </DropdownToggle>
                  <DropdownMenu right>
                    {name ?
                    <>
                      <DropdownItem>{ name }</DropdownItem>
                      <DropdownItem onClick={logout}>Logout</DropdownItem>
                    </>
                    : <DropdownItem onClick={LoginRedirect}>Login</DropdownItem>
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavLink>
            </Nav> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SecondaryNavbar;
