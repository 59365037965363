import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

import imgUrl from "../../assets/images/imgUrl.js";

function CountryDetail({ parentCallback, parentProgram, countryArr }) {
	const [totalBeneficiary, setTotalBeneficiary] = useState(""); // Catchement Population Served Data
	const [countryPopulation, setCountryPop] = useState(""); // Catchement Population Served Data
	let getCountry = countryArr && countryArr.length > 0 ? countryArr : [];
	let filterNCD = getCountry[0] && getCountry[0].NCDs && getCountry[0].NCDs.length > 0 ? [...new Set(getCountry[0].NCDs.map(item => item.ncd_name))] : [];

	const history = useHistory();

	const routeChange = () => {
		let path = `/countries`;
		history.push(path);
	}

	const BeneficiaryPop = (getCountry) => {
		if (getCountry && getCountry.length > 0) {
			if (getCountry[0]["benificiary population"] !== null) {
				let num =
					getCountry[0]["benificiary population"] ? getCountry[0]["benificiary population"][0]['total benificiary population'] : 'NA';
				const TotalVal = (num) => {
					if (num > 999 && num < 1000000) {
						return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
					} else if (num > 1000000) {
						return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
					} else if (num < 900) {
						return num; // if value < 1000, nothing to do
					}
				};

				setTotalBeneficiary(TotalVal(num)); //Pasing Total beneficiary State
			} else {
				setTotalBeneficiary("NA");
			}
		}
	};

	const CountryPop = (getCountry) => {
		if (getCountry && getCountry.length > 0) {
			if (getCountry[0].counry_population !== null) {
				let num = getCountry[0].counry_population;
				const TotalVal = (num) => {
					if (num > 999 && num < 1000000) {
						return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
					} else if (num > 1000000) {
						return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
					} else if (num < 900) {
						return num; // if value < 1000, nothing to do
					}
				};

				setCountryPop(TotalVal(num)); //Pasing Total beneficiary State
			} else {
				setCountryPop("NA");
			}
		}
	};

	const settingssmall = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: false,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: false,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	const toggleClose = () => {
		parentCallback(false);
	}

	const openProgram = (id) => {
		parentProgram(id);
	}

	useEffect(() => {
		BeneficiaryPop(getCountry);
		CountryPop(getCountry);
	}, [countryArr])

	return (
		<>
			{getCountry && getCountry.length > 0 ?
				<Row>
					<Col sm="12">
						<div className="member_profile_card_open">
							<div className="member_profile_card_closebtn" onClick={() => toggleClose()} style={{ cursor: 'pointer' }}>
								<img
									className="stoptblogoimg"
									alt="stopTB"
									src={imgUrl.close_btn}
								/>
							</div>
							<div className="lmi_country_profile_open_image">

								<Row>
									<Col sm="2" className="left-gradient"></Col>
									<Col sm="8" className="d-flex align-items-center justify-content-center">
										<p className="mb-0 text-center" style={{ width: "200px" }}>
											<img
												className="stoptblogoimg"
												alt="stopTB"
												src={getCountry[0].country_logo}
												style={{ width: "100%" }}
											/>
										</p>
									</Col>
									<Col sm="2" className="right-gradient"></Col>
								</Row>
							</div>
							<div className="blue_border_holder"></div>
							<div className="member_profile_card_open_description mt-5">
								<Row>
									<Col sm="12">
										<p className="member_company_title_name">{getCountry[0].Country_name}</p>
									</Col>
								</Row>
								<Row>
									<Col sm="4">
										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_population}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
													<p className="member_profile_card_open_cat_title">{countryPopulation} (2020) Population</p>
												</Col>
											</Row>
										</div>
										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_ad}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
													<p className="member_profile_card_open_cat_title"><a onClick={() => routeChange()} style={{cursor: "pointer"}}>Analytical Dashboard/Countries</a></p>
												</Col>
											</Row>
										</div>
									</Col>
									<Col sm="4">
										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_benpop}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
													<p className="member_profile_card_open_cat_title">{totalBeneficiary || totalBeneficiary === 0 ? totalBeneficiary : 'NA'} Beneficiary Population</p>
												</Col>
											</Row>
										</div>
										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_ncd}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2 country_scroll_container">
													<p className="member_profile_card_open_cat_title">
														{filterNCD &&
															filterNCD.length > 0 ?
															filterNCD.map((memberName, index) => (
																<>
																	{filterNCD.length - 1 == index
																		? " " + memberName
																		: " " + memberName + ","}
																</>
															)) : 'NA'
														}
													</p>
												</Col>
											</Row>
										</div>


									</Col>
									<Col sm="4">

										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_programs}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
													<p className="member_profile_card_open_cat_title">
														{
															getCountry[0]["total no of program"] ? getCountry[0]["total no of program"] > 1 ? getCountry[0]["total no of program"] + " Programs" : getCountry[0]["total no of program"] + " Program" : "Program NA"}
													</p>
												</Col>
											</Row>
										</div>
										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_countries}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2 country_scroll_container">
													<p className="member_profile_card_open_cat_title w-100">{getCountry[0]["Catchment Areas"] &&
															getCountry[0]["Catchment Areas"].length > 0 ? getCountry[0]["Catchment Areas"].length : ''} Catchment Areas</p>
													<p className="member_profile_card_open_cat_sub_title">
													{getCountry[0]["Catchment Areas"] &&
															getCountry[0]["Catchment Areas"].length > 0 ?
															getCountry[0]["Catchment Areas"].map((countryName, index) => (
																<>
																	{getCountry[0]["Catchment Areas"].length - 1 == index
																		? `${countryName}`
																		: ` ${countryName},`}
																</>
															)) : 'NA'
														}
													</p>
												</Col>
											</Row>
										</div>
									</Col>
								</Row>
							</div>
							<div className="blue_border_holder mt-4"></div>

						</div>
						{getCountry[0].programs && getCountry[0].programs.length > 0 ?
							<Slider {...settingssmall} className="small_slider">

								{getCountry[0].programs.map((prog) => (
									<div id={prog.program_id} onClick={() => openProgram(prog.program_id)}>
										<div className="member_profile_card_image"
											style={{
												backgroundImage: `url(${prog.program_logo ? prog.program_logo : ''})`,
												backgroundPosition: "center",
												backgroundRepeat: "no-repeat",
												backgroundSize: "cover"
											}}>
											{prog.AO && prog.AO == "Y" ? (<div className="member_profile_ooimage">
												<img className="stoptblogoimg" alt="stopTB" src={imgUrl.ao_circle} />
											</div>) : ''}
											<div className="member_profile_description">
												<div className="member_profile_title">
													<p>{prog.program_name}</p>
												</div>
												<div className="member_profile_company_name">
													<p>
														{prog.Members &&
															prog.Members.length > 0 &&
															prog.Members.map((memberName, index) => (
																<>
																	{prog.Members.length - 1 == index
																		? memberName.member_name
																		: " " + memberName.member_name + ","}
																</>
															))
														}
													</p>
												</div>
											</div>
										</div>
									</div>
								))
								}
							</Slider>
							: ""}
					</Col>
				</Row>
				: ''}
		</>
	);
}

export default CountryDetail;