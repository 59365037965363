import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Nav,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  Spinner,
} from "reactstrap";
//import Loader from "react-loader-spinner";
import imgUrl from "../../assets/images/imgUrl.js";
//import { Parser } from "json2csv";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
//const FileDownload = require("js-file-download");

const SecondaryNavbarDashboard = (props) => {
  //console.log(props);
  //const [spinnerLoading, setSpinnerLoading] = useState(false); // Loader state

  const setActivePage = (page) => {
    localStorage.setItem("activePage", page);
  };

  const printPDF = () => {
    document
      .getElementById("pdf_download_alert")
      .classList.replace("hide", "show");
    //document.getElementById("Col_Desc").classList.remove("colb-desp-summary");
    //document.getElementById("Col_Desc").style.maxHeight = "100px";

    const domElement = document.getElementById("AA_Program");
    html2canvas(domElement, {
      //  allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      document
        .getElementById("Col_Desc")
        .classList.remove("colb-desp-summary-height");
      const descDomElement = document.getElementById("mainCollDesc");

      html2canvas(descDomElement, {
        //  allowTaint: true,
        useCORS: true,
      }).then((descCanvas) => {
        let ScreenshotAttachment = canvas.toDataURL();
        let descDataURL = descCanvas.toDataURL("image/png");

        document
          .getElementById("Col_Desc")
          .classList.add("colb-desp-summary-height");
        //document.getElementById("Col_Desc").style.maxHeight = "150px";
        //console.log("File: ", ScreenshotAttachment);
        document
          .getElementById("ImplPartner")
          .classList.remove("partners_content");
        const ImplPartnerDoc = document.getElementById("Implementing_partner");
        console.log(ImplPartnerDoc);

        html2canvas(ImplPartnerDoc, {
          //  allowTaint: true,
          useCORS: true,
        }).then((partnerCanvas) => {
          console.log(partnerCanvas);
          //console.log(ImplPartnerDoc.clientWidth);
          let partnerDataURL = partnerCanvas.toDataURL("image/png");
          //console.log(partnerDataURL);
          document
            .getElementById("ImplPartner")
            .classList.add("partners_content");
          const pdf = new jsPdf("p", "mm", "a4");
          // var width = ImplPartnerDoc.scrollWidth;;
          // var height = ImplPartnerDoc.scrollHeight;
          // console.log(width);
          // console.log(height);

          const imgProps = pdf.getImageProperties(descDataURL);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          const imgProps1 = pdf.getImageProperties(partnerDataURL);
          const pdfWidth1 = pdf.internal.pageSize.getWidth();
          const pdfHeight1 = (imgProps1.height * pdfWidth1) / imgProps1.width;
          //pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

          pdf.addImage(ScreenshotAttachment, "JPEG", 5, 5, 200, 280);
          pdf.addPage();
          pdf.addImage(descDataURL, "PNG", 5, 5, pdfWidth - 10, pdfHeight);
          pdf.addPage();
          pdf.addImage(partnerDataURL, "PNG", 5, 5, pdfWidth1 - 20, pdfHeight1);

          let pdfsave = props.getProgram + ".pdf";
          pdf.save(pdfsave);
          document
            .getElementById("pdf_download_alert")
            .classList.replace("show", "hide");
        });
        // pdf.addImage(descDataURL, "JPEG", 5, 5, 200, 210);

        // let pdfsave = props.getProgram + ".pdf";
        // pdf.save(pdfsave);
      });
    });
  };

  return (
    <>
      <div className="secondary-navholder">
        <Container>
          <Row>
            <Col className="d-flex justify-content-between">
              <Nav className="container justify-content-center">
                <NavLink href="https://aaopenplatform.accessaccelerated.org/">
                  <img
                    className=""
                    alt=""
                    src={imgUrl.left_arrow_white}
                    style={{ width: "10px", marginRight: "4px" }}
                  />{" "}
                  Back to the Open Platform
                </NavLink>
                <NavLink
                  href="/faqs"
                  className="pr-2 faqs-navLink"
                  onClick={() => setActivePage("faqs-page")}
                >
                  FAQs
                </NavLink>
              </Nav>
              {props.exportButton ? (
                <Nav className="justify-content-end">
                  <NavLink
                    className="pr-2"
                    style={{ cursor: "pointer" }}
                    onClick={printPDF}
                  >
                    Export PDF
                  </NavLink>
                </Nav>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <div
        id="pdf_download_alert"
        className="pdf_download_alert animate__animated hide"
      >
        <Alert color="dark" style={{ margin: "0px" }}>
          <Spinner color="dark" /> PDF export in progress, file will be
          downloaded when ready...
        </Alert>
      </div>
      {/* <div
        id="csv_download_alert"
        className="csv_download_alert animate__animated hide"
      >
        <Alert color="dark" style={{ margin: "0px" }}>
          <Spinner color="dark" /> CSV export in progress, file will be
          downloaded when ready...
        </Alert>
      </div> */}
      {/* Loader */}
      {/* {spinnerLoading ? (
        <div
          style={{
            textAlign: "center",
            height: "calc(100vh - 6.5rem)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "6.5rem",
          }}
        >
          <Loader
            type="TailSpin"
            color="#f26432"
            height={100}
            width={100}
            visible={spinnerLoading}
          />
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default SecondaryNavbarDashboard;
