import React, { useEffect, useState, useCallback } from "react";
import {
    Container,
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Table, Modal, ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink, Card, Collapse, CardBody,
} from "reactstrap";
import Loader from "react-loader-spinner";
import apiBaseUrl from "../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// import CryptoJs from 'crypto-js';
import UserDetails from "./UserDetails";

function RegisterUser(props) {
    //For Registration Modal
    const {
        buttonLabel,
        className
    } = props;

    const toastBox = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: '',
    }

    const [modal, setModal] = useState(false);
    //==================

    const [userDetail, setUserDetail] = useState({
        user: "",
        email: "",
        password: "",
        confirmPass: "",
        userRole: 2,
        aa_member: 39,
        isactive: 0,
        created_user: 88
    }); // Set state for file Type
    const [matchPassword, setMatchPassword] = useState(false);
    const [userData, showUserDetails] = useState([]);
    const [memberData, setMembers] = useState([]);
    const [userRoles, setRoles] = useState([]);
    const [newToken, getNewToken] = useState('');
    const [displayInfo, setInfoMsg] = useState(false); //Check if all field have values or not
    const [editUser, getEditUser] = useState({})
    const [registerForm, openRegisterForm] = useState(false)
    const [userDetailCount, setUserDetailCount] = useState(0)
    const [spinnerLoading, setSpinnerLoading] = useState(true); // Loader state

    let name, value;

    const toggle = () => {
        getEditUser({})
        setUserDetail({isactive: 0})
        openRegisterForm(true)
        setInfoMsg(false)
        // setSpinnerLoading(false)
        setModal(!modal)
    };

    const editToggle = () => {
        openRegisterForm(false)
        setInfoMsg(false)
        // setSpinnerLoading(false)
        setModal(!modal)
    };

    const handleFormInput = (e) => {
        name = e.target.name;
        value = e.target.type === 'checkbox' ? (e.target.checked === true ? 1 : 0) : e.target.value;
        setUserDetail({...userDetail, [name]:value})
        getEditUser({...editUser, [name]:value})
    }

    const getUserDetails = async () => {
        await fetch(
            `${apiBaseUrl}getUserDetails`
          )
            .then((response) => response.json())
            .then((data) => {
              const Data = data;
              if(props.loginUser.uid) {
                const filterUsers = Data.filter(val => val.created_user === props.loginUser.uid);
                showUserDetails(filterUsers);
                console.log('filterUsers',filterUsers)
              } else {
                showUserDetails(Data);
              }
            })
            .catch((err) => {
              console.log(`${err} while contacting the quote API.`);
            });
    }

    const getMemberList = async () => {
        await fetch(
            `${apiBaseUrl}getMembers`
          )
            .then((response) => response.json())
            .then((data) => {
              const Data = data;
              setMembers(Data);
            })
            .catch((err) => {
              console.log(`${err} while contacting the quote API.`);
            });
    }

    const getUserRole = async () => {
        await fetch(
            `${apiBaseUrl}getUserRoles`
          )
            .then((response) => response.json())
            .then((data) => {
              const Data = data;
              if(props.loginUser.role) {
                const filterRole = Data.filter(data => data.roleid != props.loginUser.role);
                setRoles(filterRole);
              } else {
                setRoles(Data);
              }
            })
            .catch((err) => {
              console.log(`${err} while contacting the quote API.`);
            });
    }

    const registerUserSubmit = async (e) => {//Register data POST call
        e.preventDefault();
        if(props.loginUser.uid) {
            userDetail.created_user = props.loginUser.uid
        }

        if(userDetail.password !== userDetail.confirmPass) {
            setMatchPassword(true);
            return;
        }
        if(userDetail.user && userDetail.password && userDetail.email) {
            setMatchPassword(false);
            const { user, email, password, userRole, aa_member, isactive, created_user } = userDetail;
            try {
                const res = await fetch(`${apiBaseUrl}registerUser`, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({user, email, password, userRole, aa_member, isactive, created_user}),
                }).then(response => {
                    return response.text();
                })
                .then(data => {
                    if(data.length < 100) {
                        toast(`${data}`, toastBox)
                    } else {
                        toast.success("New User Registered!", toastBox)
                    }
                    getNewToken(data);
                    toggle()
                });
            } catch(err) {
                if(err.response.status === 500) {
                    console.log('There was problem with the server');
                } else {
                    console.log(err.response.data.msg);
                }
            }
            setInfoMsg(false);           
        } else {
            console.log("Please fill all fields");
            setInfoMsg(true);
        }
    }

    const updateUserSubmit = async (e) => {//Update data PUT call
        e.preventDefault();
        setInfoMsg(false);
        setMatchPassword(false);
        if(editUser.password != '' && editUser.confirmPass != '') {
            if(editUser.password !== editUser.confirmPass) {
                setMatchPassword(true);
                return;
            }
        }
        
        if(editUser.user && editUser.password && editUser.email) {
            setMatchPassword(false);
            setInfoMsg(false); 
            const userID = editUser.userid;
            const { user, email, password, userRole, aa_member, isactive } = editUser;
            try {
                const res = await fetch(`${apiBaseUrl}update/${userID}`, {
                    method: 'PUT',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({user, email, password, userRole, aa_member, isactive}),
                }).then(response => {
                    return response.text();
                })
                .then(data => {
                    if(data.length < 100) {
                        toast(`${data}`, toastBox)
                    } else {
                        toast.success("User Data Updated!", toastBox)
                    }
                    getNewToken(data);
                    toggle()
                });
            } catch(err) {
                if(err.response.status === 500) {
                    console.log('There was problem with the server');
                } else {
                    console.log(err.response.data.msg);
                }
            }
            setInfoMsg(false);           
        } else {
            console.log("Please fill all fields");
            setInfoMsg(true);
        }
    }

    // Callback to get data from its child component(User-Detail)
    const callback = useCallback((editUserDt) => {
        console.log(editUserDt)
        const getUserData = JSON.parse(editUserDt);
        getEditUser(getUserData);
        editToggle();
    }, []);

    useEffect(() => {
        getMemberList();
        getUserRole();
        setSpinnerLoading(false)
    },[props.loginUser.role])

    useEffect(()=> {
        getUserDetails();
        setUserDetailCount(userDetailCount + 1);
    }, [newToken])

    return (
        <>
        {spinnerLoading ? (
                <div
                style={{
                    textAlign: "center",
                    height: "calc(100vh - 6.5rem)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "6.5rem",
                }}
                >
                <Loader
                    type="TailSpin"
                    color="#f26432"
                    height={100}
                    width={100}
                    visible={spinnerLoading}
                />
                </div>
            ) : (<>
            <Row>
                <Col sm="12">
                    <h4 className="tabTitle">User Management</h4>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="float-right downloadPDF_btn" color="primary" onClick={toggle} style={{ marginBottom: '1rem' }}>Create User</Button>

                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    {userData && userData.length > 0 ? 
                        <UserDetails key={userDetailCount} userData={userData} parentCallback={callback} loggedIn={props.loginUser.uid ? props.loginUser.uid : 88}/> : "No user Registered!"
                    }
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <Modal isOpen={modal} toggle={toggle} className={className}>
                            <ModalHeader toggle={toggle}>{registerForm ? 'Register User' : 'Update User'}</ModalHeader>
                            <ModalBody>
                                <Card className="border-0">
                                    <CardBody className="w-100">
                                        <Form className="row">
                                            <FormGroup className="col-sm-12">
                                                <Label for="userName">User Name</Label>
                                                <Input type="text" name="user" id="userName" onChange={handleFormInput} placeholder="User Name" value={ editUser.user || '' }/>
                                            </FormGroup>
                                            <FormGroup className="col-sm-12">
                                                <Label for="userEmail">Email</Label>
                                                <Input type="email" name="email" id="userEmail" onChange={handleFormInput} placeholder="Email Address" value={ editUser.email || '' }/>
                                            </FormGroup>
                                            <FormGroup className="col-sm-12">
                                                <Label for="userPassword">Password</Label>
                                                <Input type="password" name="password" id="userPassword" autoComplete="off" onChange={handleFormInput} placeholder="Password" />
                                            </FormGroup>
                                            <FormGroup className="col-sm-12">
                                                <Label for="confirmPassword">Confirm Password:</Label>
                                                <Input type="password" name="confirmPass" id="confirmPassword" autoComplete="off" onChange={handleFormInput} placeholder="Password" />
                                            </FormGroup>
                                            <>
                                            {matchPassword ? 
                                                <div style={{ width: '100%', textAlign: 'left', color: '#f00' }}>
                                                    <p style={{ fontSize: '14px' }}>Password does not match.
                                                    </p>
                                                </div> : ''
                                            }
                                            </>
                                            <FormGroup className="col-sm-12">
                                                <Label for="userRole">Select Role</Label>
                                                <Input type="select" name="userRole" id="userRole" onChange={handleFormInput} value={editUser.userRole || 2} >
                                                {userRoles && userRoles.length > 0 ? 
                                                    userRoles.map((role) => 
                                                    (<option value={role.roleid} key={role.roleid}>{role.rolename}</option>))
                                                    : (<option value="N/A" key="N/A">N/A</option>)}
                                                </Input>
                                            </FormGroup>
                                            <FormGroup className="col-sm-12">
                                                <Label for="userMember">AA Member</Label>
                                                <Input type="select" name="aa_member" id="userMember" onChange={handleFormInput} value={ editUser.aa_member || 39 } >
                                                    {memberData && memberData.length > 0 ? 
                                                    memberData.map((member) => 
                                                    (<option value={member.org_id} key={member.org_id}>{member.org_displayed_name}</option>))
                                                    : (<option value="N/A" key="N/A">N/A</option>)}
                                                </Input>
                                            </FormGroup>
                                            <FormGroup className="col-sm-11" check>
                                                <Label check>
                                                <Input name="isactive" type="checkbox" onChange={handleFormInput} checked={editUser.isactive || 0} />{' '}
                                                Active
                                                </Label>
                                            </FormGroup>
                                            <>
                                            { displayInfo ?
                                            <div style={{ width: '100%', textAlign: 'left', color: '#f00', paddingTop: '10px' }}>
                                                <p style={{ fontSize: '14px', paddingLeft: '20px' }}>Please fill in all the fields.
                                                </p>
                                            </div> : ''
                                            }
                                            </>
                                            <div className="col-sm-12">
                                                <Button className="float-right" onClick={registerForm ? registerUserSubmit : updateUserSubmit}>Submit</Button>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </ModalBody>
                        </Modal>
                    </div>
                </Col>
            </Row>
            </>)}
        </>        
    );
}

export default RegisterUser;