import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col,} from "reactstrap";
import { Helmet } from "react-helmet";
import { TabContent, TabPane, Nav, NavItem, NavLink,} from 'reactstrap';
import classnames from 'classnames';
import imgUrl from "../assets/images/imgUrl.js";
import { Report } from "powerbi-report-component";
import axios from "axios";
import apiBaseUrl from "../config";
import { models } from 'powerbi-client';
import Dashboard from "../components/Dashboard";
import LeafletMap from "../components/dashboard_overview/LeafletMap";
import "../assets/css/customstyles.css";

import Highcharts from 'highcharts';
import sunburst from "highcharts/modules/sunburst.js";
import HighchartsReact from 'highcharts-react-official';

// import highchartsMore from "highcharts/highcharts-more.js";
import networkGraph from "highcharts/modules/networkgraph.js";

// highchartsMore(Highcharts);
networkGraph(Highcharts);
sunburst(Highcharts);

Highcharts.seriesTypes.sunburst.prototype.onClickDrillToNode = function(event) {
  var series = this,
    point = event.point,
    drillId = point && point.drillId;
  // If a drill id is returned and noDrill is not set to true, add click event and cursor.
  if (Highcharts.isString(drillId) && !point.noDrill) {
    point.setState(""); // Remove hover
    series.drillToNode(drillId);
  }
};

Highcharts.getOptions().colors.splice(0, 0, "transparent");

function CollectiveImpact() {
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [partnersData, setPartnersData] = useState([]);
  const [allPartner, setallPartner] = useState("");
  const [mapKey, setMapKey] = useState(1);
  const [activeCountry, setActiveCountry] = useState({})
  const [thameticDataArr, setThameticDataArr] = useState([])
  const [thameticNames, setThameticNames] = useState([])
  const [sunData, setSunData] = useState([
  {
      id: "0.0",
      parent: "",
      name: "THEMATIC AREAS NETWORK"
  }
  ]);
  const [sunKey, setSunKey] = useState(0)
  const [networkOption, setNetworkOption] = useState(
    {
      chart: {
        height: "100%"
      },

      credits: {
        enabled: false
      },
    
      title: {
        text: ""
      },
      series: [
        {
          type: "sunburst",
          data: sunData,
          allowDrillToNode: true,
          cursor: "pointer",
          dataLabels: {
            format: "{point.name}",
            filter: {
              property: "innerArcLength",
              operator: ">",
              value: 16
            },
            style: {
              fontSize: '10px'
            }
          },
          levels: [
            {
              level: 1,
              levelIsConstant: false,
              dataLabels: {
                filter: {
                  property: "outerArcLength",
                  operator: ">",
                  value: 64
                },
                style: {
                  fontSize: '10px'
                }
              }
            },
            {
              level: 2,
              colorByPoint: true
            },
            {
              level: 3,
              colorVariation: {
                key: "brightness",
                to: -0.5
              }
            },
            {
              level: 4,
              colorVariation: {
                key: "brightness",
                to: 0.5
              }
            }
          ]
        }
      ],
      tooltip: {
        headerFormat: "",
        pointFormat: "<b>{point.name}</b>: <b>{point.value}</b>"
      }
    }
  )

  const [filterIsoCode, setFilterIsoCode] = useState("");


  const position = [51.505, -0.09]
  /*Tab*/
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const getMapData = () => {
    fetch("https://aaopenplatform.accessaccelerated.org/world.geo.json")
      .then((response) => response.json())
      .then((data) => {
        setGeoJsonData(data);

        fetch(`${apiBaseUrl}getPartnersMap`)
          .then((response) => response.json())
          .then((data) => {
            let thematicNames = [];
            let allData = data[0].json_agg;
            let filterPartners = allData.filter((val) => val.Partners && val.Partners.length > 1 );
            let thematicData = allData.filter((val) => val.Network_diagram_tag && val.Network_diagram_tag.length > 0 );
            thematicData.forEach((val) => {
              if(val.Network_diagram_tag && val.Network_diagram_tag.length > 0) {
                val.Network_diagram_tag.forEach((names) => {
                  if(!thematicNames.includes(names.thematic_area)) {
                    thematicNames.push(names.thematic_area);
                  }
                })
              }
            })
            setThameticDataArr(thematicData);
            setThameticNames(thematicNames);
            NetworkChart(thematicData, thematicNames)
            // SunGraph(thematicData, thematicNames)
            SunGraph(allData, thematicNames)
            // let uniquNames = [...new Set(thematicNames)];
            // console.log("thematicData",thematicData, thematicNames);
            setMapData(allData);
            setPartnersData(filterPartners);
            PartnersList(filterPartners);
            setMapKey(mapKey + 1);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  const chartColor = (val) => {
    let color;
    if(val == 'NCD Alliance'){
      color = '#930c62';
    } else if(val == 'Path') {
      color = '#ffba18';
    } else if(val == 'World Bank') {
      color = '#1da0bc';
    } else if(val == 'City Cancer Challenge (C/CAN)') {
      color = '#015c84';
    } else if(val == 'World Heart Federation') {
      color = '#c41111';
    }

    return color;
  }

  const SunGraph = (data,names, val = '') => {
    console.log("data data", data)
    let filterPart = data.filter((val) => val.Partners && val.Partners.length > 0 && val.Network_diagram_tag && val.Network_diagram_tag.length > 0);
    // console.log("names, data",names, filterPart);
    let partnersName = [];
    filterPart.forEach((val) => {
      if(val.Partners && val.Partners.length > 0) {
        val.Partners.forEach((names) => {
          if(!partnersName.includes(names.partner_name)) {
            partnersName.push(names.partner_name);
          }
        })
      }
    })

    console.log("partnersName", partnersName)

    let subArr = [], childArr = ["a","b","c","d","f","g","h","i","j","k"], finalArr = [];
    partnersName.forEach((val, index) => {
      subArr.push({
        id: index + 1 + "",
        parent: '0.0',
        name: val,
        color: chartColor(val)
      })
      let namesArr = []
      filterPart.forEach((arr) => {
        
        if(arr.Network_diagram_tag.length > 0) {
          // let allNames = []
          arr.Network_diagram_tag.forEach((area) => {
            const arr = area.Partner_list && area.Partner_list.map(object => object.partner_name);
            if(arr.includes(val)) {
              let a = {}
              a["name"] = area.thematic_area;
              namesArr.push(a);
            }
          })
        }
      })

      let partnersArr = findOcc(namesArr,'name',index, childArr[index])
      console.log("partnersArr", partnersArr)
      finalArr = [...finalArr, ...partnersArr]

    })

    if(val == 'filter') {
      let arr = [{
        id: "0.0",
        parent: "",
        name: "THEMATIC AREAS NETWORK"
    }]
      setSunData([...arr,...subArr,...finalArr])
      setNetworkOption({
        chart: {
          height: "100%"
        },
  
        credits: {
          enabled: false
        },
      
        title: {
          text: ""
        },
        series: [
          {
            type: "sunburst",
            data: [...arr,...subArr,...finalArr],
            allowDrillToNode: true,
            cursor: "pointer",
            dataLabels: {
              format: "{point.name}",
              filter: {
                property: "innerArcLength",
                operator: ">",
                value: 16
              },
              style: {
                fontSize: '10px'
              }
            },
            levels: [
              {
                level: 1,
                levelIsConstant: false,
                dataLabels: {
                  filter: {
                    property: "outerArcLength",
                    operator: ">",
                    value: 64
                  },
                  style: {
                    fontSize: '10px'
                  }
                }
              },
              {
                level: 2,
                colorByPoint: true
              },
              {
                level: 3,
                colorVariation: {
                  key: "brightness",
                  to: -0.5
                }
              },
              {
                level: 4,
                colorVariation: {
                  key: "brightness",
                  to: 0.5
                }
              }
            ]
          }
        ],
        tooltip: {
          headerFormat: "",
          pointFormat: "<b>{point.name}</b>: <b>{point.value}</b>"
        }
      })
    } else {
      setSunData([...sunData,...subArr,...finalArr])
    }
    setSunKey(sunKey + 1);
  }

  useEffect(() => {
    console.log("sun Data", sunData)
    // setSunData(sunData)
    setNetworkOption({
      chart: {
        height: "100%"
      },

      credits: {
        enabled: false
      },
    
      title: {
        text: ""
      },
      series: [
        {
          type: "sunburst",
          data: sunData,
          allowDrillToNode: true,
          cursor: "pointer",
          dataLabels: {
            format: "{point.name}",
            filter: {
              property: "innerArcLength",
              operator: ">",
              value: 16
            },
            style: {
              fontSize: '10px'
            }
          },
          levels: [
            {
              level: 1,
              levelIsConstant: false,
              dataLabels: {
                filter: {
                  property: "outerArcLength",
                  operator: ">",
                  value: 64
                },
                style: {
                  fontSize: '10px'
                }
              }
            },
            {
              level: 2,
              colorByPoint: true
            },
            {
              level: 3,
              colorVariation: {
                key: "brightness",
                to: -0.5
              }
            },
            {
              level: 4,
              colorVariation: {
                key: "brightness",
                to: 0.5
              }
            }
          ]
        }
      ],
      tooltip: {
        headerFormat: "",
        pointFormat: "<b>{point.name}</b>: <b>{point.value}</b>"
      }
    })
  },[sunKey])

  function findOcc(arr, key, parent, idVal){
    let arr2 = [];
      
    arr.forEach((x,index)=>{
         
      // Checking if there is any object in arr2
      // which contains the key value
       if(arr2.some((val)=>{ return val[key] == x[key] })){
           
         // If yes! then increase the occurrence by 1
         arr2.forEach((k)=>{
           if(k[key] === x[key]){ 
             k["value"]++
           }
        })
           
       }else{
         // If not! Then create a new object initialize 
         // it with the present iteration key's value and 
         // set the occurrence to 1
         let a = {}
         a[key] = x[key]
         a["value"] = 1
         a['parent'] = parent + 1 + ''
         a['id'] = index + idVal
         arr2.push(a);
       }
    })
      
    return arr2
  }

  const NetworkChart = (data, names) => {
    let newtworkObj = [];
    names.forEach((name) => {
      let partnerArr = [];
      data.forEach((obj) => {
        if(obj.Network_diagram_tag && obj.Network_diagram_tag.length > 0) {
          // let partnerArr = []
          obj.Network_diagram_tag.forEach((val) => {
            if(name == val.thematic_area) {
              // thematicNames.push(val.thematic_area);
              if(val.Partner_list && val.Partner_list.length > 0) {
                val.Partner_list.forEach((partner) => {
                  // partnerArr.push(partner.partner_name);
                  if(!partnerArr.includes(partner.partner_name)) {
                    partnerArr.push(partner.partner_name);
                  }
                })
              }
            }
          })
        }
      })
      newtworkObj.push({ [name]: partnerArr })
    })
    console.log("newtworkObj",sunData)
  }

  const countryCheck = (id) => {
    let isoCode = id;
    setFilterIsoCode(isoCode)
  }

  const PartnersList = (partners) => {
    let partnersText = "";
    if(partners && partners.length > 0) {
      partners.forEach((element,index) => {
        if((partners.length - 1) == index) {
          partnersText += `<span id='${element.iso3_code}' class='partnersCountry'>${element.Country_name} (${element.Partners.length} ${element.Partners.length === 1 ? 'partner' : 'partners'})</span>`;
        } else {
          partnersText += `<span id='${element.iso3_code}' class='partnersCountry'>${element.Country_name} (${element.Partners.length} ${element.Partners.length === 1 ? 'partner' : 'partners'}), </span>`;
        }
      });
      setallPartner(partnersText);
    }
  }

  let patnersCountries = document.querySelectorAll('.partnersCountry');
  for(let i = 0; i < patnersCountries.length; i++) {
    patnersCountries[i].addEventListener("click", function() {
      countryCheck(patnersCountries[i].id);
    });
  }
  // patnersCountries.addEventListener("click", countryCheck);

  
  
// To Open and Close the network dialog box on map
const openNetworkDig = () => {
  let openButton = document.querySelector('#open-network');
  openButton.classList.add('hide');
  let closeButton = document.querySelector('#close-network');
  closeButton.classList.remove('hide');

  let diagram = document.querySelector('.network-diagram');
  diagram.classList.remove('minimum-height')
}

const closeNetworkDig = () => {
  let closeButton = document.querySelector('#close-network');
  closeButton.classList.add('hide');
  let openButton = document.querySelector('#open-network');
  openButton.classList.remove('hide');

  let diagram = document.querySelector('.network-diagram');
  diagram.classList.add('minimum-height')
}
// To Open and Close the network dialog box on map - end

// let getFilterName = localStorage.getItem('thematicName')
  useEffect(() => {
    getMapData();
  }, []);

  // Filter by legend
  const callBackFilter = (data,name) => {
    // NetworkChart(data,thameticNames);
    setSunData([{
        id: "0.0",
        parent: "",
        name: "THEMATIC AREAS NETWORK"
    }])
    SunGraph(data, thameticNames, "filter")
    // setNetworkOption({
    //   chart: {
    //       type: 'networkgraph'
    //   },
    //   title: {
    //       text: ""
    //   },
    //   plotOptions: {
    //       networkgraph: {
    //           // keys: ["from", "to"], //Error line
    //           layoutAlgorithm: {
    //               enableSimulation: false,
    //               // friction: -0.9,
    //               integration: "verlet",
    //               linkLength: 80 // in pixels
    //           },
    //           id: 'lang-tree',
    //           // link: {
    //           //     color: 'red'
    //           // }
    //       }
    //   },
    //   credits: {
    //         enabled: false,
    //   },
    //   series: [{
    //       marker: {
    //         radius: 13,
    //       },
    //       dataLabels: {
    //         enabled: true,
    //         allowOverlap: false,
    //         linkFormat: ''
    //       },
    //       data: [
    //           ['Advocacy and communications', 'C/Can'],
    //           ['Advocacy and communications', 'NCDA'],
    //           ['Advocacy and communications', 'WB'],
    //           ['Advocacy and communications', 'WHF'],
  
    //           ['priority-setting', 'WHF'],
    //           ['priority-setting', 'C/Can'],
    //           ['priority-setting', 'WB'],
    //           ['priority-setting', 'PATH'],
    //           // ['priority-setting', 'Funding'],
    //           ['Funding', 'C/Can'],
    //           ['Funding', 'WB'],
    //           ['Funding', 'NCDA'],
    //           // ['Funding', 'Evidence'],
    //           ['Evidence generation', 'C/Can'],
    //           ['Evidence generation', 'PATH'],
    //           ['Evidence generation', 'WHF'],
    //           ['Evidence generation', 'NCDA'],
    //           ['Evidence generation', 'WB'],
  
    //           ['Engagement with PLWNCDs', 'C/Can'],
    //           ['Engagement with PLWNCDs', 'WHF'],
  
    //           ['Strengthening or expansion of health services', 'WHF'],
    //           ['Strengthening or expansion of health services', 'C/Can'],
    //           ['Strengthening or expansion of health services', 'NCDA'],
    //           ['Strengthening or expansion of health services', 'WB'],
    //           ['Strengthening or expansion of health services', 'PATH'],
    //       ],
    //       // ['Advocacy and communications', 'Engagement with PLWNCDs', 'Evidence generation', 'Strengthening or expansion of health services', 'Expansion or strengthening of health services', 'Funding', 'Partnering and stakeholder coordination', 'Priority-setting']
    //       nodes: [
    //           {
    //               id: "priority-setting",
    //               marker: {
    //                   radius: 25
    //               },
    //               color: '#f58b21'
    //           },
    //           {
    //               id: "Evidence generation",
    //               marker: {
    //                   radius: 25
    //               },
    //               color: '#3c69bb'
    //           },
    //           {
    //             id: "Engagement with PLWNCDs",
    //             marker: {
    //                 radius: 25
    //             },
    //             color: '#bced21'
    //           },
    //           {
    //             id: "Strengthening or expansion of health services",
    //             marker: {
    //                 radius: 25
    //             },
    //             color: '#742d7e'
    //           },
    //           {
    //             id: "Funding",
    //             marker: {
    //                 radius: 25
    //             },
    //             color: '#f5ed21'
    //           },
    //           {
    //             id: "Advocacy and communications",
    //             marker: {
    //                 radius: 25
    //             },
    //             color: '#8cc640'
    //           }
    //       ]
    //   }]
    // })
    setFilterIsoCode("")
  }

  // To filter Network Chart
  const callBackFilterNetwork = (arr) => {
    console.log("arr filter",arr, thameticDataArr)
      let getfilterArr = thameticDataArr.filter((val) => {
        if(val.iso3_code == arr.properties.iso_a3) {
          return val.Network_diagram_tag;
        }
      });

      let networkArr = getfilterArr[0] && getfilterArr[0].Network_diagram_tag ? getfilterArr[0].Network_diagram_tag : [];
      // console.log("networkArr",networkArr, getfilterArr)
      SunGraph(getfilterArr, thameticNames, "filter")
      let customArr = [], areaName = [];
      let nodes = [
        {
            id: "Priority-setting",
            marker: {
                radius: 25
            },
            color: '#f58b21'
        },
        {
            id: "Evidence generation",
            marker: {
                radius: 25
            },
            color: '#3c69bb'
        },
        {
          id: "Engagement with PLWNCDs",
          marker: {
              radius: 25
          },
          color: '#bced21'
        },
        {
          id: "Strengthening or expansion of health services",
          marker: {
              radius: 25
          },
          color: '#742d7e'
        },
        {
          id: "Funding",
          marker: {
              radius: 25
          },
          color: '#f5ed21'
        },
        {
          id: "Advocacy and communications",
          marker: {
              radius: 25
          },
          color: '#8cc640'
        }
    ]
      networkArr.forEach((arrval) => {
        if(arrval.Partner_list && arrval.Partner_list.length > 0) {
          arrval.Partner_list.forEach((partner) => {
            customArr.push([arrval.thematic_area,partner.partner_name])
            areaName.push(arrval.thematic_area.toLowerCase())
          })
        }  
      })

      let uniqueNames = [...new Set(areaName)]
      let filerNodes = nodes.filter((val) => {
        if(uniqueNames.includes(val.id.toLowerCase())) {
          return val;
        }
      })

  }

  return (
    <>
      <Helmet>
        <title>Collective Impact - Access Accelerated</title>
        <meta
          name="description"
          content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
        />
        {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
      </Helmet>
      <div className="mastheader-holder bg-lgGrey collectiveImpact masterWrap">
        <Container>
          <Row>
          
            <Col lg="7">
              <div className="heading">
                <h1 className="partial-underline color-darkblack">
                   Working Toward Collective Impact
                </h1>
              </div>
              <div className="mast-description collectivePara color-darkblack">
              <p style={{fontSize: "16px" , letterSpacing: "0px" , color:"#000000"}}>
                      <img
                  className=""
                  alt=""
                  src={imgUrl.right_bigarrow}
                  style={{ width: "27px", height:"auto" , marginRight: "4px" , marginTop:"-5px" , maxWidth:"100%"}}
                />{" "}
                
                View the collective impact from Partners and Members in our 
                <br></br>     
                <a href="/overview-dashboard" style={{textDecoration:"underline",cursor:"pointer", color:"#F4754A" ,fontSize: "16px" , letterSpacing: "0px" , marginLeft: "5%"}}>
                  NCD OVERVIEW DASHBOARD
                </a>


                </p>
                <p>
                No one partner can engage in all activities, yet together, there is space for efforts to align across partners with 
                a view toward improving health functions for better access to NCD services. At the same time, partners have projects 
                that touch on needs other partners are involved in, 
                highlighting overlaps in the work that go beyond the geographic overlap of their projects.
                </p>
              </div>
            </Col>
            <Col lg="5" className="mastimgholder">
              <div className="profile-imagecontainer">
                <div>
                  <img
                    className="stoptblogoimg"
                    alt="stopTB"
                    src={imgUrl.collective_img}
                    style={{width:"100%" , height:"330px" , maxWidth:"100%"}}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="tabPartner">
        <Nav tabs>
          <NavItem className="partnerActive">
            <NavLink
            href="/partner-module"
              // className='active'
              // onClick={() => { toggle('1'); }}
            >
               OUR PARTNERS / M&amp;E FRAMEWORK
            </NavLink>
          </NavItem>
          <NavItem className="collectiveActive">
            <NavLink
            href="/collective-impact"
              className='active'
              // onClick={() => { toggle('2'); }}
            >
                 WORKING TOWARD COLLECTIVE IMPACT
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {/* <Row>
              <Col sm="12">
                <h4>Tab 1 Contents</h4>
              </Col>
            </Row> */}
          </TabPane>
          <TabPane tabId="2">
            {/* <Row>
              <Col sm="6">
                <Card body>
                  <CardTitle>Special Title Treatment</CardTitle>
                  <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                  <Button>Go somewhere</Button>
                </Card>
              </Col>
              <Col sm="6">
                <Card body>
                  <CardTitle>Special Title Treatment</CardTitle>
                  <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                  <Button>Go somewhere</Button>
                </Card>
              </Col>
            </Row> */}

          </TabPane>
        </TabContent>
     </div>

      <div className="dashboardtemplate-holder collectiveImpact">
        <Container>
          <div className="mt-4 mb-0 exploreallbtnholder">
            <Row>
              <Col lg="12">
                <div className="text-center">
                  <p className="text-right collectiveTitle">
                  PARTNER PROJECTS’ GEOGRAPHIC DISTRIBUTION
                  </p>
                  <p style={{textAlign: "left", marginBottom: "0"}} className="collective-map-description">Partner projects have taken place in {thameticDataArr.length} countries. Partners work alongside a variety of stakeholders across country contexts, including people living with NCDs (PLWNCDs), government, human resources for health (HRH), international bodies, the private sector, and civil society. Toggle the map filter below to see the geographic distribution of stakeholder involvement in Access Accelerated partner projects.</p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="exploreallbtnholder mt-4 mb-0">
            <Row>
              <Col lg="12">
                  {/* <div className="px-2">
                    <img
                      className=""
                      alt=""
                      src={imgUrl.partners_map}                  
                    />
                  </div> */}

                  {Object.keys(geoJsonData).length > 0 && mapData.length > 0 ?
                    <>
                    <div id="map-container" style={{position: "relative"}}>
                        <LeafletMap
                          //key={mapKey}
                          mapKey={mapKey}
                          setMapKey={setMapKey}
                          //mapHeight={el.h + 2.375}
                          mapData={mapData}
                          geoJsonData={geoJsonData}
                          mapView={ "partners" }
                          activeTab={"10"}
                          // parentCall={callback}
                          activeCountry={activeCountry}
                          filterMap={callBackFilter}
                          filterIsoCode={filterIsoCode}
                          filterNetwork={callBackFilterNetwork}
                        />
                        <div id="network-chart">
                          {/* <div className="heading">
                            <h4>Thematic areas network</h4>
                            <div>
                              <button id="open-network" className="hide" onClick={() => openNetworkDig()}>+</button>
                              <button id="close-network" onClick={() => closeNetworkDig()}>-</button>
                            </div>
                          </div> */}
                          <div className="network-diagram maximum-height" style={{height: "300px"}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={networkOption}
                                key = {sunKey}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="geographic-details">
                        <h4>View highlights:</h4>
                        <span>Partners overlap in {partnersData.length} countries:</span>
                        <p dangerouslySetInnerHTML={{ __html: allPartner }}>
                        </p>
                      </div>
                    </> : '' }
              </Col>
              {/* <Col lg="12" className="chart-holder">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </Col> */}
            </Row>              
          </div>   
         
        </Container>
      </div>

      <div className="dashboardtemplate-holder mb-5 collectiveImpact resourceholder bg-grey">
        <Container>
            <Row>
                <Col lg="12">
                  <div className="partners-projects-container">
                    {/* <h5 className="text-left collectiveTitle mb-4">
                       PARTNERS’ PROJECTS
                    </h5> */}
                    <Dashboard layoutSelected={"partners"} />
                  </div>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                  <div className="">
                   
                     
                  </div>

                  
                </Col>
                
                      
            </Row>
                
            
        </Container>
      </div>

      {/* <div className="dashboardtemplate-holder mb-5 resourceholder collectiveImpact"
      >
        <Container>
          <div className="mt-4 mb-0 exploreallbtnholder">
            
              <div className="mt-4 mb-0 exploreallbtnholder">
                <Row>
                  <Col lg="12">
                    <div className="text-center">
                      <p className="text-right collectiveTitle">
                        GEOGRAPHICAL REPRESENTATION OF PARTNER PROJECTS REPORTED METRICS BY COUNTRY
                      </p>
                    
                    </div>

                  </Col>
                </Row>
              </div>

              <div className="mt-4 mb-0 exploreallbtnholder">
                <Row>
                  <Col lg="12">
                    <div className="text-center">
                       
                    <img
                        className=""
                        alt="downloadReport"
                        src={imgUrl.geography_image}
                      />
                            
                    
                    </div>
                    
                  </Col>
                </Row>
              </div>
             
          </div>
        </Container>
      </div> */}

    
    </>
  );
}

export default CollectiveImpact;
