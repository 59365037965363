import React, { useState, useEffect, useCallback, useRef } from "react";

import { Row, Col } from "reactstrap";

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

import apiBaseUrl from "../../config";
import LeafletMap from "./LeafletMap";

import imgUrl from "../../assets/images/imgUrl.js";

function OverviewMap({
  parentCallFun,
  activeTab,
  programId,
  memberName,
  filterCountryMap,
  filterCountryBeneMap,
  filterNCDMap,
  partenersData
}) {
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [FilterProgramMap, setFilterProgramMap] = useState(null);
  const [FilterMemberMap, setFilterMemberMap] = useState(null);
  const [filterCountryMapArr, setFilterCountryMap] = useState(null);
  const [filterBeneMapArr, setFilterBeneMap] = useState(null);
  const [filterNCDMapArr, setFilterNCDMap] = useState(null);
  const [mapKey, setMapKey] = useState(1);

  const getMapData = () => {
    fetch("https://aaopenplatform.accessaccelerated.org/world.geo.json")
      .then((response) => response.json())
      .then((data) => {
        setGeoJsonData(data);

        fetch(`${apiBaseUrl}getMapData`)
          .then((response) => response.json())
          .then((data) => {
            const mapData = data[0].map_json_load.filter(
              (map) => map.low_income === "Y" || map.middle_income === "Y"
            );
            setMapData(mapData);
            setMapKey(mapKey + 1);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  const filterMap = () => {
    let getProgramID = programId;
    let filterMap = [];

    if (getProgramID) {
      mapData.forEach((data) => {
        if (data.programs && data.programs.length > 0) {
          data.programs.map((prog) => {
            if (prog.program_id === getProgramID) {
              filterMap.push(data);
            }
          });
        }
      });

      setFilterProgramMap(filterMap);
    } else {
      setFilterProgramMap(null);
    }
  };

  const filterMemberMap = () => {
    let getMember = memberName;
    let filterMap = [];
    if (getMember) {
      mapData.forEach((data) => {
        if (data.AA_Members && data.AA_Members.length > 0) {
          //Need member name not id
          data.AA_Members.map((prog) => {
            if (prog.member_name === getMember) {
              filterMap.push(data);
            }
          });
        }
      });

      setFilterMemberMap(filterMap);
    } else {
      setFilterMemberMap(null);
    }
  };

  const filterCountryMapFun = () => {
    let getCOuntry = filterCountryMap;
    let filterMap = [];
    if (getCOuntry && getCOuntry.length > 0) {
      mapData.forEach((data) => {
        let program_countries = data.Country_name;
        if (getCOuntry.includes(program_countries)) {
          filterMap.push(data);
        }
      });

      setFilterCountryMap(filterMap);
    } else {
      setFilterCountryMap(null);
    }
  };

  const filterBeneMapFun = () => {
    let getCOuntry = filterCountryBeneMap;
    let filterMap = [];
    if (getCOuntry && getCOuntry.length > 0) {
      mapData.forEach((data) => {
        let program_countries = data.Country_name;
        if (getCOuntry.includes(program_countries)) {
          filterMap.push(data);
        }
      });

      setFilterBeneMap(filterMap);
    } else {
      setFilterBeneMap(null);
    }
  };

  const filterNCDMapFun = () => {
    let getNCDArr = filterNCDMap;
    let filterMap = [];

    if (getNCDArr && getNCDArr.length > 0) {
      mapData.forEach((data) => {
        if (data.NCDs && data.NCDs.length > 0) {
          data.NCDs.forEach((ncd) => {
            ncd["NCDs sub type"].map((subNcd) => {
              if (getNCDArr.includes(subNcd.sub_ncd_name)) {
                filterMap.push(data);
              }
            });
          });
        }
      });

      setFilterNCDMap([...new Set(filterMap)]);
    } else {
      setFilterNCDMap(null);
    }
  };

  // Callback to get data from its child component(CountryDetail)
  const callback = useCallback((value) => {
    parentCallFun(value);
  }, []);

  useEffect(() => {
    getMapData();
  }, []);

  useEffect(() => {
    filterMap();
  }, [programId]);

  useEffect(() => {
    filterMemberMap();
  }, [memberName]);

  useEffect(() => {
    filterCountryMapFun();
  }, [filterCountryMap[0]]);

  useEffect(() => {
    filterCountryMapFun();
  }, [filterCountryMap.length]);

  useEffect(() => {
    filterBeneMapFun();
  }, [filterCountryBeneMap[0]]);
  
  useEffect(() => {
    filterBeneMapFun();
  }, [filterCountryBeneMap.length]);

  useEffect(() => {
    filterNCDMapFun();
  }, [filterNCDMap.length]);

  return (
    <>
      <Row>
        <Col lg="12">
          <div className="map-holder">
            {mapData.length > 0 ? (
              <LeafletMap
                //key={mapKey}
                mapKey={mapKey + activeTab}
                setMapKey={setMapKey}
                //mapHeight={el.h + 2.375}
                mapData={
                  FilterProgramMap && activeTab === "1"
                    ? FilterProgramMap
                    : FilterMemberMap && activeTab === "3"
                    ? FilterMemberMap
                    : filterCountryMapArr && activeTab === "5"
                    ? filterCountryMapArr
                    : filterBeneMapArr && activeTab === "2"
                    ? filterBeneMapArr
                    : filterNCDMapArr && activeTab === "4"
                    ? filterNCDMapArr
                    : mapData
                }
                geoJsonData={geoJsonData}
                mapView={
                  activeTab === "1" ||
                  activeTab === "3" ||
                  activeTab === "4" ||
                  activeTab === "5"
                    ? "choropleth"
                    : "bubbles"
                }
                activeTab={activeTab}
                parentCall={callback}
                partenersData={partenersData && partenersData.length > 0 ? partenersData : []}
              />
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default OverviewMap;
