import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

import imgUrl from "../../assets/images/imgUrl.js";
// import { backgroundRepeat } from "html2canvas/dist/types/css/property-descriptors/background-repeat";

function ProgramDetail({ parentCallback, programArr }) {
  const [totalBeneficiary, setTotalBeneficiary] = useState(""); // Catchement Population Served Data
  const getProgram = programArr && programArr.length > 0 ? programArr : [];
  const filterNCD = getProgram[0] && getProgram[0].NCDs && getProgram[0].NCDs.length > 0 ? [...new Set(getProgram[0].NCDs.map(item => item['NCDs type']))] : [];

  const BeneficiaryPop = (getProgram) => {
    if(getProgram && getProgram.length > 0) {
      if (getProgram[0].catchement_Population_served !== null) {
        let num =
          getProgram[0].catchement_Population_served.CatchmentAreas[0]
            .total_pop_served;
        const TotalVal = (num) => {
          if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
          } else if (num > 1000000) {
            return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
          } else if (num < 900) {
            return num; // if value < 1000, nothing to do
          }
        };

        setTotalBeneficiary(TotalVal(num)); //Pasing Total beneficiary State
      } else {
        setTotalBeneficiary("NA");
      }
    }
  };

  const history = useHistory();

  const routeChange = () =>{ 
    let path = `/Programs`; 
    history.push(path);
  }

  const settingssmall = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const settingsvertical = {
    dots: false,
    infinite: false,
    speed: 500,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const toggleClose = () => {
    parentCallback(false);
  }

  useEffect(()=> {
    BeneficiaryPop(getProgram);
  },[programArr])

  return (
    <>
      {getProgram && getProgram.length > 0 ?
        <Row>
          <Col sm="12">
            <div className="member_profile_card_open">
              <div className="member_profile_card_closebtn" onClick={() => toggleClose()} style={{ cursor: 'pointer' }}>
                <img
                  className="stoptblogoimg"
                  alt="stopTB"
                  src={imgUrl.close_btn}
                />
              </div>
              <div className="" style={{
                backgroundImage: `url(${getProgram[0].program_logo})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "300px"
              }}>
                {getProgram[0].AO && getProgram[0].AO == "Y" ? (
                <div className="member_profile_card_open_ooimage text-right">
                  <img
                    className="stoptblogoimg"
                    alt="stopTB"
                    src={imgUrl.ao_circle}
                  />
                  <div className="aotext"><p>This program is part of <br />the Access Observatory</p></div>
                </div>) : ''}
              </div>
              <div className="blue_border_holder"></div>
              <div className="member_profile_card_open_description mt-2">
                <Row>
                  <Col sm="3" className="member_profile_card_leftsection">
                    <div className="mb-2">
                      <Row noGutters>
                        <Col sm="2" className="text-center">
                          <img
                            className="stoptblogoimg"
                            alt="stopTB"
                            src={imgUrl.icon_ncd}
                          />
                        </Col>
                        <Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
                          <p className="member_profile_card_open_cat_title">
                            {filterNCD &&
                              filterNCD.length > 0 &&
                              filterNCD.map((memberName, index) => (
                                <>
                                  {filterNCD.length - 1 == index
                                    ? " " + memberName
                                    : " " + memberName + ","}
                                </>
                              ))
                            }
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-2">
                      <Row noGutters>
                        <Col sm="2" className="text-center">
                          <img
                            className="stoptblogoimg"
                            alt="stopTB"
                            src={imgUrl.icon_benpop}
                          />
                        </Col>
                        <Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
                          <p className="member_profile_card_open_cat_title">{totalBeneficiary ? totalBeneficiary : 'NA'}</p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-2">
                      <Row noGutters>
                        <Col sm="2" className="text-center">
                          <img
                            className="stoptblogoimg"
                            alt="stopTB"
                            src={imgUrl.icon_countries}
                          />
                        </Col>
                        <Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
                          <p className="member_profile_card_open_cat_title">
                            {getProgram[0].program_countries &&
                              getProgram[0].program_countries.length > 0 &&
                              getProgram[0].program_countries.map((countryName, index) => (
                                <>
                                  {getProgram[0].program_countries.length - 1 == index
                                    ? " " + countryName.Country_name
                                    : " " + countryName.Country_name + ","}
                                </>
                              ))
                            }
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-2">
                      <Row noGutters>
                        <Col sm="2" className="text-center">
                          <img
                            className="stoptblogoimg"
                            alt="stopTB"
                            src={imgUrl.icon_ad}
                          />
                        </Col>
                        <Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
                          <p className="member_profile_card_open_cat_title"><a onClick={() => window.open(getProgram[0].Program_profile_url, "_blank")} style={{cursor: "pointer"}}>Open Analytical Dashboard</a></p>
                        </Col>
                      </Row>
                    </div>
                    {getProgram[0].AO_profile_link ?
                    <div className="mb-2">
                      <Row noGutters>
                        <Col sm="2" className="text-center">
                          <img
                            className="stoptblogoimg"
                            alt="stopTB"
                            src={imgUrl.icon_ao}
                          />
                        </Col>
                        <Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
                          <p className="member_profile_card_open_cat_title"><a target="_blank" href={getProgram[0].AO_profile_link}>Open the AO Profile</a></p>
                        </Col>
                      </Row>
                    </div> : ''}
                  </Col>
                  <Col sm="9">

                    <p className="prog_ended_date">Ended: {getProgram[0].program_end_year ? getProgram[0].program_end_year : 'NA'}</p>
                    <p className="prog_title_company_name">{getProgram[0].program_name} - <span>
                      {getProgram[0].Members &&
                        getProgram[0].Members.length > 0 &&
                        getProgram[0].Members.map((memberName, index) => (
                          <>
                            {getProgram[0].Members.length - 1 == index
                              ? " " + memberName.member_name
                              : " " + memberName.member_name + ","}
                          </>
                        ))
                      }
                    </span></p>
                    <div className="member_desc_text"
                      dangerouslySetInnerHTML={{
                        __html: getProgram[0].Program_desc
                      }}>
                    </div>
                    <p className="seeprog_link text-right mt-2" onClick={() => window.open(getProgram[0].Program_profile_url, "_blank")} style={{ cursor: "pointer" }}>
                      <img
                        className="stoptblogoimg"
                        alt="stopTB"
                        src={imgUrl.member_prog_logo}
                      />
                      See Program Details
                      <img
                        className="stoptblogoimg ml-2 w-6px"
                        alt="stopTB"
                        src={imgUrl.right_arrow_white}
                      />
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        : ''}
    </>
  );
}

export default ProgramDetail;