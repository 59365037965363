import React, {useState, useEffect} from "react";

import { Container, Row, Col, Button } from "reactstrap";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

function KeyPopulation({ keyPop }) {
    const keyPopData = keyPop ? keyPop : {};
    const [population,setPopulation] = useState([]);
    const [ageGroup,setAgeGroup] = useState([]);
    const [gender,setGender] = useState([]);
    const [maxPop,setMaxPop] = useState(100);
    const [maxAge,setMaxAge] = useState(100);
    const [maxGender,setMaxGender] = useState(100);

    const keyPopColor = ['#C14234','#D46831','#E89A4C','#F3B35F','#F4CC94','#FFDFB0'];
    const ageColor = ['#295C92','#3879A9','#4F9EBF','#6FB8D4','#A3D1E1'];
    const genderColor = ['#731F58','#983077','#C15498','#E694BD'];

    const renderData = (data,state,maxState,colors) => {
        let newArr = [], maxVal = 0;
        data.map((val,index) => {
            newArr.push({ name: val.name, data: [val.program_count], color: colors[index] ? colors[index] : '#ccc' });
            maxVal = maxVal + val.program_count
        });
        state(newArr);
        maxState(maxVal);
    }

    const passData = () => {
        let spPop = keyPopData.special_population;
        let ageData = keyPopData.age_groups;
        let genData = keyPopData.gender;

        renderData(spPop,setPopulation,setMaxPop,keyPopColor);
        renderData(ageData,setAgeGroup,setMaxAge,ageColor);
        renderData(genData,setGender,setMaxGender,genderColor);
    }

    const options = {
        chart: {
            backgroundColor: '#343332',
            type: 'bar',
            height: "50%"
        },
        title: {
            text: 'Special populations',
            style: {
                color: "#ffffff",
                fontFamily: 'BrandonGrotesque-Regular',
                fontSize: "16px"
            }
        },
        xAxis: {
            visible: false,
            categories: ['Special populations']
        },
        yAxis: {
            reversed: true,
            visible: false,
            min: 0,
            max: maxPop ? maxPop : '',
            title: {
                text: ' '
            }
        },
        legend: {
            reversed: false,
            itemStyle: {
                color: "#fff",
                fontFamily: 'BrandonGrotesque-Regular'
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        credits: {
            enabled: false
        },
        series: population ? population : []
    }

    const options2 = {
        chart: {
            backgroundColor: '#343332',
            type: 'bar',
            height: "42%"
        },
        title: {
            text: 'Age groups',
            style: {
                color: "#ffffff",
                fontFamily: 'BrandonGrotesque-Regular',
                fontSize: "16px"
            }
        },
        xAxis: {
            visible: false,
            categories: ['Age groups']
        },
        yAxis: {
            reversed: true,
            visible: false,
            min: 0,
            max: maxAge ? maxAge : '',
            title: {
                text: ' '
            }
        },
        legend: {
            reversed: false,
            itemStyle: {
                color: "#fff",
                fontFamily: 'BrandonGrotesque-Regular'
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        credits: {
            enabled: false
        },
        series: ageGroup ? ageGroup : []
    }

    const options3 = {
        chart: {
            backgroundColor: '#343332',
            type: 'bar',
            height: "42%"
        },
        title: {
            text: 'Gender',
            style: {
                color: "#ffffff",
                fontFamily: 'BrandonGrotesque-Regular',
                fontSize: "16px"
            }
        },
        xAxis: {
            visible: false,
            categories: ['Gender']
        },
        yAxis: {
            reversed: true,
            visible: false,
            min: 0,
            max: maxGender ? maxGender : '',
            title: {
                text: ' '
            }
        },
        legend: {
            reversed: false,
            itemStyle: {
                color: "#fff",
                fontFamily: 'BrandonGrotesque-Regular'
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        credits: {
            enabled: false
        },
        series: gender ? gender : []
    }

    useEffect(() => {
        passData();
    },[keyPop])

    return (
        <>
            <Row>
                <Col lg="12" className="chartHeaderText">
                    <div className="">
                        <p className="chart-title mb-0">PROGRAM DISTRIBUTION BY KEY POPULATION</p>
                    </div>
                </Col>
                <Col lg="12" className="chart-holder" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                    <Row>
                        <Col lg="4">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                            />
                        </Col>
                        <Col lg="4">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options2}
                            />
                        </Col>
                        <Col lg="4">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options3}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" className="text-center">Number of Programs</Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default KeyPopulation;
