import React, { useState, useCallback, useEffect } from "react";

import { Collapse, Button, CardBody, Card, Row, Col } from "reactstrap";
import classnames from "classnames";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

import imgUrl from "../../assets/images/imgUrl.js";
import ProgramDetail from "./ProgramDetail";

function ProgramSlider(props) {
  let programID = props.programID ? props.programID : "";
  let programList = props.programList;
  const programsData = programList && programList.length > 0 ? programList : [];
  const filterProgId = props.flterProgSlider ? props.flterProgSlider : null;
  let CloseDetail = props.closeCountryDetail;
  const [collapse, setIsCollapse] = useState(false);
  const [program, setProgram] = useState([]);

  const toggle = (id) => {
    let getProgram = programsData.filter((e) => {
      return e.Program_id === id;
    });

    if (props.isCollapsed) {
      props.isCollapsed(true);
    }
    setProgram(getProgram);
    setIsCollapse(true);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Callback to get data from its child component(ProgramDetail)
  const callback = useCallback((value) => {
    setIsCollapse(value);
    if (props.isCollapsed) {
      props.isCollapsed(value);
    }
  }, []);

  useEffect(() => {
    toggle(programID);
  }, [programID]);

  useEffect(() => {
    toggle(filterProgId);
  }, [filterProgId]);

  useEffect(() => {
    setIsCollapse(false);
    if (props.isCollapsed) {
      props.isCollapsed(false);
    }
  }, [CloseDetail])

  return (
    <>
      <Collapse isOpen={collapse}>
        <ProgramDetail
          parentCallback={callback}
          programArr={program && program.length > 0 ? program : []}
        />
      </Collapse>
      <Row>
        <Col sm="12">
          <Slider {...settings}>
            {programList &&
              programList.length > 0 &&
              programList.map((sliderData) => (
                <div
                  key={sliderData.Program_id}
                  id={sliderData.Program_id}
                  onClick={() => toggle(sliderData.Program_id)}
                >
                  <div
                    className="member_profile_card_image"
                    style={{
                      backgroundImage: `url(${sliderData.program_logo})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    {sliderData.AO && sliderData.AO == "Y" ? (
                      <div className="member_profile_ooimage">
                        <img
                          className="stoptblogoimg"
                          alt="stopTB"
                          src={imgUrl.ao_circle}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="member_profile_description">
                      <div className="member_profile_title">
                        <p>{sliderData.program_name}</p>
                      </div>
                      <div className="member_profile_company_name">
                        <p>
                          {sliderData.Members &&
                            sliderData.Members.length > 0 &&
                            sliderData.Members.map((memberName, index) => (
                              <>
                                {sliderData.Members.length - 1 == index
                                  ? memberName.member_name
                                  : " " + memberName.member_name + ","}
                              </>
                            ))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </Col>
      </Row>
    </>
  );
}

export default ProgramSlider;
