import React, { useEffect, useState } from "react";
import {
    Container,
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Table,
    TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import classnames from 'classnames';
import FileUpload from '../components/FileUpload';
import RegisterUser from "../components/RegisterUser";

const UploadData = () => {
    const [activeTab, setActiveTab] = useState('1'); // Active Tabs
    const [selectedFileType, setFileType] = useState(); // Set state for file Type
    const [userObj, setUserObj] = useState(''); // Set state for file Type
    const [userJSON ,setUserJSON] = useState({}); // Set state for file Type
    const userName = localStorage.getItem('userName');
    const [instruction, setInstruction] = useState('');
    const [sampleFile, setSampleFile] = useState('');

    const GHPExport = (<p>This is GHP Export</p>);
    const CovidDaily = (<p>Covid Daily Data</p>);
    const CovidStringency = (<p>This is Covid stringency Data</p>);
    const CovidResponse = (<p>This is Covid response category data</p>);

    const instructionObj = {
        'GHP export data': GHPExport,
        'Covid daily data': CovidDaily,
        'Covid stringency Data': CovidStringency,
        'Covid response category data': CovidResponse,
    }

    const sampleFileUrl = {
        'GHP export data': 'https://aaopenplatform.accessaccelerated.org/sample-files/GHP_AAOP.csv',
        'Covid daily data': 'https://aaopenplatform.accessaccelerated.org/sample-files/covid_data_template.xlsx',
        'Covid stringency Data': 'https://aaopenplatform.accessaccelerated.org/sample-files/covid_stringency_data_template.xlsx',
        'Covid response category data': 'https://aaopenplatform.accessaccelerated.org/sample-files/covid_response_data_template.xlsx',
    }

    const changeFileType = (event) => { // For select list
        if (event.target.value !== undefined) {
            setFileType(event.target.value);
            showInstruction(event.target.value);
        }
    };

    const showInstruction = (selectedVal) => {
        setInstruction(instructionObj[selectedVal]);
        setSampleFile(sampleFileUrl[selectedVal]);
    }   
    
    const toggle = tab => { // Tab selection
        if (activeTab !== tab) setActiveTab(tab);
    }

    const getLocalVal = () => {
        const getData = localStorage.getItem('DataObj');
        setUserObj(getData);
        const userData = JSON.parse(getData);

        if ('Role' in userData && 'UserId' in userData) {
            setUserJSON({ role: userData.Role, uid: userData.UserId })
        }
    }

    useEffect(() => {
        getLocalVal();
    },[userObj])
    
    return (
        <div>
            <div className="content-top-gap uploadDataPage">
                <Container className="themed-container" fluid={true}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >
                                Upload
                            </NavLink>
                        </NavItem>
                        { userName === 'dure_admin' || userJSON.role === 1 ? 
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                User Management
                            </NavLink>
                        </NavItem> : '' }
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <h4 className="tabTitle">File Upload</h4>
                                    <hr></hr>
                                </Col>
                                <Col sm="3">
                                    <FormGroup>
                                        <Label for="exampleSelect">File Type</Label>
                                        <Input type="select" name="select" id="exampleSelect" onChange={changeFileType}>
                                            <option value="N/A">N/A</option>
                                            <option value="GHP export data">GHP export data</option>
                                            <option value="Covid daily data">Covid daily data</option>
                                            <option value="Covid stringency Data">Covid stringency index data</option>
                                            <option value="Covid response category data">Covid response data</option>
                                            <option value="Program banner logo">Program banner image</option>
                                            <option value="Member logo">AA member logo</option>
                                            <option value="NCD logo">NCD logo</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="2" style={{ display: 'flex', alignItems: 'center', paddingTop: '12px',paddingLeft: '0', textDecoration: 'underline'}}>
                                    {sampleFile ? 
                                    <a href={sampleFile} title="download Sample file" download>Sample file</a>
                                    : ''}
                                </Col>
                                <Col sm="12">{instruction}</Col>

                            </Row>
                            <FileUpload type={selectedFileType} />
                            <Row>
                                <Col sm="12">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>File Id</th>
                                                <th>File Name</th>
                                                <th>Upload Status</th>
                                                <th>Upload By</th>
                                                <th>Upload Date</th>
                                                <th>Comment</th>
                                                <th>Action(s)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="7" style={{textAlign: 'center', fontSize: '16px', fontWeight: '600'}}>No data available!</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                        </TabPane>
                        <TabPane tabId="2">
                            <RegisterUser loginUser={userJSON}/>
                        </TabPane>
                        <TabPane tabId="3">

                        </TabPane>
                    </TabContent>

                </Container>
            </div>
        </div>
    );
}

export default UploadData;