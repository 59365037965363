import React, { useState, useCallback, useEffect } from "react";

import { Collapse, Button, CardBody, Card, Row, Col } from "reactstrap";
import classnames from "classnames";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

import imgUrl from "../../assets/images/imgUrl.js";

import CountryDetail from "./CountryDetail";

function CountrySlider({ countryList, filterCountryArr, closeCountryDetail, callParentFun, isCollapsed }) {
  let countryListArr = countryList;
  let getFilterCountryArr = filterCountryArr;
  let CloseDetail = closeCountryDetail;
  const countryData = countryListArr && countryListArr.length > 0 ? countryListArr : [];
  const [collapse, setIsCollapse] = useState(false);
  const [country, setCountry] = useState([]);

  const toggle = (id) => {
    // console.log("country ID", id);
    let getCountry = countryData.filter((e) => {
      return e.iso3_code === id;
    });
    // console.log("bigCities", getCountry);
    isCollapsed(true);
    setCountry(getCountry);
    setIsCollapse(true);
  };

  const CountryPop = (getPopulation) => {
    if (getPopulation) {
      let num = getPopulation;
      const TotalVal = (num) => {
        if (num > 999 && num < 1000000) {
          return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
        } else if (num > 1000000) {
          return (num / 1000000).toFixed(1) + "M";
        } else if (num < 900) {
          return num; // if value < 1000, nothing to do
        }
      };
      return TotalVal(num); //Pasing Total beneficiary State
    } else {
      return "NA";
    }
  };

  const settingsvertical = {
    dots: false,
    infinite: false,
    speed: 500,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Callback to get data from its child component(CountryDetail)
  const callback = useCallback((value) => {
    setIsCollapse(value);
    isCollapsed(value);
  }, []);

  const programCallback = useCallback((value) => {
    callParentFun(value);
  }, []);

  useEffect(() => {
    setIsCollapse(false);
    isCollapsed(false);
  }, [CloseDetail])

  return (
    <>
      <Collapse isOpen={collapse}>
        <Row>
          <Col sm="9">
            <CountryDetail
              parentCallback={callback}
              parentProgram={programCallback}
              countryArr={country && country.length > 0 ? country : []}
            />
          </Col>
          <Col sm="3">
            <div className="slider_vertical_holder">
              <Slider {...settingsvertical} className="slider_vertical">
                {countryData &&
                  countryData.length > 0 &&
                  countryData.map((sliderData) => (
                    <div id={sliderData.iso3_code} key={sliderData.iso3_code} onClick={() => toggle(sliderData.iso3_code)}>
                      <div className="lmi_countries_card" style={{
                        background: `linear-gradient(270deg, #1A1A1A00 0%, #0C0C0CB7 35%, #161616 100%), url(${sliderData.country_logo})`
                      }}>
                        <div className="lmi_contries_card_titleholder">
                          <p>{sliderData.Country_name}</p>
                          <div className="white_border_holder_small"></div>
                        </div>

                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Collapse>
      <Collapse isOpen={!collapse}>
        <Row>
          <Col sm="12">
            {getFilterCountryArr && getFilterCountryArr.length > 0 ?
              <Slider {...settings}>
                {getFilterCountryArr &&
                  getFilterCountryArr.length > 0 &&
                  getFilterCountryArr.map((sliderData) => (
                    <div
                      key={sliderData.iso3_code}
                      id={sliderData.iso3_code}
                      onClick={() => toggle(sliderData.iso3_code)}
                    >
                      <div
                        className="lmi_countries_card"
                        style={{
                          background: `linear-gradient(270deg, #1A1A1A00 0%, #0C0C0CB7 35%, #161616 100%), url(${sliderData.country_logo})`
                        }}
                      >
                        <div className="lmi_contries_card_titleholder">
                          <p>{sliderData.Country_name}</p>
                        </div>
                        <div className="lmi_countries_card_descriptionholder">
                          <Row noGutters>
                            <Col xs="6">
                              <p className="cat_title">Population:</p>
                            </Col>
                            <Col xs="6">
                              <p className="cat_subtitle">
                                {CountryPop(sliderData.counry_population)} (2021)
                              </p>
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col xs="5">
                              <p className="cat_title">Programs:</p>
                            </Col>
                            <Col xs="7">
                              <p className="cat_subtitle">
                                {sliderData["total no of program"]
                                  ? sliderData["total no of program"]
                                  : "NA"}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  ))} </Slider> :
              <Slider {...settings}>
                {countryData &&
                  countryData.length > 0 &&
                  countryData.map((sliderData) => (
                    <div
                      key={sliderData.iso3_code}
                      id={sliderData.iso3_code}
                      onClick={() => toggle(sliderData.iso3_code)}
                    >
                      <div
                        className="lmi_countries_card"
                        style={{
                          background: `linear-gradient(270deg, #1A1A1A00 0%, #0C0C0CB7 35%, #161616 100%), url(${sliderData.country_logo})`
                        }}
                      >
                        <div className="lmi_contries_card_titleholder">
                          <p>{sliderData.Country_name}</p>
                        </div>
                        <div className="lmi_countries_card_descriptionholder">
                          <Row noGutters>
                            <Col xs="6">
                              <p className="cat_title">Population:</p>
                            </Col>
                            <Col xs="6">
                              <p className="cat_subtitle">
                                {CountryPop(sliderData.counry_population)} (2021)
                              </p>
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col xs="5">
                              <p className="cat_title">Programs:</p>
                            </Col>
                            <Col xs="7">
                              <p className="cat_subtitle">
                                {sliderData["total no of program"]
                                  ? sliderData["total no of program"]
                                  : "NA"}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            }
          </Col>
        </Row>
      </Collapse>
    </>
  );
}

export default CountrySlider;
