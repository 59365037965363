import { Pointer } from 'highcharts';
import { point } from 'leaflet';
import React, { useEffect, useState, useCallback } from 'react';
import {
    Table,
    TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText, Collapse, CardBody,
} from "reactstrap";
import apiBaseUrl from "../config";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserDetails({userData, parentCallback, loggedIn}) {
    let userDetails = userData.userData;
    const loggedInuserID = loggedIn;
    console.log('loggedIn',loggedInuserID)
    const [userInfo, getUserInfo] = useState(userDetails);
    const [editUser, getEditUser] = useState([])

    const toastBox = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: '',
    }

    const getUserDetails = async () => {
        await fetch(
            `${apiBaseUrl}getUserDetails`
          )
            .then((response) => response.json())
            .then((data) => {
              const Data = data;
              if(localStorage.getItem("userName") === null) {
                getUserInfo(Data);
              } else {
                const getNameFromStorage = localStorage.getItem("userName");
                userDetails = Data.filter(name => name.username != getNameFromStorage);
                if(loggedInuserID && loggedInuserID != 88) {
                    userDetails = userDetails.filter(id => id.created_user === loggedInuserID);
                }
                console.log('userDetails page',userDetails)
                getUserInfo(userDetails);
              }
            })
            .catch((err) => {
              console.log(`${err} while contacting the quote API.`);
            });
    }


    const removeUser = async (id) => {
        const userID = id;

        try {
            await fetch(`${apiBaseUrl}delete/${userID}` , {
                method: 'DELETE',
              }).then(response => response.text())
              .then(data => {
                toast(`${data}`,toastBox)
                
                // Update the User Table after deleting User Row
                getUserDetails();
              });
        } catch(err) {
            if(err.response.status === 500) {
                console.log('There was problem with the server');
            } else {
                console.log(err.response.data.msg);
            }
        }
    }

    const editUserData = async (id) => {
        const userID = id;

        try {
            await fetch(`${apiBaseUrl}edit/${userID}`  , {
                method: 'GET',
              }).then(response => response.text())
              .then(data => {
                getEditUser(data);
                parentCallback(data)
              });
        } catch(err) {
            if(err.response.status === 500) {
                console.log('There was problem with the server');
            } else {
                console.log(err.response.data.msg);
            }
        }
    }

    useEffect(()=> {
        getUserDetails();
    },[])

    useEffect(()=> {
        getUserDetails();
    },[userDetails])

    return (
        <Table>
            <thead>
                <tr>
                    <th>User Id</th>
                    <th>User Name</th>
                    <th>Email Address</th>
                    <th>Is Active</th>
                    <th>Creation Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {userInfo && userInfo.length > 0 ? userInfo.map((user) => (
                    <tr key={user.userid}>
                        <td>{user.userid}</td>
                        <td>{user.username}</td>
                        <td>{user.email}</td>
                        <td>{user.isactive === 1 ? "Yes" : "No"}</td>
                        <td>{user.creation_ts}</td>
                        <td>
                            <span style={{cursor: 'Pointer', marginRight: '10px', textDecoration: 'underline'}} onClick={(e) => editUserData(user.userid)}>Edit</span>
                            <span style={{cursor: 'Pointer', textDecoration: 'underline'}} onClick={(e) => removeUser(user.userid)}>Remove</span>
                        </td>
                    </tr>
                )) : (
                    <tr>
                        <td colSpan='6' style={{textAlign: 'center', fontSize: '16px', fontWeight: '600'}}>No data available!</td>
                    </tr>
                ) }
            </tbody>
        </Table>
    )
}

export default UserDetails;