import React, { useState, useCallback, useEffect } from "react";

import { Collapse, Button, CardBody, Card, Row, Col } from 'reactstrap';
import classnames from "classnames";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

import NcdDetail from "./NcdDetail";

function NcdSlider({ ncdList, closeCountryDetail, callParentFun, isCollapsed }) {
	const ncdListArr = ncdList;
	const ncdData = ncdListArr && ncdListArr.length > 0 ? ncdListArr : [];
	let CloseDetail = closeCountryDetail;
	const [collapse, setIsCollapse] = useState(false);
	const [ncd, setNCD] = useState([]);

	const toggle = (id) => {
		console.log("country ID", id);
		let getNcd = ncdData.filter((e) => {
			return e.ncd_id === id;
		});

		isCollapsed(true);
		setNCD(getNcd);
		setIsCollapse(true);
	}

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 5,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
					infinite: true,
					dots: false,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: false,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	const NcdClassName = (ncdName) => {
		if (ncdName == "Cancer") {
			return "cancer_card";
		} else if (ncdName == "Cardiovascular Disease") {
			return "cvd_card";
		} else if (ncdName == "Diabetes") {
			return "diabetes_card";
		} else if (ncdName == "Respiratory Diseases") {
			return "resp_card";
		} else if (ncdName == "Other") {
			return "other_card";
		}

	}

	// Callback to get data from its child component(CountryDetail)
	const callback = useCallback((value) => {
		setIsCollapse(value);
		isCollapsed(value);
	}, []);

	const programCallback = useCallback((value) => {
		callParentFun(value);
	}, []);

	useEffect(() => {
		setIsCollapse(false);
		isCollapsed(false);
	}, [CloseDetail])

	return (
		<>
			<Collapse isOpen={collapse}>
				<Row>
					<Col sm="9">
						<NcdDetail parentCallback={callback} parentProgram={programCallback} ncdArr={ncd && ncd.length > 0 ? ncd : []} />
					</Col>
					<Col sm="3">

						<div className="slider_vertical_holder ncdlist_vertical_holder">
							{
								ncdData &&
								ncdData.length > 0 &&
								ncdData.map((sliderData) => (
									<div className={`add_ncd_card ${NcdClassName(sliderData.ncd_name)}`} key={sliderData.ncd_id} id={sliderData.ncd_id} onClick={() => toggle(sliderData.ncd_id)}>

										<div className="add_ncd_card_descriptionholder">
											<p>{sliderData.ncd_name && sliderData.ncd_name == "Cardiovascular Disease" ? "CVDs" : sliderData.ncd_name}</p>
											<div className="white_border_holder_small"></div>

										</div>
									</div>))}
						</div>
					</Col>
				</Row>
			</Collapse>
			<Collapse isOpen={!collapse}>
				<Row>
					<Col sm="12">
						<Slider {...settings}>
							{
								ncdData &&
								ncdData.length > 0 &&
								ncdData.map((sliderData) => (
									<div key={sliderData.ncd_id} id={sliderData.ncd_id} onClick={() => toggle(sliderData.ncd_id)}>
										<div className={`add_ncd_card ${NcdClassName(sliderData.ncd_name)}`}>
											<div className="add_ncd_card_titleholder">
												<p>{sliderData.ncd_name && sliderData.ncd_name == "Cardiovascular Disease" ? "CVDs" : sliderData.ncd_name}</p>
											</div>
											<div className="add_ncd_card_descriptionholder">
												<Row noGutters>
													<Col xs="7">
														<p className="cat_title">Types:</p>
													</Col>
													<Col xs="5">
														<p className="cat_subtitle">{sliderData["sub types"].length}</p>
													</Col>
												</Row>
												<Row noGutters>
													<Col xs="7">
														<p className="cat_title">Programs:</p>
													</Col>
													<Col xs="5">
														<p className="cat_subtitle">{sliderData["total no of program"]}</p>
													</Col>
												</Row>
												<Row noGutters>
													<Col xs="7">
														<p className="cat_title">Most Prevalent:</p>
													</Col>
													<Col xs="5">
														<p className="cat_subtitle">{sliderData["most prevelant"] ? (sliderData["most prevelant"] == 'General Cardiovascular' ? 'General CVDs' : sliderData["most prevelant"]) : "NA"}</p>
													</Col>
												</Row>
											</div>
										</div>
									</div>
								))}
						</Slider>
					</Col>
				</Row>
			</Collapse>
		</>
	)
}

export default NcdSlider;
