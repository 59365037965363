import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Nav, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";

import imgUrl from "../../assets/images/imgUrl.js";
import apiBaseUrl from "../../config";

const SecondaryNavbar = ({setAuth}) => {
  let history = useHistory();
  const [name, setName] = useState("");

  const getProfile = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await fetch(`${apiBaseUrl}dashboard`, {
        method: "POST",
        headers: { jwt_token: token }
      });
      console.log(res);
      // if (!res.ok) {
      //   if ([401, 403].includes(res.status)) {
      //     console.log("You are not authorize person")
      //       // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      //       // localStorage.removeItem("token");
      //       // localStorage.removeItem("userName");
      //       setAuth(false);
      //       setName("");
      //       // history.push('/home');
      //   }
      // }
      const parseData = await res.json();
      setName(parseData.username);
    } catch (err) {
      console.error(err.message);
    }
  };

  const logout = async e => {
    e.preventDefault();
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("userName");
      localStorage.removeItem("DataObj");
      setAuth(false);
      setName("");
      let path = `/home`;
      history.push(path);
      // toast.success("Logout successfully");
    } catch (err) {
      console.error(err.message);
    }
  };

  const LoginRedirect = () => {
    let path = `/login`;
    
    history.push(path);
  }

  const setActivePage = (page) => {
    localStorage.setItem("activePage", page);
  };

  const setLandingPage = (page) => {
    localStorage.setItem("landingPageIs", page);
    localStorage.setItem("onClick", "navbarClick");
    if (page === "dashboard") {
      setActivePage("ncdDashboard");
    } else {
      setActivePage("home");
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
    <div className="secondary-navholder secondary-ncd-overview-dashboard">
      <Container>
        <Row>
          <Col className="d-flex justify-content-between">
            <Nav className="container justify-content-center">
              <NavLink href="https://aaopenplatform.accessaccelerated.org/">
                <img
                  className=""
                  alt=""
                  src={imgUrl.left_arrow_white}
                  style={{ width: "10px", marginRight: "4px" }}
                />{" "}
                Back to the Open Platform
              </NavLink>
              
              <NavLink
                className="pr-2 faqs-navLink"
                href="/programs"
                target="_blank"
                onClick={() => setLandingPage("dashboard")}
              >

                ANALYTICAL DASHBOARD
                <img
                  className=""
                  alt=""
                  src={imgUrl.areaChart}
                  style={{ width: "12px", marginLeft: "6px" }}
                />
                <img
                  className=""
                  alt=""
                  src={imgUrl.rightIcon}
                  style={{ width: "10px", marginLeft: "6px" }}
                />
              </NavLink>
            </Nav>
          </Col>
          
        </Row>
        
      </Container>
    </div>
    <div>
        <p className="db-description" style={{textAlign:'center', padding:'5px', backgroundColor:'#F26333', margin:'0px', fontSize:'14px'}}>Please note that the NCD Dashboard is no longer receiving updates or new data. The data presented was last updated in 2021.</p>
    </div>
    </>
  );
};

export default SecondaryNavbar;
