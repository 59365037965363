import React, { useState, useMemo, useRef, useEffect } from "react";
//import { render } from "react-dom";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  //Tooltip,
  useMap,
  FeatureGroup,
  Popup,
  Circle,
  Tooltip,
  ZoomControl
} from "react-leaflet";

import { ListGroup, ListGroupItem, Table, Row, Col } from "reactstrap";
import "../../assets/css/customstyles.css";
import imgUrl from "../../assets/images/imgUrl";

const LeafletMap = ({
  mapKey,
  setMapKey,
  //mapHeight,
  mapData,
  geoJsonData,
  mapView,
  activeTab,
  parentCall,
  activeCountry,
  filterMap,
  filterIsoCode,
  filterNetwork,
  partenersData
}) => {
  // console.log("mapData geoJsonData",geoJsonData)
  const [currentMapReference, setCurrentMapReference] = useState([]);
  const [mapZoom, setMapZoom] = useState(2);
  const [legendChange, setLegendChange] = useState(0);
  const [legendDisabled, setLegendDisabled] = useState({
    'hrh':false,
    'civilsociety':false,
    'internationalbodies':false,
    'plwncds':false,
    'privatesector':false,
    'government':false
  })
  const [mapCenter, setMapCenter] = useState([
    // 33.7243396617476,
    // 6.013053729738964,
    0,0
  ]);
  const [thamaticData,setThamaticData] = useState([])
  const [filterActor,setFilterActor] = useState('all')
  const [clickCountry,setClickCountry] = useState({})

  const [lowScales, setLowScale] = useState([1, 6, 11, 16, 26]);
  const [highScales, setHighScale] = useState([5, 10, 15, 25, 35]);
  const [colorScales, setColorScale] = useState([
    "#83CED3",
    "#2DA6B8",
    "#0D7791",
    "#187092",
    "#045F85",
  ]);

  const mapRef = useRef();
  const [drilledCountry, setDrilledCountry] = useState([]);
  const [mapLevel, setMapLevel] = useState(1);
  const [drilldownGeoJson, setDrilldownGeoJson] = useState([]);
  const [drilldownMapData, setDrilldownMapData] = useState([]);

  const mapHotspotColor = (feature) => {
    //let hotspotColor = getMapColor(featureValue);
    let hotspotColor = "#9A7398";
    return {
      color: "#FFF",
      fillColor: hotspotColor,
      weight: 1,
      fillOpacity: 0.6,
    };
  };

  const mapHotspotCenter = (feature) => {
    let layerFeatureJSON = L.geoJson(feature);
    let mapCentroid = layerFeatureJSON.getBounds();
    return mapCentroid.getCenter();
  };

  const mapHotspotRadius = (feature) => {
    const index = mapData.findIndex(
      (country) => country.iso3_code === feature.properties.iso_a3
    );

    let featureValue = 0;
    let beneficiaryPopulation = 0;

    if (index !== -1) {
      featureValue =
        mapData[index]["catchement_Population_served"] !== null
          ? mapData[index]["catchement_Population_served"]["CatchmentAreas"]
          : "N/A";
      beneficiaryPopulation = featureValue[0]["total_pop_served"];
    }

    if (beneficiaryPopulation !== undefined && beneficiaryPopulation !== "") {
      return Number(beneficiaryPopulation) > 4000000
        ? Number(beneficiaryPopulation) / 25
        : Number(beneficiaryPopulation) > 3000000
        ? Number(beneficiaryPopulation) / 10
        : Number(beneficiaryPopulation) > 2000000
        ? Number(beneficiaryPopulation) / 5
        : Number(beneficiaryPopulation) > 1000000
        ? Number(beneficiaryPopulation) / 3
        : Number(beneficiaryPopulation) > 500000
        ? Number(beneficiaryPopulation) / 2
        : Number(beneficiaryPopulation) / 2;
      //return beneficiaryPopulation;
    } else {
      return 0;
    }
  };

  const mapHotspotRadiusPartner = (feature) => {
    const index = partenersData.findIndex(
      (country) => country.iso3_code === feature.properties.iso_a3
    );

    let featureValue = 0;
    let beneficiaryPopulation = 0;

    // if (index !== -1) {
    //   featureValue =
    //     mapData[index]["catchement_Population_served"] !== null
    //       ? mapData[index]["catchement_Population_served"]["CatchmentAreas"]
    //       : "N/A";
    //   beneficiaryPopulation = featureValue[0]["total_pop_served"];
    // }

    if (index !== -1) {
      return Number('500000')
      //return beneficiaryPopulation;
    } else {
      return 0;
    }
  };

  const mapHotspotPopup = (feature) => {
    const index = mapData.findIndex(
      (country) => country.iso3_code === feature.properties.iso_a3
    );

    let featureValue = 0;
    let beneficiaryPopulation = 0;

    if (index !== -1) {
      featureValue =
        mapData[index]["catchement_Population_served"] !== null
          ? mapData[index]["catchement_Population_served"]["CatchmentAreas"]
          : "N/A";
      beneficiaryPopulation = featureValue[0]["total_pop_served"];
    }

    let toolTipContent = "";

    if (beneficiaryPopulation !== undefined && beneficiaryPopulation !== "") {
      toolTipContent =
        "<p><b>" +
        feature.properties.name +
        "</b></p><p>Beneficiary Population: " +
        beneficiaryPopulation +
        "</p>";
      return toolTipContent;
    } else {
      toolTipContent =
        "<p><b>" +
        feature.properties.name +
        "</b></p><p>Beneficiary Population: N/A</p>";
      return toolTipContent;
    }
  };

  const mapHotspotLocation = (feature) => {
    const index = mapData.findIndex(
      (country) => country.iso3_code === feature.properties.iso_a3
    );

    if (index !== -1) {
      //return feature.properties.name;
      return mapData[index].Country_name;
    }
  };

  const mapHotspotContent = (feature) => {
    const index = mapData.findIndex(
      (country) => country.iso3_code === feature.properties.iso_a3
    );

    let featureValue = 0;
    let beneficiaryPopulation = 0;

    if (index !== -1) {
      featureValue =
        mapData[index]["catchement_Population_served"] !== null
          ? mapData[index]["catchement_Population_served"]["CatchmentAreas"]
          : "N/A";
      beneficiaryPopulation = featureValue[0]["total_pop_served"];
    }

    let toolTipContent = "";

    if (beneficiaryPopulation !== undefined && beneficiaryPopulation !== "") {
      toolTipContent = beneficiaryPopulation
        .toLocaleString("en-US");
        // .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return toolTipContent;
    } else {
      toolTipContent = "N/A";
      return toolTipContent;
    }
  };

  const mapAreasCount = (feature) => {

    const index = mapData.findIndex(
      (country) => country.iso3_code === feature.properties.iso_a3
    );

    let featureValue = 0;
    let areasCount = 0;

    if (index !== -1) {
      featureValue =
        mapData[index]["catchement_Population_served"] !== null &&
        mapData[index]["catchement_Population_served"]["CatchmentAreas"] !==
          null
          ? mapData[index]["catchement_Population_served"]["CatchmentAreas"]
          : "N/A";
      if (featureValue !== "N/A") {
        //console.log(featureValue[0]["Catchment_areas"]);
        areasCount = featureValue[0]["Catchment_areas"].length;
      }
    }

    let toolTipContent = "";

    if (areasCount !== undefined && areasCount !== "") {
      toolTipContent = areasCount
        .toLocaleString("en-US");
        // .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      return toolTipContent;
    } else {
      toolTipContent = "N/A";
      return toolTipContent;
    }
  };

  const mapAreasList = (feature) => {

    const index = mapData.findIndex(
      (country) => country.iso3_code === feature.properties.iso_a3
    );

    let featureValue = 0;
    let areasList = [];

    if (index !== -1) {
      featureValue =
        mapData[index]["catchement_Population_served"] !== null &&
        mapData[index]["catchement_Population_served"]["CatchmentAreas"] !==
          null
          ? mapData[index]["catchement_Population_served"]["CatchmentAreas"]
          : "N/A";
      if (featureValue !== "N/A") {
        //console.log(featureValue[0]["Catchment_areas"]);
        areasList = featureValue[0]["Catchment_areas"];
      }
    }

    let toolTipContent = "";
    let tempArray = [];

    if (areasList.length > 0 && areasList !== undefined && areasList !== "") {
      for (let i in areasList) {
        tempArray.push(areasList[i].catchment_province);
      }
      toolTipContent = tempArray.join(", ");
      return toolTipContent;
    } else {
      toolTipContent = "N/A";
      return toolTipContent;
    }
  };

  /***************** Partners Map *****************/
  // if(filterIsoCode && filterIsoCode.length > 0) {
  //   let filterCountry = mapData.filter(
  //     (country) => country.iso3_code == filterIsoCode
  //   );

  //   // console.log("filterIsoCode",filterCountry,geoJsonData)
  // }

  const zoomLayer = (layer) => {
    console.log("layer",layer)
  }

  const partnersGeoJsonStyle = (feature) => {
    let index = "";

    if (mapLevel === 1) {
      if(thamaticData && thamaticData.length > 0 && feature) {
        // console.log("in thametic data",feature)
        index = thamaticData.findIndex(
          (country) => country.iso3_code === feature.properties.iso_a3
        );
      } else {
        // console.log("in mapdata data", feature)
        index = feature && mapData.findIndex(
          (country) => country.iso3_code === feature.properties.iso_a3
        );
      }
    } else {
      console.log("something went wrong!")
    }

    if (index !== -1) {
      let countryData;
      if(thamaticData && thamaticData.length > 0 && feature) {
        countryData = thamaticData.filter(
          (country) => country.iso3_code === feature.properties.iso_a3
        );
      } else {
        countryData = feature && mapData.filter(
          (country) => country.iso3_code === feature.properties.iso_a3
        );
      }
      
      // console.log("countryData",countryData)
      if(Object.keys(clickCountry).length > 0) {
        filterIsoCode = ""
        if(countryData && countryData[0].iso3_code === clickCountry.properties.iso_a3) {
          return {
            fillColor: '#f26432',
            weight: 0.5,
            opacity: 1,
            color: "#fff",
            //dashArray: "3",
            fillOpacity: 1,
          };
        }
      }

      if(filterIsoCode && filterIsoCode.length > 0) {
        if(countryData && countryData[0].iso3_code == filterIsoCode) {
          activeAreas([],filterIsoCode);
          zoomLayer();
          return {
            fillColor: '#f26432',
            weight: 0.5,
            opacity: 1,
            color: "#fff",
            //dashArray: "3",
            fillOpacity: 1,
          };
        }
      }
      
      // let getColor = countryData && countryData[0]?.Partners !== null && filterActor == 'all' ? "#000" : getMapColor(0)
      let getColor;
      if(countryData && countryData[0]?.Partners !== null) {
        if(filterActor == 'all') {
          getColor = '#000'  
        } else if(filterActor == 'PLWNCDs') {
          getColor = '#52b335'
        } else if(filterActor == 'GOVERNMENT') {
          getColor = '#015c84'
        } else if(filterActor == 'Human resourses in healthcare  (HRH)') {
          getColor = '#0b7451'
        } else if(filterActor == 'INTERNATIONAL BODIES') {
          getColor = '#930c62'
        } else if(filterActor == 'PRIVATE SECTOR') {
          getColor = '#c41111'
        } else if(filterActor == 'CIVIL SOCIETY') {
          getColor = '#ffba18'
        } else {
          getColor = '#000';
        }
      } else {
        getColor = getMapColor(0);
      }

      return {
        fillColor: getColor,
        weight: 0.5,
        opacity: 1,
        color: "#fff",
        //dashArray: "3",
        fillOpacity: 1,
      };
    } else {
      return {
        fillColor: getMapColor(0),
        weight: 0.5,
        opacity: 1,
        color: "#fff",
        //dashArray: "3",
        fillOpacity: 1,
      };
    }
  };

  const [screenWidth, setScreenWidth] = useState(getWindowDimensions())

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const partnersOnEachFeature = (feature, layer) => {
    // console.log("getLayer",layer)
    if (feature.properties && feature.properties.name) {
      //layer.bindPopup(feature.properties.name);

      //console.log(activeTab);
      let toolTipContent = "";
      let appendData = "<p style='margin: 0'><b>Partners:</b></p>"

      if (mapLevel === 1 || mapLevel === 3) {
        const countryData = mapData.filter(
          (country) => country.iso3_code === feature.properties.iso_a3
        );

        if(countryData && countryData.length > 0) {
            if(countryData[0].Partners && countryData[0].Partners.length > 0) {
              for (let index = 0; index < countryData[0].Partners.length; index++) {
                const element = countryData[0].Partners[index];
                // appendData = "<p style='margin: 0'><b>Partners:</b></p>"
                appendData += `<p style="margin: 0;"><img style='width: 12px; margin-right: 4px' src='${element.partner_name == 'NCD Alliance' ? imgUrl.ncdImg : (element.partner_name == 'Path') ? imgUrl.pathImg : (element.partner_name == 'World Bank') ? imgUrl.worldBankImg : (element.partner_name == 'City Cancer Challenge (C/CAN)') ? imgUrl.ccanImg : (element.partner_name == 'World Heart Federation') ? imgUrl.whfImg : ""}' />${element.partner_name}</p>`;
              }
            } else {
              appendData = "<p style='margin: 0'><b>Partners:</b> NA</p>"
            }
        }

       if (activeTab === "10") {
          // console.log("active tab 10");
          toolTipContent = `
          <p>
            <b style="font-size: 14px;">${feature.properties.name}</b>
          </p>
          ${appendData}
        `;
        } else {
            toolTipContent = `
              <p>
                <b>${feature.properties.name}</b>
              </p>
              <p>N/A</p>
            `;
          }
      }

      // layer.bindPopup(toolTipContent, {
      //   maxWidth: "250",
      //   className: "custom",
      // });
      
      layer.bindTooltip(toolTipContent, {
        direction: "auto",
        sticky: true,
        permanent: false,
      });

      layer.on({
        mouseover: (e) => {
          let thisLayer = e.target;
          thisLayer.setStyle({
            weight: 1.5,
            opacity: 1,
            color: "#fff",
            //dashArray: '',
            fillOpacity: 0.7,
          });
        },
        mouseout: (e) => {
          let thisLayer = e.target;

          thisLayer.setStyle({
            weight: 0.5,
            opacity: 1,
            color: "#fff",
            //dashArray: '',
            fillOpacity: 1,
          });
          //info.update();
        },
        popupopen: (e) => {
        },
        click: function (e) {
          filterNetwork(e.target.feature)
          setMapCenter([e.latlng.lat,e.latlng.lng]);
          setMapZoom(3);
          setClickCountry(e.target.feature)
          activeAreas(e.target.feature);
          partnersGeoJsonStyle()
          setLegendChange(legendChange + 1);
          setMapKey(mapKey + 1)
        },
        dblclick: function (e) {
          // console.log("double click")
        },
      });
    }
  };

  const partnersOnEachFeatureMob = (feature, layer) => {
    // console.log("getLayer",layer)
    if (feature.properties && feature.properties.name) {
      //layer.bindPopup(feature.properties.name);

      //console.log(activeTab);
      let toolTipContent = "";
      let appendData = "<p style='margin: 0'><b>Partners:</b></p>"

      if (mapLevel === 1 || mapLevel === 3) {
        const countryData = mapData.filter(
          (country) => country.iso3_code === feature.properties.iso_a3
        );

        if(countryData && countryData.length > 0) {
            if(countryData[0].Partners && countryData[0].Partners.length > 0) {
              for (let index = 0; index < countryData[0].Partners.length; index++) {
                const element = countryData[0].Partners[index];
                // appendData = "<p style='margin: 0'><b>Partners:</b></p>"
                appendData += `<p style="margin: 0;"><img style='width: 12px; margin-right: 4px' src='${element.partner_name == 'NCD Alliance' ? imgUrl.ncdImg : (element.partner_name == 'Path') ? imgUrl.pathImg : (element.partner_name == 'World Bank') ? imgUrl.worldBankImg : (element.partner_name == 'City Cancer Challenge (C/CAN)') ? imgUrl.ccanImg : (element.partner_name == 'World Heart Federation') ? imgUrl.whfImg : ""}' />${element.partner_name}</p>`;
              }
            } else {
              appendData = "<p style='margin: 0'><b>Partners:</b> NA</p>"
            }
        }

       if (activeTab === "10") {
          // console.log("active tab 10");
          toolTipContent = `
          <p>
            <b style="font-size: 14px;">${feature.properties.name}</b>
          </p>
          ${appendData}
        `;
        } else {
            toolTipContent = `
              <p>
                <b>${feature.properties.name}</b>
              </p>
              <p>N/A</p>
            `;
          }
      }

      layer.bindPopup(toolTipContent, {
        maxWidth: "250",
        className: "custom",
      });
      
      // layer.bindTooltip(toolTipContent, {
      //   direction: "auto",
      //   sticky: true,
      //   permanent: false,
      // });

      layer.on({
        mouseover: (e) => {
          let thisLayer = e.target;
          thisLayer.setStyle({
            weight: 1.5,
            opacity: 1,
            color: "#fff",
            //dashArray: '',
            fillOpacity: 0.7,
          });
        },
        mouseout: (e) => {
          let thisLayer = e.target;

          thisLayer.setStyle({
            weight: 0.5,
            opacity: 1,
            color: "#fff",
            //dashArray: '',
            fillOpacity: 1,
          });
          //info.update();
        },
        popupopen: (e) => {
        },
        click: function (e) {
          filterNetwork(e.target.feature)
          setClickCountry(e.target.feature)
          activeAreas(e.target.feature);
          partnersGeoJsonStyle()
          setLegendChange(legendChange + 1);
          // setMapKey(mapKey + 1)
        },
        dblclick: function (e) {
          // console.log("double click")
        },
      });
    }
  };

  useEffect(() => {

  },[])

  const activeAreas = (country,isoCode = '') => {
    // filterMap(country,'')
    let getActors = document.querySelectorAll('.actorFilterList li:not(:first-child) button');
    // let allActors = getActors.shift(); 
    console.log("country",country, getActors, mapData);
    let arr = []
    if(isoCode && isoCode.length > 0) {
      mapData.forEach((data) => {
        if(data.iso3_code == isoCode) {
          console.log("Thematic",data);
          let thameticArr = data['Thematic Area Actor'];
          if(thameticArr && thameticArr.length > 0) {
            console.log("thameticArr",thameticArr )
            thameticArr.forEach((area) => {
              arr.push(area.Actor_name.toLowerCase().replaceAll(" ","") === 'humanresoursesinhealthcare(hrh)' ? 'hrh' : area.Actor_name.toLowerCase().replaceAll(" ",""));
            })
          }
        }
      })
    } else {
      mapData.forEach((data) => {
        if(data.iso3_code == country.properties.iso_a3) {
          console.log("Thematic",data);
          let thameticArr = data['Thematic Area Actor'];
          if(thameticArr && thameticArr.length > 0) {
            console.log("thameticArr",thameticArr )
            thameticArr.forEach((area) => {
              arr.push(area.Actor_name.toLowerCase().replaceAll(" ","") === 'humanresoursesinhealthcare(hrh)' ? 'hrh' : area.Actor_name.toLowerCase().replaceAll(" ",""));
            })
          }
        }
      })
    }
    
    
    for(let i=0; i<getActors.length; i++) {
      console.log(getActors[i].innerText.toLowerCase().replaceAll(" ",""))
      if(!arr.includes(getActors[i].innerText.toLowerCase().replaceAll(" ",""))) {
        console.log("getting output",arr ,getActors[i].innerText.toLowerCase().replaceAll(" ",""))
        legendDisabled[getActors[i].innerText.toLowerCase().replaceAll(" ","")] = true
        setLegendDisabled(legendDisabled)
      } else {
        legendDisabled[getActors[i].innerText.toLowerCase().replaceAll(" ","")] = false
        setLegendDisabled(legendDisabled)
      }
    }
    
    console.log("arr",arr, legendDisabled)
  }

  const filterPartnersMap = (value) => {
    setClickCountry({})
    if(value == "all") {
      setFilterActor('all')
      setThamaticData([]);
      setLegendDisabled({
        'hrh':false,
        'civilsociety':false,
        'internationalbodies':false,
        'plwncds':false,
        'privatesector':false,
        'government':false
      })
      // console.log("filter map",value,thematicData);
      partnersGeoJsonStyle();
      filterMap(mapData,value)
      return;
    }

    if(value == "HRH") {
      value = "Human resourses in healthcare  (HRH)";
    }
    let thematicData = [];
    mapData.filter(
      (country) => {
        if(country['Thematic Area Actor'] != null) {
          country['Thematic Area Actor'].forEach(element => {
            if(element.Actor_name.toLowerCase().replaceAll(" ","") == value.toLowerCase().replaceAll(" ","")) {
              thematicData.push(country)
            }
          });
        }
      }
    );

    setFilterActor(value)
    setThamaticData(thematicData);
    filterMap(thematicData,value)
    
    partnersGeoJsonStyle();
  }

  function PartnersLegendControl() {
    //const parentMap = useMap();

    // Memoize the legend so it's not affected by position changes
    const legend = useMemo(
      () => (
        <div className="maplegend" style={{ textAlign: 'center',padding: '15px' }}>
          <h6
            style={{
              fontFamily: "BrandonGrotesque-Regular",
              marginBottom: "5px",
              color: "#1a1a1a",
              fontWeight: "600",
            }}
          >
            LEGEND
          </h6>
          <div style={{ textAlign: 'center' }}>
            <ul style={{ listStyle: 'none' }} className="actorFilterList">
              <li style={{ margin: '11px 0' }}><button className={filterActor.replaceAll(" ","").toLowerCase() == 'all' ? 'activeAll' : ''} style={{
                width: "160px",
                border: "none",
                boxShadow: "2px 3px 3px 4px #ccc",
              fontFamily: "BrandonGrotesque-Regular",
              fontSize: "11px",
              padding: "4px",
              color: "#fff",
              background: "#3e3e3e"
            }} onClick={() => filterPartnersMap('all')}>All ACTORS</button></li>
              <li style={{ margin: '11px 0' }} className={legendDisabled['plwncds'] == true ? 'disable' : 'lol'}><button className={filterActor.replaceAll(" ","").toLowerCase() == 'plwncds' ? 'activeplwncds' : ''} style={{
                width: "160px",
                border: "none",
                boxShadow: "2px 3px 3px 4px #ccc",
              fontFamily: "BrandonGrotesque-Regular",
              fontSize: "11px",
              padding: "4px",
              color: "#fff",
              background: "#52b335"
            }} onClick={() => filterPartnersMap('PLWNCDs')}>PLWNCDs</button></li>
              <li style={{ margin: '11px 0' }} className={legendDisabled['government'] == true ? 'disable' : 'lol'}><button className={filterActor.replaceAll(" ","").toLowerCase() == 'government' ? 'government' : ''} style={{
                width: "160px",
                border: "none",
                boxShadow: "2px 3px 3px 4px #ccc",
              fontFamily: "BrandonGrotesque-Regular",
              fontSize: "11px",
              padding: "4px",
              color: "#fff",
              background: "#015c84"
            }} onClick={() => filterPartnersMap('GOVERNMENT')}>GOVERNMENT</button></li>
              <li style={{ margin: '11px 0' }} className={legendDisabled['hrh'] == true ? 'disable' : 'lol'}><button className={filterActor.replaceAll(" ","").toLowerCase() == 'humanresoursesinhealthcare(hrh)' ? 'activeHrh' : ''} style={{
                width: "160px",
                border: "none",
                boxShadow: "2px 3px 3px 4px #ccc",
              fontFamily: "BrandonGrotesque-Regular",
              fontSize: "11px",
              padding: "4px",
              color: "#fff",
              background: "#0b7451"
            }} onClick={() => filterPartnersMap('HRH')}>HRH</button></li>
              <li style={{ margin: '11px 0' }} className={legendDisabled['internationalbodies'] == true ? 'disable' : 'lol'}><button className={filterActor.replaceAll(" ","").toLowerCase() == 'internationalbodies' ? 'activeInterbodies' : ''} style={{
                width: "160px",
                border: "none",
                boxShadow: "2px 3px 3px 4px #ccc",
              fontFamily: "BrandonGrotesque-Regular",
              fontSize: "11px",
              padding: "4px",
              color: "#fff",
              background: "#930c62"
            }} onClick={() => filterPartnersMap('INTERNATIONAL BODIES')}>INTERNATIONAL BODIES</button></li>
              <li style={{ margin: '11px 0' }} className={legendDisabled['privatesector'] == true ? 'disable' : 'lol'}><button className={filterActor.replaceAll(" ","").toLowerCase() == 'privatesector' ? 'activePrivatesector' : ''} style={{
                width: "160px",
                border: "none",
                boxShadow: "2px 3px 3px 4px #ccc",
              fontFamily: "BrandonGrotesque-Regular",
              fontSize: "11px",
              padding: "4px",
              color: "#fff",
              background: "#c41111"
            }} onClick={() => filterPartnersMap('PRIVATE SECTOR')}>PRIVATE SECTOR</button></li>
              <li style={{ margin: '11px 0' }} className={legendDisabled['civilsociety'] == true ? 'disable' : 'lol'}><button className={filterActor.replaceAll(" ","").toLowerCase() == 'civilsociety' ? 'activeCivilsociety' : ''} style={{
                width: "160px",
                border: "none",
                boxShadow: "2px 3px 3px 4px #ccc",
              fontFamily: "BrandonGrotesque-Regular",
              fontSize: "11px",
              padding: "4px",
              color: "#fff",
              background: "#ffba18"
            }} onClick={() => filterPartnersMap('CIVIL SOCIETY')}>CIVIL SOCIETY</button></li>
            </ul>
          </div>
          <div className="legendMapList">
            <ul style={{ listStyle: 'none', paddingLeft: "25%", marginTop: '49px', color: '#000', fontFamily: "BrandonGrotesque-Regular" }}>
              <li style={{ display: 'flex', alignItems: 'center'  }}>
                <div style={{width: '12px', marginRight: '5px'}}><img style={{ width: '100%' }} src={imgUrl.pathImg} /></div>
                <span>PATH</span></li>
              <li style={{ display: 'flex', alignItems: 'center'  }}>
                <div style={{width: '12px', marginRight: '5px'}}><img style={{ width: '100%' }} src={imgUrl.ncdImg} /></div>
                <span>NCD Alliance</span></li>
              <li style={{ display: 'flex', alignItems: 'center'  }}>
                <div style={{width: '12px', marginRight: '5px'}}><img style={{ width: '100%' }} src={imgUrl.worldBankImg} /></div>
                <span>World Bank</span></li>
              <li style={{ display: 'flex', alignItems: 'center'  }}>
                <div style={{width: '12px', marginRight: '5px'}}><img style={{ width: '100%' }} src={imgUrl.whfImg} /></div>
                <span>WHF</span></li>
              <li style={{ display: 'flex', alignItems: 'center'  }}>
                <div style={{width: '12px', marginRight: '5px'}}><img style={{ width: '100%' }} src={imgUrl.ccanImg} /></div>
                <span>C/CAN</span></li>
            </ul>
          </div>
        </div>
      ),
      [legendChange]
    );

    return (
      <div className="leaflet-top leaflet-left" style={{bottom : "0",  background: 'rgba(204, 204, 204, 0.8)'}}>
        <div className="leaflet-control leaflet-bar" style={{margin : "0", border: "none"}}>{legend}</div>
      </div>
    );
  }

  /***************** Choropleth Map *****************/

  const getMapColor = (value) => {
    //console.log(value);

    let returnColor = "#E6E6E6";
    let lowScale = lowScales;
    let highScale = highScales;
    let colorScale = colorScales;

    if (value !== undefined && value !== -1 && value !== 0) {
      value = Number(value);
      if (value > highScale[highScale.length - 1]) {
        returnColor = colorScale[colorScale.length - 1];
      } else {
        for (var i = 0; i < lowScale.length; i++) {
          if (value >= lowScale[i] && value <= highScale[i]) {
            returnColor = colorScale[i];
            break;
          }
          if (value < lowScale[i]) {
            returnColor = colorScale[i];
            break;
          }
        }
      }
    }
    return returnColor;
  };

  const mapGeoJsonStyle = (feature) => {
    let index = "";

    if (mapLevel === 1) {
      index = mapData.findIndex(
        (country) => country.iso3_code === feature.properties.iso_a3
      );
    } else {
      index = drilldownMapData.findIndex(
        (province) =>
          province.catchment_province_code === feature.properties.iso_a3
      );
    }

    if (index !== -1) {
      let featureValue = "";
      if (mapLevel === 1) {
        featureValue =
          mapData[index]["total no of program"] !== null
            ? mapData[index]["total no of program"]
            : 0;
      } else {
        featureValue = drilldownMapData[index]["programs"].length;
      }
      //console.log(featureValue);

      return {
        fillColor: getMapColor(featureValue),
        weight: 0.5,
        opacity: 1,
        color: "#fff",
        //dashArray: "3",
        fillOpacity: 1,
      };
    } else {
      return {
        fillColor: getMapColor(0),
        weight: 0.5,
        opacity: 1,
        color: "#fff",
        //dashArray: "3",
        fillOpacity: 1,
      };
    }
  };

  const mapGeoJsonStyle1 = (feature) => {

    const index = mapData.findIndex(
      (country) => country.iso3_code === feature.properties.iso_a3
    );

    if (index !== -1) {
      let featureValue =
        mapData[index]["catchement_Population_served"] !== null
          ? mapData[index]["catchement_Population_served"]["CatchmentAreas"]
          : "N/A";
      //console.log(featureValue[0]["total_pop_served"]);
      let beneficiaryPopulation = featureValue[0]["total_pop_served"];

      return {
        fillColor:
          beneficiaryPopulation !== undefined && beneficiaryPopulation !== ""
            ? "#9FB8BD"
            : "#E6E6E6",
        weight: 0.5,
        opacity: 1,
        color: "#fff",
        //dashArray: "3",
        fillOpacity: 1,
      };
    } else {
      return {
        fillColor: "#E6E6E6",
        weight: 0.5,
        opacity: 1,
        color: "#fff",
        //dashArray: "3",
        fillOpacity: 1,
      };
    }
  };

  const onEachFeature = (feature, layer) => {

    if (feature.properties && feature.properties.name) {
      let toolTipContent = "";
      if (mapLevel === 1) {
        const countryData = mapData.filter(
          (country) => country.iso3_code === feature.properties.iso_a3
        );
        if (activeTab === "3") {
          // Member Companies
          let membersList = "";
          //let tempArray = [];
          if (countryData.length > 0 && countryData[0].AA_Members !== null) {
            //console.log(countryData[0].AA_Members);

            let tempMembers = countryData[0].AA_Members.sort((a, b) =>
              a.member_name.localeCompare(b.member_name)
            );
            membersList = tempMembers.map(function (element) {
              return `<p style="margin:0px;margin-bottom:5px;border-bottom: 1px solid #ddd;">${element["member_name"]}</p>`;
            });
            membersList = membersList.join("");

            toolTipContent = `
              <p style="margin:0px !important;font-size:20px;font-family: BrandonGrotesque-Regular;text-align:center;">
                ${feature.properties.name}
              </p>
              <p style="margin:0px !important;margin-top:5px;margin-bottom:5px;font-size:15px;font-family: BrandonGrotesque-Regular;text-align:center;">Members: ${countryData[0].AA_Members.length}</p>
              <div style="margin-top: 5px;
              margin-bottom: 5px;
              background-color: #fff;
              color: #707070;
              border-radius: 4px;
              padding: 5px;
              font-size: 16px;
              max-height:150px;overflow-y:auto;
              font-family: BrandonGrotesque-Regular;">${membersList}</div>`;

            if (mapLevel === 1 && countryData.length > 0) {
              toolTipContent += `<p style="margin:0px !important;margin-top:5px;margin-bottom:5px;text-align:center;"><button id="drilldownMapBtn" type="button" style="background: #f26432;
              appearance: none;
              box-shadow: none;
              border: none;
              padding: 5px;
              color: #fff;
              border-radius: 5px;
              font-family: BrandonGrotesque-Regular;
              font-size: 14px;"><i class="fa fa-arrow-down"></i> Drilldown</button></p>
            `;
            }
          } else {
            toolTipContent = `
              <p>
                <b>${feature.properties.name}</b>
              </p>
              <p><b>Members</b></p>
              N/A
            `;
          }
        } else if (activeTab === "1" || activeTab === "4") {
          // Member Programs or Addressed NCD
          let ncdCount = "";
          if (countryData.length > 0 && countryData[0].NCDs !== null) {
            ncdCount = countryData[0].NCDs.map(function (element) {
              return `<tr><td style="padding:5px;">${element["ncd_name"]}</td><td style="padding:5px;color: #e16d41;">${element["no of programs"]}</td></tr>`;
            });
          }

          const index = mapData.findIndex(
            (country) => country.iso3_code === feature.properties.iso_a3
          );

          let featureValue = 0;

          if (index !== -1) {
            featureValue =
              mapData[index]["total no of program"] !== null
                ? mapData[index]["total no of program"]
                : 0;
          }

          toolTipContent = `<p className="popup_title" style="margin:0px !important;font-size:20px;font-family: BrandonGrotesque-Regular;text-align:center;">${
            feature.properties.name
          }</p> 
          <p className="popup_subtitle" style="margin:0px !important;margin-top:5px;margin-bottom:5px;font-size:15px;font-family: BrandonGrotesque-Regular;text-align:center;">Number of programs: ${
            featureValue !== undefined ? Number(featureValue) : "N/A"
          }</p><Table bordered style="background-color:#fff;color:#707070;font-size:16px;font-family: BrandonGrotesque-Regular;margin-top:5px;margin-bottom:5px;border-radius:4px;width:100%;">${ncdCount
            .toString()
            .replace(/,/g, "")}</Table>`;

          if (mapLevel === 1 && countryData.length > 0) {
            toolTipContent += `
            <p style="margin:0px !important;margin-top:5px;margin-bottom:5px;text-align:center;"><button id="drilldownMapBtn" type="button" style="background: #f26432;
          appearance: none;
          box-shadow: none;
          border: none;
          padding: 5px;
          color: #fff;
          border-radius: 5px;
          font-family: BrandonGrotesque-Regular;
          font-size: 14px;"><i class="fa fa-arrow-down"></i> Drilldown</button></p>`;
          }
        } else if (activeTab === "10") {
          toolTipContent = `
              <p>
                <b>Bold text</b>
              </p>
              <p>N/A</p>
            `;
        } else {
          // Beneficiary Population, LMI Countries
          let programList = "";
          //let tempArray = [];
          if (countryData.length > 0 && countryData[0].programs !== null) {
            let tempPrograms = countryData[0].programs.sort((a, b) =>
              a.program_name.localeCompare(b.program_name)
            );
            programList = tempPrograms.map(function (element) {
              return `<p style="margin:0px;margin-bottom:5px;border-bottom: 1px solid #ddd;">
              <a style="color:#818181" href="https://aaopenplatform.accessaccelerated.org/program-profile/${element["program_name"].toLocaleLowerCase().replace(" & "," ").replace("&"," ").replace(" - "," ").replace("-"," ").replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, "").replaceAll(" ","-").replace("--","-")}" target="_blank">${element["program_name"]}</a>
              </p>`;
            });

            programList = programList.join("");

            toolTipContent = `
              <p style="margin:0px !important;font-size:20px;font-family: BrandonGrotesque-Regular;text-align:center;">
                ${feature.properties.name}
              </p>
              <p style="margin:0px !important;margin-top:5px;margin-bottom:5px;font-size:15px;font-family: BrandonGrotesque-Regular;text-align:center;">Programs: ${countryData[0].programs.length}</p>
              <div style="background-color:#fff;padding:10px;border-radius:4px;margin-top:5px;margin-bottom:5px;color: #707070;font-family: BrandonGrotesque-Regular;font-size:16px;max-height:150px;overflow-y:auto;">${programList}</div>`;

            if (mapLevel === 1 && countryData.length > 0) {
              toolTipContent += `
              <p style="margin:0px !important;margin-top:5px;margin-bottom:5px;text-align:center;">
              <button id="drilldownMapBtn" id="drilldownMapBtn" type="button" style="background: #f26432;
              appearance: none;
              box-shadow: none;
              border: none;
              padding: 5px;
              color: #fff;
              border-radius: 5px;
              font-family: BrandonGrotesque-Regular;
              font-size: 14px;"><i class="fa fa-arrow-down"></i> Drilldown</button></p>
            `;
            }
          } else {
            toolTipContent = `
              <p>
                <b>${feature.properties.name}</b>
              </p>
              <p>N/A</p>
            `;
          }
        }
      } else {
        toolTipContent = `
              <p>
                <b>abc</b>
              </p>
              <p>N/A</p>
            `;
      }

      layer.bindPopup(toolTipContent, {
        maxWidth: "250",
        className: "custom",
      });

      // layer.bindTooltip(toolTipContent, {
      //   direction: "auto",
      //   sticky: true,
      //   permanent: false,
      // });

      layer.on({
        mouseover: (e) => {
          let thisLayer = e.target;
          thisLayer.setStyle({
            weight: 1.5,
            opacity: 1,
            color: "#fff",
            //dashArray: '',
            fillOpacity: 0.7,
          });
        },
        mouseout: (e) => {
          let thisLayer = e.target;

          thisLayer.setStyle({
            weight: 0.5,
            opacity: 1,
            color: "#fff",
            //dashArray: '',
            fillOpacity: 1,
          });
          //info.update();
        },
        popupopen: (e) => {
          //console.log(e);
          if (document.getElementById("drilldownMapBtn") !== null) {
            document.getElementById("drilldownMapBtn").onclick = function () {
              mapDrillButton();
            };
          }

        },
        click: function (e) {
          mapDrilldown(e); // commented for Migrant TB
        },
        dblclick: function (e) {
          //console.log('dbl click');
        },
      });
    }
  };

  const onEachFeatureDrill = (feature, layer) => {

    if (feature.properties && feature.properties.NAME_2) {
      let toolTipContent = "";
      if (mapLevel === 2) {
        // Drilldown Map Tooltip

        let programList = "";
        if (drilldownMapData.length > 0) {
          const provinceData = drilldownMapData.filter(
            (province) =>
              province.catchment_province_code === feature.properties.iso_a3
          );

          if (provinceData.length > 0) {
            let tempPrograms = provinceData[0].programs.sort((a, b) =>
              a.program_name.localeCompare(b.program_name)
            );
            programList = tempPrograms.map(function (element) {
              return `<p style="margin:0px;margin-bottom:5px;border-bottom: 1px solid #ddd;">
              <a style="color:#818181" href="https://aaopenplatform.accessaccelerated.org/program-profile/${element["program_name"].toLocaleLowerCase().replace(" & "," ").replace("&"," ").replace(" - "," ").replace("-"," ").replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, "").replaceAll(" ","-").replace("--","-")}" target="_blank">
              ${element["program_name"]}
              </a>
              </p>`;
            });
            //console.log(programList);
            // for (let i in countryData[0].programs) {
            //   tempArray.push(countryData[0].programs[i].program_name);
            // }
            programList = programList.join("");
            //console.log(programList);

            toolTipContent = `
            <p style="margin:0px !important;font-size:20px;font-family: BrandonGrotesque-Regular;text-align:center;">
              ${feature.properties.NAME_2}
            </p>
            <p style="margin:0px !important;margin-top:5px;margin-bottom:5px;font-size:15px;font-family: BrandonGrotesque-Regular;text-align:center;">Programs: ${provinceData[0].programs.length}</p>
            <div style="background-color:#fff;padding:10px;border-radius:4px;margin-top:5px;margin-bottom:5px;color: #707070;font-family: BrandonGrotesque-Regular;font-size:16px;max-height:150px;overflow-y:auto;">${programList}</div>`;
          } else {
            toolTipContent = `
              <p>
                <b>${feature.properties.NAME_2}</b>
              </p>
              N/A
            `;
          }
        } else {
          toolTipContent = `
            <p>
              <b>${feature.properties.NAME_2}</b>
            </p>
            <p>N/A</p>
          `;
        }
        //console.log(toolTipContent);
      }

      //console.log(toolTipContent);
      layer.bindPopup(toolTipContent, {
        maxWidth: "250",
        className: "custom",
      });

      // layer.bindTooltip(toolTipContent, {
      //   direction: "auto",
      //   sticky: true,
      //   permanent: false,
      // });

      layer.on({
        mouseover: (e) => {
          let thisLayer = e.target;
          thisLayer.setStyle({
            weight: 1.5,
            opacity: 1,
            color: "#fff",
            //dashArray: '',
            fillOpacity: 0.7,
          });
        },
        mouseout: (e) => {
          let thisLayer = e.target;

          thisLayer.setStyle({
            weight: 0.5,
            opacity: 1,
            color: "#fff",
            //dashArray: '',
            fillOpacity: 1,
          });
          //info.update();
        },
        popupopen: (e) => {
          //console.log(e);
          if (document.getElementById("drilldownMapBtn") !== null) {
            document.getElementById("drilldownMapBtn").onclick = function () {
              mapDrillButton();
            };
          }

          //console.log(currentMapReference);
        },
        click: function (e) {
          //mapDrilldown(e);
        },
        dblclick: function (e) {
          //console.log('dbl click');
        },
      });
    }
  };

  const mapDrilldown = (e) => {
    //console.log("drill down map", e.target.feature.properties);

    if (mapLevel === 1) {
      let tempISO = e.target.feature.properties.iso_a3;
      let tempDrilledCountry = mapData.filter(
        (obj) => obj.iso3_code === tempISO
      );
      //console.log(tempDrilledCountry);
      localStorage.setItem(
        "drilledCountry",
        JSON.stringify(tempDrilledCountry)
      );
      //setDrilledCountry(tempDrilledCountry);
      setMapCenter([e.latlng.lat,e.latlng.lng]);
      setMapZoom(4)
      parentCall(e.target.feature.properties.iso_a3);
    }
  };

  const mapDrillButton = () => {
    let tempDrilledCountry = JSON.parse(localStorage.getItem("drilledCountry"));
    //console.log(tempDrilledCountry);

    if (tempDrilledCountry[0].catchement_Population_served !== null) {
      let tempISO = tempDrilledCountry[0].iso3_code;
      //console.log(tempISO);
      //get geojson

      fetch(
        `https://aaopenplatform.accessaccelerated.org/provinces/${tempISO}.geojson`
      )
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          // console.log(
          //   tempDrilledCountry[0].catchement_Population_served.CatchmentAreas[0]
          //     .Catchment_areas
          // );
          
          setMapLevel(mapLevel + 1);
          setDrilldownGeoJson(data);
          setDrilldownMapData(
            tempDrilledCountry[0].catchement_Population_served.CatchmentAreas[0]
              .Catchment_areas
          );
          // setMapZoom(4)
          setMapKey(mapKey + 1);
        })
        .catch((err) => {
          console.log("Shape File Error: ", err);
        });
    } else {
      console.log("Drilldown data not available!");

      let tempISO = tempDrilledCountry[0].iso3_code;

      fetch(
        `https://aaopenplatform.accessaccelerated.org/provinces/${tempISO}.geojson`
      )
        .then((response) => response.json())
        .then((data) => {
          
          setMapLevel(mapLevel + 1);
          setDrilldownGeoJson(data);
          setDrilldownMapData([]);
          // setMapZoom(4)
          setMapKey(mapKey + 1);
        })
        .catch((err) => {
          console.log("Shape File Error: ", err);
        });
    }
  };

  const resetMap = (e) => {
    e.preventDefault();
    // resetFilter();
    //setMapZoom(mapDefaultZoom);
    //setCurrentMapKey(currentMapKey + 1);
    setLegendDisabled({
      'hrh':false,
      'civilsociety':false,
      'internationalbodies':false,
      'plwncds':false,
      'privatesector':false,
      'government':false
    })
    setMapZoom(2)
    setDrilldownGeoJson([]);
    setMapLevel(1);
    setMapCenter([0,0])
    if(mapView === "partners") {
      filterPartnersMap('all');
    }
    setMapKey(mapKey + 1);
  };

  function MapReferenceGeoJson() {
    const map = useMap();

    setCurrentMapReference(map);

    // var layerFeatureJSON = "";
    // if (mapLevel === 1) {
    //   layerFeatureJSON = L.geoJson(geoJsonData);
    // } else {
    //   layerFeatureJSON = L.geoJson(drilldownGeoJson);
    // }

    // var mapCentroid = layerFeatureJSON.getBounds();
    // map.fitBounds(mapCentroid);
    // setMapKey(mapKey + 1);
    return null;
  }

  function LegendControl() {
    //const parentMap = useMap();

    // Memoize the legend so it's not affected by position changes
    const legend = useMemo(
      () => (
        <div className="maplegend">
          <h6
            style={{
              fontFamily: "BrandonGrotesque-Regular",
              marginBottom: "5px",
              color: "#1a1a1a",
              width: "250px",
            }}
          >
            Number of programs
          </h6>
          <Row noGutters className="map_legend_gradholder">
            <Col sm="2">1</Col>
            <Col sm="8" className="map_legendbg"></Col>
            <Col sm="2">40</Col>
          </Row>
          <ListGroup horizontal className="map_legend_listgroup">
            {highScales.length > 0
              ? colorScales.map((colorScale, index) => {
                  return (
                    <ListGroupItem key={index}>
                      <i
                        className="fa fa-circle fs-12px mr-2"
                        style={{ color: colorScale }}
                      ></i>
                      {`${lowScales[index]} ${
                        highScales[index] === undefined
                          ? "& Above"
                          : ` - ` + highScales[index]
                      }`}
                    </ListGroupItem>
                  );
                })
              : ""}
            <ListGroupItem>
              <i
                className="fa fa-circle fs-12px mr-2"
                style={{ color: "#E6E6E6" }}
              ></i>
              N/A
            </ListGroupItem>

            {/* <ListGroup.Item>
              <i class="fa fa-circle fs-12px mr-2"></i>Medium
            </ListGroup.Item>
            <ListGroup.Item>
              <i class="fa fa-circle fs-12px mr-2"></i>High
            </ListGroup.Item>
            <ListGroup.Item>
              <i class="fa fa-circle fs-12px mr-2"></i>Very High
            </ListGroup.Item> */}
          </ListGroup>
        </div>
      ),
      []
    );

    return (
      <div className="leaflet-bottom leaflet-right">
        <div className="leaflet-control leaflet-bar">{legend}</div>
      </div>
    );
  }

  function LegendControl1() {
    // Memoize the legend so it's not affected by position changes
    const legend = useMemo(
      () => (
        <div className="maplegend benpop_maplegend">
          <ListGroup vertical="true">
            <ListGroupItem>
              <i
                className="fa fa-square fs-14px mr-2"
                style={{ color: "#9FB8BD" }}
              ></i>
              Data Available
            </ListGroupItem>
            <ListGroupItem>
              <i
                className="fa fa-square fs-14px mr-2"
                style={{ color: "#E6E6E6" }}
              ></i>
              No Data
            </ListGroupItem>
          </ListGroup>
        </div>
      ),
      []
    );

    return (
      <div className="leaflet-bottom leaflet-right">
        <div className="leaflet-control leaflet-bar">{legend}</div>
      </div>
    );
  }

  return (
    <>
      {/* Render Indicator data */}
      {mapData && geoJsonData ? (
        <MapContainer
          //ref={mapRef}
          key={mapKey}
          style={{ height: "500px", backgroundColor: "#fff" }}
          center={mapCenter}
          zoom={mapView === "partners" && mapZoom}
          zoomControl={false}
          minZoom={mapView === "partners" ? '2' :mapZoom}
          maxZoom={9}
          bounds={L.geoJson(
            mapLevel === 1 ? geoJsonData : drilldownGeoJson
          ).getBounds()}
          scrollWheelZoom={false}
        >
          <TileLayer
            //attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
            //url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
            //url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png" // Dark
            //url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png" // Light
            //url=""
            url={
              mapLevel === 1
                ? ""
                : "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                
            }
          />
          { mapView === "partners" ? (
            <>
              <GeoJSON
                key={mapKey}
                // zoom={mapZoom}
                data={mapLevel === 1 ? geoJsonData : drilldownGeoJson}
                style={partnersGeoJsonStyle}
                onEachFeature={
                  (screenWidth && screenWidth.width < 1024) ? partnersOnEachFeatureMob : partnersOnEachFeature 
                }
              ></GeoJSON>
              {/* <ZoomControl position="topright" /> */}
              <span
                  className="refreshMap partners"
                  title="Reset Filters"
                  onClick={(e) => resetMap(e)}
                >
                  <i className="fas fa-redo-alt"></i>
                </span>
              <PartnersLegendControl />
            </>
          ) : mapView === "choropleth" ? (
            <>
              <GeoJSON
                key={mapKey}
                data={mapLevel === 1 ? geoJsonData : drilldownGeoJson}
                style={mapGeoJsonStyle}
                onEachFeature={
                  mapLevel === 1 ? onEachFeature : onEachFeatureDrill
                }
              ></GeoJSON>
              {/* <FeatureGroup>
                {geoJsonData.features.map((feature, index) => {
                  return (
                    <Circle
                      key={index}
                      pathOptions={mapHotspotColor(feature)}
                      center={mapHotspotCenter(feature)}
                      radius={mapHotspotRadiusPartner(feature)}
                    >
                      <Popup style={{ maxWidth: "200px" }}>
                        <p>{mapHotspotLocation(feature)}</p>
                        
                      </Popup>
                    </Circle>
                  );
                })}
              </FeatureGroup> */}
              <ZoomControl position="topleft" />
              <LegendControl />
            </>
          ) : (
            <>
              <GeoJSON
                key={mapKey}
                data={geoJsonData}
                style={mapGeoJsonStyle1}
                //onEachFeature={onEachFeature1}
              ></GeoJSON>
              <FeatureGroup>
                {geoJsonData.features.map((feature, index) => {
                  return (
                    <Circle
                      key={index}
                      pathOptions={mapHotspotColor(feature)}
                      center={mapHotspotCenter(feature)}
                      radius={mapHotspotRadius(feature)}
                      //radius={500000}
                    >
                      {/* <Popup>{mapHotspotPopup(feature)}</Popup> */}
                      {/* <Tooltip>{mapHotspotPopup(feature)}</Tooltip> */}
                      <Popup style={{ maxWidth: "200px" }}>
                        <p>{mapHotspotLocation(feature)}</p>
                        <p>
                          Beneficiary Population:{" "}
                          <div style={{ backgroundColor: "#ffffff" }}>
                            <span style={{ color: "#930D62" }}>
                              <b>{mapHotspotContent(feature)}</b>
                            </span>
                          </div>
                        </p>
                        <p>
                          Catchment Areas:{" "}
                          <div style={{ backgroundColor: "#ffffff" }}>
                            <span style={{ color: "#930D62" }}>
                              <b>{mapAreasCount(feature)} Subnational areas</b>
                            </span>
                          </div>
                        </p>
                        <p
                          style={{
                            maxWidth: "200px",
                            fontSize: "13px",
                            color: "#1a1a1a",
                            backgroundColor: "#ffffff",
                            marginTop: "-5px",
                          }}
                        >
                          <div
                            style={{
                              maxHeight: "200px",
                              whiteSpace: "normal",
                              maxHeight: "150px",
                              overflowY: "auto",
                            }}
                          >
                            {mapAreasList(feature)}
                          </div>
                        </p>
                      </Popup>
                    </Circle>
                  );
                })}
              </FeatureGroup>
              <ZoomControl position="topleft" />
              <LegendControl1 />
            </>
          )}

          {/* <div className="leaflet-top leaflet-right">
            <div className="leaflet-control leaflet-bar">
              <div className="resetButton" onClick={(e) => resetMap(e)}>
                Reset Map
              </div>
            </div>
          </div> */}
          {Object.keys(drilldownGeoJson).length > 0 ? 
          <span
            className="drilldownMap"
            title="Map Default Level"
            onClick={(e) => resetMap(e)}
          >
            <i className="fas fa-level-up-alt"></i>
          </span>
          : ''}
          <MapReferenceGeoJson />
        </MapContainer>
      ) : (
        ""
      )}
    </>
  );
};

export default LeafletMap;
