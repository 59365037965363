import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

import imgUrl from "../../assets/images/imgUrl.js";

function NcdDetail({ parentCallback, parentProgram, ncdArr }) {
	const [totalBeneficiary, setTotalBeneficiary] = useState(""); // Catchement Population Served Data
	let getNcd = ncdArr && ncdArr.length > 0 ? ncdArr : [];

	const history = useHistory();

  const routeChange = () =>{ 
    let path = `/ncds`; 
    history.push(path);
  }

	const settingssmall = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: false,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: false,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	const settingsvertical = {
		dots: false,
		infinite: false,
		speed: 500,
		vertical: true,
		verticalSwiping: true,
		slidesToShow: 6,
		slidesToScroll: 6,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: false,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	const NcdClassName = (ncdName) => {
		if (ncdName == "Cancer") {
			return "cancer_card";
		} else if (ncdName == "Cardiovascular Disease") {
			return "cvd_card";
		} else if (ncdName == "Diabetes") {
			return "diabetes_card";
		} else if (ncdName == "Respiratory Diseases") {
			return "resp_card";
		} else if (ncdName == "Other") {
			return "other_card";
		}

	}

	const BeneficiaryPop = (getNCD) => {
		if (getNCD && getNCD.length > 0) {
			if (getNCD[0]["benificiary population"] !== null) {
				let num =
					getNCD[0]["benificiary population"];
				const TotalVal = (num) => {
					if (num > 999 && num < 1000000) {
						return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
					} else if (num > 1000000) {
						return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
					} else if (num < 900) {
						return num; // if value < 1000, nothing to do
					}
				};

				setTotalBeneficiary(TotalVal(num)); //Pasing Total beneficiary State
			} else {
				setTotalBeneficiary("NA");
			}
		}
	};

	const toggleClose = () => {
		parentCallback(false);
	}

	const openProgram = (id) => {
		parentProgram(id);
	}

	useEffect(() => {
		BeneficiaryPop(getNcd);
	}, [ncdArr])

	return (
		<>
			{getNcd && getNcd.length > 0 ?
				<Row>
					<Col sm="12">
						<div className="member_profile_card_open">
							<div className="member_profile_card_closebtn" onClick={() => toggleClose()} style={{ cursor: 'pointer' }}>
								<img
									className="stoptblogoimg"
									alt="stopTB"
									src={imgUrl.close_btn}
								/>
							</div>
							<div className={`add_ncd_profile_open_image ${NcdClassName(getNcd[0].ncd_name)}`}>

								<Row>
									<Col sm="2" className="left-gradient"></Col>
									<Col sm="8">
										<p className="ncd_title">{getNcd[0].ncd_name}</p>
									</Col>
									<Col sm="2" className="right-gradient"></Col>
								</Row>
							</div>
							<div className="white_border_holder"></div>
							<div className="member_profile_card_open_description mt-5">
								<Row>
									<Col sm="12">
										<p className="member_company_title_name">{getNcd[0].ncd_name}</p>
									</Col>
								</Row>
								<Row>
									<Col sm="4">
										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_programs}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
													<p className="member_profile_card_open_cat_title">
														{
															getNcd[0]["total no of program"] ? getNcd[0]["total no of program"] > 1 ? getNcd[0]["total no of program"] + " Programs" : getNcd[0]["total no of program"] + " Program" : "Program NA"}
													</p>
												</Col>
											</Row>
										</div>
										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_ad}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
													<p className="member_profile_card_open_cat_title"><a onClick={() => routeChange()} style={{cursor: "pointer"}}>Open NCD Profile</a></p>
												</Col>
											</Row>
										</div>
									</Col>
									<Col sm="4">
										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_benpop}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2 d-flex align-items-center">
													<p className="member_profile_card_open_cat_title">{totalBeneficiary} Beneficiary Population</p>
												</Col>
											</Row>
										</div>
										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_ncd}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2  country_scroll_container">
													<p className="member_profile_card_open_cat_title">
														{getNcd[0]["sub types"] &&
															getNcd[0]["sub types"].length > 0 ?
															getNcd[0]["sub types"].map((memberName, index) => (
																<>
																	{getNcd[0]["sub types"].length - 1 == index
																		? " " + memberName
																		: " " + memberName + ","}
																</>
															)) : 'NA'
														}
													</p>
												</Col>
											</Row>
										</div>


									</Col>
									<Col sm="4">


										<div className="mb-4">
											<Row noGutters>
												<Col sm="2" className="text-center">
													<img
														className="stoptblogoimg"
														alt="stopTB"
														src={imgUrl.icon_countries}
													/>
												</Col>
												<Col sm="10" className="pl-2 pr-2 country_scroll_container_big">
													<p className="member_profile_card_open_cat_title">
													{getNcd[0].countries &&
															getNcd[0].countries.length > 0 ?
															getNcd[0].countries.map((countryName, index) => (
																<>
																	{getNcd[0].countries.length - 1 == index
																		? `${countryName.country}`
																		: ` ${countryName.country},`}
																</>
															)) : 'NA'
														}
													</p>
												</Col>
											</Row>
										</div>


									</Col>
								</Row>

							</div>
							<div className="white_border_holder mt-4"></div>

						</div>
						<Slider {...settingssmall} className="small_slider">
						{getNcd[0].programs.map((prog) => (
							<div id={prog.program_id} onClick={() => openProgram(prog.program_id)}>
								<div className="member_profile_card_image" style={{
									backgroundImage: `url(${prog.program_logo ? prog.program_logo : ''})`,
									backgroundPosition: "center",
									backgroundRepeat: "no-repeat",
									backgroundSize: "cover"}}>
									{prog.AO && prog.AO == "Y" ? (<div className="member_profile_ooimage">
										<img
											className="stoptblogoimg"
											alt="stopTB"
											src={imgUrl.ao_circle}
										/></div>) : '' }
									<div className="member_profile_description">
										<div className="member_profile_title">
											<p>{prog.program_name}</p>
										</div>
										<div className="member_profile_company_name">
											<p>
											{prog.Members &&
															prog.Members.length > 0 &&
															prog.Members.map((memberName, index) => (
																<>
																	{prog.Members.length - 1 == index
																		? memberName.member_name
																		: " " + memberName.member_name + ","}
																</>
															))
														}
											</p>
										</div>
									</div>
								</div>
							</div>
						))}
						</Slider>
					</Col>
				</Row>
				: ''}
		</>
	);
}

export default NcdDetail;