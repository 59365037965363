import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import imgUrl from "../assets/images/imgUrl.js";

function FaqsPage() {
  return (
    <>
      <Helmet>
        <title>FAQs - Access Accelerated</title>
        <meta
          name="description"
          content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
        />
        {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
      </Helmet>
      <div className="mastheader-holder faqsPage masterWrap">
        <Container>
          <Row>
            <Col lg="2"></Col>
            <Col lg="8">
              <div className="heading">
                <h1 className="faqs-title">
                  <span className="orangeword">F</span>requently{" "}
                  <span className="orangeword">A</span>sked{" "}
                  <span className="orangeword">Q</span>uestions
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="faqs-quest-section pb-5">
        <Container>
          <Row>
            <Col lg="2" md="2" sm="2"></Col>
            <Col lg="10" md="10" sm="10">
              <div className="faqs-quest-section-content mt-5">
                <h5 className="partial-underline top-heading-text">
                  GENERAL INFORMATION
                </h5>
                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What is the Access Accelerated Open Platform?
                  </h3>
                  <p className="ans-text">
                    The Access Accelerated Open Platform is a knowledge resource and dashboard that provides a centralized information repository of action on noncommunicable diseases (NCDs). It is designed to inform users about NCD programs, catalyze biopharmaceutical industry NCD action in low- and middle-income countries (LMICs) and guide member companies and partners to adapt their work amid the COVID-19 pandemic. It was launched in 2018. While the platform no longer receives updates or new data, it continues to serve as a valuable resource, providing users access to all information and data gathered up until 2021. 
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    Who is the Open Platform for? 
                  </h3>
                  <p className="ans-text">
                  Anyone interested in learning about global efforts to reduce the NCD burden by the biopharmaceutical industry, government bodies, NGOs, civil society, and people living with NCDs.
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What will you find on the Open Platform?
                  </h3>
                  <p className="ans-text">
                  The Open Platform provides visualizations and analysis of NCD-program information, peer-reviewed data and unique insights into the biopharmaceutical industry’s NCD programs between 2018 and 2021.
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    How does the Open Platform benefit users?
                  </h3>
                  <p className="ans-text">
                    The Open Platform allows users to compare past program initiatives and identify gaps, synergies and opportunities for action. With easy-to-understand interactive visualizations, the Open Platform supports capacity building, communications, advocacy, monitoring and evaluation. 
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What are some of the features of the Open Platform?
                  </h3>
                  <p className="ans-text">
                  Users can efficiently search, retrieve, and report data on global NCD action from the years 2018 to 2021. Additionally, through the COVID-19 Portal, users can get insights on how to adapt programs to meet the needs of people living with NCDs.
                  Highlights include: 
                    <ul>
                      <li>
                        <span className="ans-text-list">
                          Easy to use download functions 
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                          Powerful information search and advanced filters
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                        Interactive charts that dynamically change the dashboard with a single-click
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                        Automated data processing and management in the Centralized Information Repository
                        </span>
                      </li>
                      <li>
                        <span className="ans-text-list">
                        Key technology upgrades enabling enhanced analytical capabilities, a focus on measurable indicators at different geographical levels and to provide customizable analytical portals
                        </span>
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                    What is the COVID-19 portal? 
                  </h3>
                  <p className="ans-text">
                  In 2020, COVID-19 disrupted the status quo, impacting lives and economies alike. The <a className="ans-text" href="/covid-19-portal">COVID-19 Portal</a> presents several analytical tools for providing strategic information to members and partners to facilitate the adaptation of their work to address the NCD-burden of LMICs considering the COVID-19 pandemic.  <i>Please note that the COVID-19 portal is no longer receiving updates or new data. The data presented was last updated in 2021.</i>
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                  What are the sources of the Open Platform? 
                  </h3>
                  <p className="ans-text">The Open Platform used two main sources between the period 2018 and 2021 which worked with together with Access Accelerated:</p>
                  <ul>
                    <li>
                      <span className="ans-text-list">
                      The <a className="ans-text" target="_blank" href="https://globalhealthprogress.org/">Global Health Progress (GHP)</a> provides self-published information from the individual companies of the biopharmaceutical industry. This data is directly updated on the GHP, which is not third-party verified.
                      </span>
                    </li>
                    <li>
                      <span className="ans-text-list">
                      <a className="ans-text" target="_blank" href="https://globalhealthprogress.org/">Access Observatory (AO)</a> provides standardized information and data that has been collected and reviewed throughout each ongoing year by an independent partner (Boston University, 2017-2021)
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                  How often is data collected and updated on the platform?  
                  </h3>
                  <p className="ans-text">
                  The Access Accelerated Open Platform is an archival resource, with the last update made in 2021.
                  </p>
                </div>

                <div className="quest-ans-div mb-5 pl-5">
                  <h3 className="quest-text">
                  Can the data from the platform be downloaded?
                  </h3>
                  <p className="ans-text">
                  Yes, the data of every chart can be exported and used via the options icon (top right corner of each chart). All profiles (member, NCDs, country) will be available to export as a PDF. An option to share the visualized information via social media will be added.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FaqsPage;
