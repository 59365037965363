import React, { useState, useEffect, useCallback } from "react";

import { Collapse, Button, CardBody, Card, Row, Col } from "reactstrap";
import classnames from "classnames";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

import imgUrl from "../../assets/images/imgUrl.js";
import MemberDetail from "./MemberDetail";

function MemberSlider({memberList,memberID,closeCountryDetail,callParentFun ,isCollapsed}) {
  let memberListArr = memberList;
  let getmemberID = memberID ? memberID : "";
  let CloseDetail = closeCountryDetail;
  const memberData = memberListArr && memberListArr.length > 0 ? memberListArr : [];
  const filterMemberData = memberData.filter(
    (member) =>
      member["total no of program"] && member["total no of program"] > 0
  );
  const [collapse, setIsCollapse] = useState(false);
  const [member, setMember] = useState([]);

  const toggle = (id) => {
    let getMember = memberData.filter((e) => {
      return e.member_id === id;
    });

    isCollapsed(true);

    setMember(getMember);
    setIsCollapse(true);
  };

  const fullForm = (title) => {
    if(title == "JnJ") {
      return "Johnson & Johnson";
    } else if(title == "BMS") {
      return "Bristol-Myers Squibb";
    } else {
			return title;
		}
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsvertical = {
		dots: false,
		infinite: false,
		speed: 500,
		vertical: true,
		verticalSwiping: true,
		slidesToShow: 6,
		slidesToScroll: 6,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: false,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

  // Callback to get data from its child component(ProgramDetail)
  const callback = useCallback((value) => {
    setIsCollapse(value);
    isCollapsed(value);
  }, []);

  const programCallback = useCallback((value) => {
    callParentFun(value);
  }, []);

  useEffect(() => {
    if(getmemberID != "") {toggle(getmemberID);}
  }, [getmemberID]);

  useEffect(() => {
    setIsCollapse(false);
    isCollapsed(false);
  }, [CloseDetail])

  return (
    <>
      <Collapse isOpen={collapse}>
        <Row>
          <Col sm="9">
            <MemberDetail
              parentCallback={callback}
              parentProgram={programCallback}
              memberArr={member && member.length > 0 ? member : []}
            />
          </Col>
          <Col sm="3">
            <div className="slider_vertical_holder">
              <Slider {...settingsvertical} className="slider_vertical">
              {filterMemberData &&
              filterMemberData.length > 0 &&
              filterMemberData.map((sliderData) => (
                <div
                  key={sliderData.member_id}
                  id={sliderData.member_id}
                  onClick={() => toggle(sliderData.member_id)}
                >
                  <div className="member_companies_card" style={{ cursor: "pointer" }}>
                    <img
                      className="stoptblogoimg"
                      alt="stopTB"
                      src={
                        sliderData.member_logo
                          ? sliderData.member_logo.replace(
                              "Dark_Member_logos",
                              "light_member_logos"
                            )
                          : ""
                      }
                    />
                    <div className="blue_border_holder"></div>
                  </div>
                </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Collapse>
      <Collapse isOpen={!collapse}>
        <Row>
          <Col sm="12">
            <Slider {...settings}>
              {filterMemberData &&
                filterMemberData.length > 0 &&
                filterMemberData.map((sliderData) => (
                  <div
                    key={sliderData.member_id}
                    id={sliderData.member_id}
                    onClick={() => toggle(sliderData.member_id)}
                  >
                    <div className="member_companies_card" style={{ cursor: "pointer" }}>
                      <img
                        className="stoptblogoimg"
                        alt="stopTB"
                        src={
                          sliderData.member_logo
                            ? sliderData.member_logo.replace(
                                "Dark_Member_logos",
                                "light_member_logos"
                              )
                            : ""
                        }
                      />
                      <div className="member_companies_card_description">
                        <p className="title">{fullForm(sliderData.member_name)}</p>
                        <p className="subtitle">
                          Programs:{" "}
                          {sliderData["total no of program"]
                            ? sliderData["total no of program"]
                            : "NA"}
                        </p>
                        <p className="subtitle">
                          Member since:{" "}
                          {sliderData["member since"]
                            ? sliderData["member since"]
                            : "NA"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </Col>
        </Row>
      </Collapse>
    </>
  );
}

export default MemberSlider;
