import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button,} from "reactstrap";
import imgUrl from "../assets/images/imgUrl.js";
import Pdf from "../pdf_file/AccessAcceleratedAnnualReport2021_June.pdf";
import { Helmet } from "react-helmet";
import { TabContent, TabPane, Nav, NavItem, NavLink,} from 'reactstrap';
import classnames from 'classnames';
import PartnerModal from "../components/ModalExample";

const allFramework = [
  {
    "parent_id": "1",
    "framework": "Patient Centric",
    "description": "This domain captures the ways in which Access Accelerated partner programs directly affect the lives of beneficiaries and provide individuals with essential services for NCD prevention, treatment and care. This domain emphasizes the needs of patients and health care providers, revealing information about local and global priorities to advance NCD action.",
    "type": [
      {
        "child_id": "4",
        "name": "Patient-Level Impact for AA Partners",
        "value": "These indicators record the number of individuals that are benefitting from direct NCD service provision through AA partner programs."
      },
      {
        "child_id": "5",
        "name": "Catchment Area for better access to NCD prevention, treatment, or care",
        "value": "Catchment area data complements patient-level metrics by illustrating reach into local communities and the potential for program growth."
      },
      {
        "child_id": "6",
        "name": "Engagement with country stakeholder",
        "value": "These indicators measure capacity building and other engagement with local stakeholders."
      }
    ]
  },
  {
    "parent_id": "2",
    "framework": "Programs",
    "description": "This domain captures program growth, catalyzed funding, and progress toward completion of workplans.This domain helps to identify ways to accelerate public and private investment in NCD prevention, treatment, and control.",
    "type": [
      {
        "child_id": "7",
        "name": "Monitoring of Log Frame Deliverables",
        "value": "These indicators show whether projects are delayed, on track, or completed."
      },
      {
        "child_id": "8",
        "name": "Country Presence and Geographic Expansion",
        "value": "These indicators measure the geographic scope of Access Accelerated partner projects and indicate contributions to the advancement of local health policy priorities."
      },
      {
        "child_id": "9",
        "name": "Contributions of Funding from Member Companies",
        "value": "These indicators show cross-sector collaboration between and partner organizations and the biopharmaceutical industry members of Access Accelerated."
      }
    ]
  },
  {
    "parent_id": "3",
    "framework": "Partnerships",
    "description": "This domain captures collaboration between Access Accelerated partners and other stakeholders in government, civil society, human resources for health, and the private sector. Data on partnerships document the impact of Access Accelerated and set the standard for the impact that can be achieved through public– private partnerships.",
    "type": [
      {
        "child_id": "10",
        "name": "Additional Investments in NCD programs leveraged by AA",
        "value": "This indicator represents external stakeholders investing in Access Accelerated partner projects."
      },
      {
        "child_id": "11",
        "name": "Partnerships",
        "value": "These indicators show the number of new and developing partnerships by sector, and provide qualitative data illustrating the way in which those partnerships contribute to accessible NCD services."
      },
      {
        "child_id": "12",
        "name": "External Outreach and Engagement",
        "value": "These indicators measure the thought leadership of Access Accelerated partners and their contributions to the global discussion of NCD policy."
      }
    ]
  }
]

function PartnerModule() {

  const [frameDescription, setFrameDescription] = useState("Select a M&E area above to read about it in more detail")
  const [activeClass,setActiveClass] = useState("")
  const [activeDescClass,setActiveDescClass] = useState("")
  const [changeIndex, setChangeIndex] = useState(0)
  /*m&eFrameworkTabs*/

  const [initialBox, setInitialBox] = useState(true);

  const activeFramework = (parentId,childId) => {
    console.log("parent child ID", parentId ,childId);
    if(parentId) {
      allFramework.forEach((obj) => {
        if(obj.parent_id == parentId) {
          obj.type.forEach((val) => {
            if(val.child_id == childId) {
              setFrameDescription(val.value);
              setInitialBox(false);
              setChangeIndex(changeIndex + 1);
              removeColorClasses()

              let parent = document.querySelectorAll('.portallogomaindiv');
              parent.forEach((getEle) => {
                if(getEle.classList.contains("active")) {
                  getEle.classList.remove("active")
                }
              })

              let ele = document.getElementById(childId);
              if(parentId == "1") {
                setActiveDescClass("bigBoxOrange-framework3")
                ele.classList.remove("partnerDivContainer")
                ele.classList.add("box-OrangeFramework")
              } else if(parentId == "2") {
                setActiveDescClass("bigBoxGreen-framework4")
                ele.classList.remove("partnerDivContainer")
                ele.classList.add("box-GreenFramework1")
              } else {
                setActiveDescClass("bigBoxBlue-framework2")
                ele.classList.remove("partnerDivContainer")
                ele.classList.add("box-BlueFramework2")
              }
              return;
            }
          })
        }
      })
    }
  }

  const activeCardDetails = (parentId,desc) => {
    console.log(parentId,desc);
    setFrameDescription(desc);
    setInitialBox(false);
    setChangeIndex(changeIndex + 1);
    removeColorClasses();
    let ele = document.querySelectorAll('.portallogomaindiv');
    ele.forEach((getEle) => {
      if(getEle.classList.contains("active")) {
        getEle.classList.remove("active")
      }
    })
    if(parentId == "1") {
      let parentDiv = document.querySelector('.frameWork-1');
      parentDiv.classList.add("active");
      setActiveDescClass("bigBoxOrange-framework3")
    } else if(parentId == "2") {
      let parentDiv = document.querySelector('.frameWork-2');
      parentDiv.classList.add("active");
      setActiveDescClass("bigBoxGreen-framework4")
    } else {
      let parentDiv = document.querySelector('.frameWork-3');
      parentDiv.classList.add("active");
      setActiveDescClass("bigBoxBlue-framework2")
    }
  }

  const removeColorClasses = () => {
    let divArr = document.getElementsByClassName('frameworkDiv');

    for(let i=0; i<divArr.length; i++) {
      if(divArr[i].classList.contains('box-OrangeFramework')) {
        divArr[i].classList.remove("box-OrangeFramework")
        divArr[i].classList.add("partnerDivContainer")
      } else if(divArr[i].classList.contains('box-GreenFramework1')) {
        divArr[i].classList.remove("box-GreenFramework1")
        divArr[i].classList.add("partnerDivContainer")
      } else if(divArr[i].classList.contains('box-BlueFramework2')) {
        divArr[i].classList.remove("box-BlueFramework2")
        divArr[i].classList.add("partnerDivContainer")
      } else {
        console.log("not found")
      }
    }
  }

  useEffect(() => {
  },[changeIndex])
  
  /*Tab*/
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  
  return (
    <>
      <Helmet>
        <title>Partner Module - Access Accelerated</title>
        <meta
          name="description"
          content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
        />
        {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
      </Helmet>
      <div className="mastheader-holder masterWrap">
        <Container>
          <Row>
            <Col lg="6">
              <div className="heading">
                <h1 className="partial-underline" style={{ letterSpacing:"3.15px" , fontSize:"3.5rem"}}>OUR PARTNERS AND M&amp;E FRAMEWORK</h1>
              </div>
              <div className="mast-description">
                <p>
                Access Accelerated drives sustainable solutions at scale for NCDs. 
                Together with our members and five implementing partners, we follow the universal call 
                for greater collective action in pursuit of the UN SDGs. The work has been captured by RTI in an M&amp;E framework to show our collective impact.
                </p>
                <div style={{width:'96px', marginTop: "2rem"}}>
                  <img
                  className="img-fluid"
                  alt=""
                  src={imgUrl.RTI_whiteLogo}
                  style={{ marginLeft: "4px" , marginTop:"-3px" }}/> </div>
            <div className="modalPartner">
              <PartnerModal></PartnerModal>
              </div>
               
              </div>
            </Col>
            <Col lg="6" className="partnersLogoSection">
                <Row className="mt-5 partnersLogoContainer">
                  <Col md={4} xs={4} sm={4} lg={4}>
                    <div>
                          <img
                          className="img-fluid"
                          alt=""
                          src={imgUrl.ccan_logo}
                          style={{ marginTop:"-7%" , maxWidth:"129%", marginLeft: '-9px'}}
                        />
                        <a href="https://citycancerchallenge.org/" target="_blank" style={{textDecoration:"none"}}>
                          <p className="partnerLogoHeader" style={{paddingLeft:"10%"}}>Visit HomePage
                          <span>
                          <img
                          className="img-fluid"
                          alt=""
                          src={imgUrl.right_orangearrow}
                          style={{ marginLeft: "4px" , marginTop:"-3px" , cursor:"pointer" }}
                        /> </span>
                          </p>
                        </a> 
                    </div>
                  </Col>
                  <Col md={4} xs={4} sm={4} lg={4}>
                    <div>
                      <img
                            className="img-fluid"
                            alt=""
                            src={imgUrl.ncd_logo}
                            // style={{ width: "10px", marginRight: "4px" , marginTop:"-5px" }}
                          />
                    <a href="https://ncdalliance.org/" target="_blank" style={{textDecoration:"none"}}>
                      <p className="partnerLogoHeader" >Visit HomePage
                        <span>
                        <img
                        className="img-fluid"
                        alt=""
                        src={imgUrl.right_orangearrow}
                        style={{ marginLeft: "4px" , marginTop:"-2px" , cursor:"pointer" }}
                      /> </span>
                        </p>    
                    </a>    
                    </div>
                  </Col>
                  <Col md={4} xs={4} sm={4} lg={4}>
                    <div>
                      <img
                            className="img-fluid"
                            alt=""
                            src={imgUrl.path_logo}
                            // style={{ width: "115%" ,marginLeft:"21%" , marginTop:"8%" }}
                          />
                    <a href="https://www.path.org/" target="_blank" style={{textDecoration:"none"}}>
                       <p className="partnerLogoHeader" >Visit HomePage
                        <span>
                        <img
                        className="img-fluid"
                        alt=""
                        src={imgUrl.right_orangearrow}
                        style={{ marginLeft: "4px" , marginTop:"-3px" ,  cursor:"pointer" }}
                      /> </span>
                        </p>  
                    </a>       
                    </div>
                  </Col>
                </Row>      
                <Row className="partnersLogoContainer " style={{marginTop:"14%" , marginLeft:"2%"}}>
                  <Col md={4} xs={4} sm={4} lg={4}>
                    <div>
                      <img
                          className="img-fluid"
                          alt=""
                          src={imgUrl.worldbank_logo}
                          // style={{ width: "10px", marginRight: "4px" , marginTop:"-5px" }}
                        />
                      <a href="https://www.worldbank.org/en/home" target="_blank" style={{textDecoration:"none"}}>
                         <p className="partnerLogoHeader">Visit HomePage
                        <span>
                        <img
                        className=""
                        alt=""
                        src={imgUrl.right_orangearrow}
                        style={{ marginLeft: "4px" , marginTop:"-3px" , cursor:"pointer" }}
                      /> </span>
                        </p>
                        </a>      
                     </div>   
                  </Col>
                  <Col md={4} xs={4} sm={4} lg={4} style={{marginLeft: '6%'}}>
                    <div>
                    <img
                          className="img-fluid"
                          alt=""
                          src={imgUrl.world_logo}
                          // style={{marginLeft:"4%"}}
                        />
                        <a href="https://world-heart-federation.org/" target="_blank" style={{textDecoration:"none"}}>
                         <p className="partnerLogoHeader">Visit HomePage
                        <span>
                        <img
                        className=""
                        alt=""
                        src={imgUrl.right_orangearrow}
                        style={{ marginLeft: "4px" , marginTop:"-3px" ,  cursor:"pointer" }}
                      /> </span>
                        </p>
                        </a>  
                      </div>
                  </Col>
                  {/* <Col md={4} xs={4} sm={4} lg={4}>
                    
                  </Col> */}
                </Row>             
                
             
            </Col>
          </Row>
        </Container>
      </div>

      <div className="tabPartner">
        <Nav tabs>
          <NavItem className="partnerActive">
            <NavLink
              // className={classnames({ active: activeTab === '1' })}
              // onClick={() => { toggle('1'); }}
              href="/partner-module"
              className='active'
            >
               OUR PARTNERS / M&amp;E FRAMEWORK
            </NavLink>
          </NavItem>
          <NavItem className="collectiveActive">
            <NavLink
              // className={classnames({ active: activeTab === '2' })}
              // onClick={() => { toggle('2'); }}
              href="/collective-impact"
              // className='active'
            >
                 WORKING TOWARD COLLECTIVE IMPACT
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {/* <Row>
              <Col sm="12">
                <h4>Tab 1 Contents</h4>
              </Col>
            </Row> */}
          </TabPane>
          <TabPane tabId="2">
            {/* <Row>
              <Col sm="6">
                <Card body>
                  <CardTitle>Special Title Treatment</CardTitle>
                  <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                  <Button>Go somewhere</Button>
                </Card>
              </Col>
              <Col sm="6">
                <Card body>
                  <CardTitle>Special Title Treatment</CardTitle>
                  <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                  <Button>Go somewhere</Button>
                </Card>
              </Col>
            </Row> */}

          </TabPane>
        </TabContent>
      </div>
    
                 
      <div className="dashboardtemplate-holder reportPage partnerModule">
        <Container>

          
            <Row>
                <Col lg="3">
                  <div className="">
                    <p className="text-left partnerTitle mb-4">
                      RTI REPORT 2021
                      </p>
                      <img className="reportImg mt-4" src={imgUrl.report_partner} />
                     
                      <a href = {Pdf} target = "_blank">
                        <p>
                          <Button
                            className="partnerBtn btnexploresmall text-uppercase"
                            // onClick={showDashboard}
                            
                          
                          > 
                            <img
                              className="downloadReport"
                              alt="downloadReport"
                              src={imgUrl.download_white_arrow}
                            />
                            
                            DOWNLOAD FULL REPORT
                          </Button>
                          
                        </p>
                      </a>
                  </div>
                </Col>
                <Col lg="9">
                  <div className="reportContent">
                    <h5 className="reportDiv-header">
                      ABOUT THE REPORT
                    </h5>
                    <div className="reportText partnerBg">
                      <p>
                        Access Accelerated engaged RTI International in March 2021 to design a measurement framework to capture the impact of its investments for collective action through partner projects. 
                        
                        Using its approach to monitoring, evaluation, research, learning and adapting approach, RTI is working with Access Accelerated to measure the individual and collective impact of partner projects while adapting the measurement framework as needed to produce meaningful and novel results. 
                        Collective impact refers to population- and systems-level changes achieved by organizations learning together, aligning, and integrating their actions. 
                        RTI seeks to capture the totality of partner efforts undertaken by monitoring a streamlined set of indicators, establishing accompanying narratives, and focusing on learning. 
                        </p>
                        <p>
                        This annual measurement framework report summarizes the results achieved by Access Accelerated partners in 2021. 
                        It describes the “connective tissue” that Access Accelerated is building through its consistent engagement with public and private partners across time and space in a way that results in knowledge sharing, strengthened relationships based on a common goal, and, ultimately, trust. 
                        </p>
                        <p>
                        In 2021, across 48 projects in 44 countries, partners reported: - Reaching over 30,000 people reached with NCD services, and over 90,000 benefitting from improved access to services - Supporting 136 policy changes - Developing 42 new investment cases and 117 knowledge products - Establishing 105 new partnerships in 34 countries The 2021 Measurement Framework report documents the central role of equity in Access Accelerated partner projects, as well as the way that project embeddedness can lay groundwork for sustainability. 
                        The focus on equity demonstrates the Access Accelerated and partner commitment to agendas that favor collective impact. Flexible funding from Access Accelerated facilitates project embeddedness, supporting project evolution and longevity. The report points to opportunities for shared impact, particularly where multiple Access Accelerated partners operate in the same country. 
                        Industry and private sector engagement in concerted action for NCDs can facilitate a diversified common pool of skills and expertise, coordination, information, funding, and technologies. Success requires solid coordination through a supporting structure is necessary. It is no small undertaking to invest in NCD prevention, management and control, and the diverse portfolio of actions captured in this report reflects the value placed on resolving complex health challenges.
                      </p>

                      
                    </div>
                  </div>
                </Col>
                      
            </Row>
                
            
        </Container>
      </div>
      
         
      <div className="dashboardtemplate-holder partnerModule bg-greyish">
        <Container>
          <div className="mt-4 mb-0 exploreallbtnholder">
            <Row>
              <Col lg="12">
                <div className="text-center">
                  <p className="text-right partnerTitle">
                      M&amp;E FRAMEWORK
                  </p>
                  <p className="text-center db-description"></p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt-4 mb-0 exploreallbtnholder">
            <Row>
              <Col lg="12" className="partnerLogoMainHolder">
                { allFramework && allFramework.map((obj) => (
                  <div className={`text-center portallogomaindiv frameWork-${obj.parent_id}`}>
                    <div className="partnerLogoHolder4" style={{cursor: 'pointer'}} onClick={() => activeCardDetails(obj.parent_id, obj.description)}>
                      <img
                        className="reportLogoImg"
                        alt="meFramework"
                        src={imgUrl[obj.framework.replace(" ","_").toLowerCase()]}
                      />
                    </div>
                    <p className="partnerHeading">{obj.framework}</p>
                    
                    <div className={ obj.parent_id == "1" ? "text-center portallogodiv4 box-orange" : obj.parent_id == "2" ? "text-center portallogodiv4 box-green" : 'text-center portallogodiv4 box-blue' }>  
                    { obj.type && obj.type.map((val,index) => (

                      <div id={val.child_id} className={activeClass?`frameworkDiv ${activeClass} ${index !== 0 ? "mt-3": ''}`:`frameworkDiv partnerDivContainer ${index !== 0 ? "mt-3": ''}`} onClick={() => activeFramework(obj.parent_id, val.child_id)} style={{cursor: "pointer"}}>
                          <p className="mb-0 partnerPara">
                            {val.name}
                          </p>
                      </div>
                      ))
                    }
                    </div>  
                  </div>
                ))}
              </Col>
            </Row> 
          </div>

          <div className="mt-4 mb-0 exploreallbtnholder">
            <Row>
              <Col lg="12" className="descriptionContainer">      
                <div className="text-center">
                  <div className={`partnerDivCard ${activeDescClass}`}>
                    {initialBox &&  <img
                      className="img-fluid mb-2"
                      alt="grey_arrow"
                      src={imgUrl.Framework_arrow}
                    />}
                    <p className="sectionPara mb-0">{frameDescription}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

        </Container>
      </div>
      {/* <div className="feedbackIconDiv">
        <a
          href="https://forms.office.com/r/dkKdjFgGeK"
          target="_blank"
        >
          <img
            className="img-fluid"
            alt="runningman"
            src={imgUrl.feedback_icon}
            style={{ width: "90%" }}
          />
        </a>
      </div> */}

    </>
  );
}

export default PartnerModule;
