import React, { Component } from "react";
import { Report } from "powerbi-report-component";
import { Redirect } from "react-router-dom";
import axios from "axios";
//import Loader from "react-loader-spinner";
// import powerBITheme from "../assets/data/Final_AA_THEME.json";
// import SecondaryNavbar from "../components/navbar/SecondaryNavbar.js";
import { Helmet } from "react-helmet";
import apiBaseUrl from "../config";
import { models } from 'powerbi-client';

class PowerBIDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiResponse: null,
      report: null,
      showLoader: true,
      visual: null,
      activeTab: "",
      activePageTab: "",
      themeJson: null,
    };
  }

  activePage = () => {
    const pageUrl = window.location.href;

    let pathArray = pageUrl.split("/");
    console.log("pageUrl Array", pathArray[3]);
    if (pathArray && pathArray[3] == "programs") {
      this.setState({
        activeTab: "ReportSection",
      });
    } else if (pathArray && pathArray[3] == "members") {
      this.setState({
        activeTab: "ReportSectionb00eedf560c838917252",
      });
    } else if (pathArray && pathArray[3] == "ncds") {
      this.setState({
        activeTab: "ReportSectionc791ca15d9b786c6b683",
      });
    } else if (pathArray && pathArray[3] == "countries") {
      this.setState({
        activeTab: "ReportSection1d05a85cefc534c6294a",
      });
    }
  };

  callApi = () => {
    console.log("Called API");
  };

  componentDidMount() {
    this.setState({
      showLoader: true,
    });

    axios
      //.get("/getEmbedToken", {
      //.get("http://localhost:5300/getEmbedToken") https://access-accelerated.herokuapp.com/getEmbedToken
      .get(
        `${apiBaseUrl}getEmbedToken`,
        {
          params: {
            layout: this.props.layoutSelected,
          },
        }
      )
      .then((res) => {
        console.log(res);

        this.setState({
          apiResponse: res.data,
          showLoader: false,
        });
        //sessionStorage.setItem("apiResponse", JSON.stringify(res.data));
        // sessionStorage.setItem(
        //   "apiResponseToke",
        //   JSON.stringify(res.data.accessToken)
        // );
        //sessionStorage.setItem("apiReport", this.state.report);

        // Building the config object
        // let config = {
        //   type: 'report',
        //   embedUrl: res.data.embedUrl,
        // };

        // let element = powerbi.preload(config);

        axios
          .get(
            "https://aaopenplatform.accessaccelerated.org/AOProfiles/Final_AA_THEME.json"
          )
          .then((resp) => {
            this.setState({
              themeJson: resp.data,
            })
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    this.activePage();
  }

  render() {
    if (this.state.apiResponse != null) {
      //console.log(this.state.apiResponse);
      //console.log(this.state.report);
      //console.log(this.props);

      let memberSelected = [];
      let allMembers = [];
      let topicSelected = [];
      let allTopics = [];
      let regionSelected = [];
      let allRegions = [];
      let countrySelected = [];
      let allCountries = [];
      let allStrategies = [];
      let strategySelected = [];
      let allBeneficiaries = [];
      let beneficiarySelected = [];
      let allDataSource = [];
      let dataSourceSelected = [];

      // if (this.props.memberSelected.length >= 1) {
      //   for (let i in this.props.memberSelected) {
      //     //console.log(this.props.memberSelected[i].key)
      //     memberSelected.push(this.props.memberSelected[i].key);
      //   }
      // } else {
      //   for (let i in this.props.allMembers) {
      //     //console.log(this.props.memberSelected[i].key)
      //     allMembers.push(this.props.allMembers[i].key);
      //   }
      // }

      if (this.props?.topicSelected?.length >= 1) {
        for (let i in this.props.topicSelected) {
          //console.log(this.props.memberSelected[i].key)
          topicSelected.push(this.props.topicSelected[i].key);
        }
      } else {
        for (let i in this.props.allTopics) {
          //console.log(this.props.memberSelected[i].key)
          allTopics.push(this.props.allTopics[i].key);
        }
      }

      if (this.props?.regionSelected?.length >= 1) {
        for (let i in this.props.regionSelected) {
          //console.log(this.props.memberSelected[i].key)
          regionSelected.push(this.props.regionSelected[i].key);
        }
      } else {
        for (let i in this.props.allRegions) {
          //console.log(this.props.memberSelected[i].key)
          allRegions.push(this.props.allRegions[i].key);
        }
      }

      if (this.props?.countrySelected?.length >= 1) {
        for (let i in this.props.countrySelected) {
          //console.log(this.props.memberSelected[i].key)
          countrySelected.push(this.props.countrySelected[i].key);
        }
      } else {
        for (let i in this.props.allCountries) {
          //console.log(this.props.memberSelected[i].key)
          allCountries.push(this.props.allCountries[i].key);
        }
      }

      if (this.props?.strategySelected?.length >= 1) {
        for (let i in this.props.strategySelected) {
          //console.log(this.props.memberSelected[i].key)
          strategySelected.push(this.props.strategySelected[i].key);
        }
      } else {
        for (let i in this.props.allStrategies) {
          //console.log(this.props.memberSelected[i].key)
          allStrategies.push(this.props.allStrategies[i].key);
        }
      }

      if (this.props?.beneficiarySelected?.length >= 1) {
        for (let i in this.props.beneficiarySelected) {
          //console.log(this.props.memberSelected[i].key)
          beneficiarySelected.push(this.props.beneficiarySelected[i].key);
        }
      } else {
        for (let i in this.props.allBeneficiaries) {
          //console.log(this.props.memberSelected[i].key)
          allBeneficiaries.push(this.props.allBeneficiaries[i].key);
        }
      }

      if (this.props?.dataSourceSelected?.length >= 1) {
        for (let i in this.props.dataSourceSelected) {
          //console.log(this.props.memberSelected[i].key)
          dataSourceSelected.push(this.props.dataSourceSelected[i].key);
        }
      } else {
        for (let i in this.props.allDataSource) {
          //console.log(this.props.memberSelected[i].key)
          allDataSource.push(this.props.allDataSource[i].key);
        }
      }

      if (
        this.state.report != null &&
        (memberSelected.length >= 1 ||
          topicSelected.length >= 1 ||
          regionSelected.length >= 1 ||
          countrySelected.length >= 1 ||
          strategySelected.length >= 1 ||
          beneficiarySelected >= 1 ||
          dataSourceSelected >= 1)
      ) {
        // const filter = {
        //   $schema: "http://powerbi.com/product/schema#basic",
        //   target: {
        //     table: "AAD_Organisation_master",
        //     column: "Org_name",
        //   },
        //   operator: "In",
        //   //values: ["Merck"],
        //   values: memberSelected.length >= 1 ? memberSelected : allMembers,
        // };

        const filter1 = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "AAD_NCD_Master",
            column: "NCD_type",
          },
          operator: "In",
          //values: ["Africa"],
          values: topicSelected.length >= 1 ? topicSelected : allTopics,
        };

        const filter2 = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "AAD_Region_master",
            column: "region_name",
          },
          operator: "In",
          //values: ["Africa"],
          values: regionSelected.length >= 1 ? regionSelected : allRegions,
        };

        const filter3 = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "AAD_Country_master",
            column: "country_name",
          },
          operator: "In",
          //values: ["Africa"],
          values: countrySelected.length >= 1 ? countrySelected : allCountries,
        };

        const filter4 = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "AAD_Strategy_Master",
            column: "Strategy_Name",
          },
          operator: "In",
          //values: ["Africa"],
          values:
            strategySelected.length >= 1 ? strategySelected : allStrategies,
        };

        const filter5 = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "AAD_Target_poulation_Beneficiaries",
            column: "TGT_population_desc",
          },
          operator: "In",
          //values: ["Africa"],
          values:
            beneficiarySelected.length >= 1
              ? beneficiarySelected
              : allBeneficiaries,
        };

        const filter6 = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "AAD_Program_master",
            column: "program_source",
          },
          operator: "In",
          //values: ["Africa"],
          values:
            dataSourceSelected.length >= 1 ? dataSourceSelected : allDataSource,
        };

        //console.log(filter);
        //console.log(filter1);
        // console.log(filter2);
        // console.log(filter3);
        // console.log(filter4);
        // console.log(filter5);
        // console.log(filter6);

        this.state.report
          .setFilters([filter1, filter2, filter3, filter4, filter5, filter6])
          //.setFilters([filter, filter2])
          .catch(function (errors) {
            console.log(errors);
          });

        // const basicFilter = {
        //   $schema: "http://powerbi.com/product/schema#basic",
        //   target: {
        //     table: "Store",
        //     column: "Count"
        //   },
        //   operator: "In",
        //   values: [1,2,3,4],
        //   filterType: pbi.models.FilterType.BasicFilter
        // };

        // this.state.visual.setSlicerState({
        //   filters: [filter, filter1, filter2, filter3],
        // });
      }

      if (this.props.clearFilterVariable) {
        //this.state.report.removeFilters();

        const filter = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "VW_AA_PROGRAM_INFORMATION",
            column: "Org_displayed_Name",
          },
          operator: "In",
          //values: ["Merck"],
          values: allMembers,
        };

        const filter1 = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "VW_AA_PROGRAM_INFORMATION",
            column: "NCD_sub_type",
          },
          operator: "In",
          //values: ["Africa"],
          values: allTopics,
        };

        const filter2 = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "VW_AA_PROGRAM_INFORMATION",
            column: "REGION_NAME",
          },
          operator: "In",
          //values: ["Africa"],
          values: allRegions,
        };

        const filter3 = {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "VW_AA_PROGRAM_INFORMATION",
            column: "COUNTRY_NAME",
          },
          operator: "In",
          //values: ["Africa"],
          values: allCountries,
        };

        //console.log(filter);
        //console.log(filter2);

        this.state.report
          .setFilters([filter, filter1, filter2, filter3])
          //.setFilters([filter, filter2])
          .catch(function (errors) {
            console.log(errors);
          });

        // this.state.visual.setSlicerState({
        //   filters: [filter, filter1, filter2, filter3],
        // });
      }

      let { accessToken, embedUrl, embedID } = this.state.apiResponse;
      // let models = window['powerbi-client'].models;
      //sessionStorage.setItem("accessToken", accessToken);
      //console.log(accessToken);

      //console.log(accessToken);
      //console.log(embedUrl);

      //embedUrl = embedUrl + "&filterPaneEnabled=False";

      //   'filter=VW_AA_PROGRAM_INFORMATION/Org_displayed_Name eq "Merck"';

      const reportStyle = {
        // style object for report component
        height: "230vh",
        fontFamily: "BrandonGrotesque-Medium",
        //fontSize: 12,
      };

      const extraSettings = {
        filterPaneEnabled: false, //true
        navContentPaneEnabled: false, //true
        hideErrors: false, // Use this *only* when you want to override error experience i.e, use onError
        layoutType: models.LayoutType.MobileLandscape,
        // ... more custom settings
      };

      return (
        <>
          <Helmet>
            <title>{this.state.activePageTab + ` Access Accelerated`}</title>
            <meta
              name="description"
              content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
            />
            {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
          </Helmet>
          {/* <Loader
            visible={this.state.showLoader}
            type="ThreeDots"
            color="#3A3A3A"
            height={100}
            width={100}
            //timeout={3000} //3 secs
          /> */}
          <Report
            tokenType="Embed" // Embed or Aad
            accessToken={accessToken} // accessToken goes here
            embedUrl={embedUrl} // embedUrl goes here
            //embedId="83c0064a-b1b1-479d-8216-35b9c76ff7ed" // report or dashboard Id goes here
            //embedId="616a4463-00da-4b18-aefe-ba2b435f77ac"
            embedId={embedID}
            pageName={this.state.activeTab} // set as current page of the report
            reportMode="edit" // options: view/edit/create
            permissions="All" // View
            style={reportStyle}
            extraSettings={extraSettings}
            onLoad={(report) => {
              console.log("Report Loaded!");
              console.log(report);
              //console.log(powerBITheme);

              this.setState({
                report: report,
                showLoader: false,
              });
              //sessionStorage.setItem("report", report);
              this.state.report.applyTheme(this.state.themeJson);

              // report.getPages().then(function (pages) {
              //   console.log(pages);
              //   // Retrieve first page.
              //   var firstPage = pages[0];

              //   firstPage.getVisuals().then(function (visuals) {
              //     //console.log(visuals);

              //     this.setVisuals({
              //       visual: visuals,
              //       //showLoader: false,
              //     });
              //   });
              // });
            }}
            onRender={(report) => {
              console.log("Report Rendered!");
              //this.report = report;
              //sessionStorage.setItem("apiDataReport1", report);
            }}
            onPageChange={(data) => {
              //console.log("Page name :",data.newPage.name);
              //sessionStorage.setItem("reportPageName", data.newPage.name);
              localStorage.setItem(
                "reportPageDisplayName",
                data.newPage.displayName
              );
              console.log("Page Data :", data);
              //console.log("Page Data Page:",data.newPage);
              // console.log(`Page name :{data.newPage.displayName}`)
              this.props.selectReportTab(data.newPage.displayName);

              let activePageT = data.newPage.displayName;

              let activePageTemp =
                activePageT.charAt(0).toUpperCase() +
                activePageT.substr(1).toLowerCase();

              if (activePageTemp === "Topics") {
                activePageTemp = "NCDs";
              } else if (activePageTemp === "Coviddashboard") {
                activePageTemp = "COVID-19 Portal";
              }

              this.setState({
                activePageTab: activePageTemp + " -",
              });
            }}
            onSelectData={(data) => {
              console.log("You clicked on chart data:", data);
              console.log("You clicked on chart visual:", data.visual);
              console.log("You clicked on chart title:", data.visual.title);
            }}
          />

          {/* <SecondaryNavbar getBookmarkId={this.callApi} /> */}
        </>
      );
    } else {
      return null;
    }
  }
}
export default PowerBIDashboard;
