import React, { useState, useRef, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardTitle,
  CardText,
  Button,
  withref,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  InputGroup,
  FormGroup,
  Label,
  Input,
  Collapse,
  CardBody,
} from "reactstrap";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import imgUrl from "../assets/images/imgUrl.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import apiBaseUrl from "../config";
import Loader from "react-loader-spinner";
import LeafletMap from "../components/dashboard_overview/LeafletMap";
import $ from 'jquery';

// importing Slider components
import ProgramSlider from "../components/dashboard_overview/ProgramSlider";
import MemberSlider from "../components/dashboard_overview/MemberSlider.js";
import NcdSlider from "../components/dashboard_overview/NcdSlider.js";
import CountrySlider from "../components/dashboard_overview/CountrySlider.js";

// importing profile components
// import NcdDetail from "../components/dashboard_overview/NcdDetail.js";
// import MemberDetail from "../components/dashboard_overview/MemberDetail.js";

// importing Map and chart components
import OverviewMap from "../components/dashboard_overview/OverviewMap.js";
import NcdDistribution from "../components/dashboard_overview/NcdDistribution.js";
import BeneficiaryDistribution from "../components/dashboard_overview/BeneficiaryDistribution.js";
import StrategyDistribution from "../components/dashboard_overview/strategyDistribution.js";
import RegionDistribution from "../components/dashboard_overview/RegionDistribution.js";
import KeyPopulation from "../components/dashboard_overview/KeyPopulation.js";

import useScreenOrientation from 'react-hook-screen-orientation'
import swal from 'sweetalert';


function OverviewDashboard() {
  const [spinnerLoading, setSpinnerLoading] = useState(true); // Loader state
  const [programList, setProgramList] = useState([]);
  const [searchProgramList, setSearchProgramList] = useState([]);
  const [searchBeneficiaryList, setSearchBeneficiaryList] = useState([]);
  const [searchConuntryList, setSearchCountryList] = useState([]);
  const [searchNCDList, setSearchNCDList] = useState([]);
  const [filterBeneficiaryArr, setFilterBeneficiaryArr] = useState([]);
  const [filterCountryArr, setFilterCountryArr] = useState([]);
  const [BeneficiaryPrograms, setBeneficiaryPrograms] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [ncdList, setNCDList] = useState([]);
  const [ncdProgramCount, setNCDProgramCount] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [regionList2, setRegionList2] = useState([]);
  const [keyPop, setKeyPop] = useState({});
  const [strategies, setStrategy] = useState([]);
  const [ncdNames, setNCDNames] = useState([]);
  const [programId, setProgramId] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [memberName, setMemberName] = useState("");
  const [totalBene, setTotalBene] = useState(0);
  const [partnerTotal, setPartnerTotal] = useState(0);
  const [memberTotal, setMemberTotal] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const togglecollapse = () => setIsOpen(!isOpen);
  const [activeTab, setActiveTab] = useState("1");
  const [dropdownOpenMob, setDropdownOpenMob] = useState(false);
  const [dropdownOpenMob2, setDropdownOpenMob2] = useState(false);
  const [dropdownOpenMob3, setDropdownOpenMob3] = useState(false);
  const [dropdownOpenMob4, setDropdownOpenMob4] = useState(false);
  const [dropdownOpenMob5, setDropdownOpenMob5] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  const [dropdownOpen4, setDropdownOpen4] = useState(false);
  const [dropdownOpen5, setDropdownOpen5] = useState(false);

  // To close detial page
  const [closeProgramDetail, setCloseProgramDetail] = useState(0); // Program
  const [closeBeneDetail, setCloseBeneDetail] = useState(0); // beneficiary
  const [closeMemberDetail, setCloseMemberDetail] = useState(0); // Member
  const [closeNcdDetail, setCloseNcdDetail] = useState(0); // NCD
  const [closeCountryDetail, setCloseCountryDetail] = useState(0);// Country

  const [selectBeneCountry, setSelectBeneCountry] = useState([]);
  const [selectCountry, setSelectCountry] = useState([]);
  const [selectNcds, setSelectedNcds] = useState([]);
  const [allCountry, setAllCountry] = useState([]);

  const [filterCountryMap, setFilterCountryMap] = useState([]);
  const [filterCountryBeneMap, setFilterCountryBeneMap] = useState([]);
  const [filterNCDMap, setFilterNCDMap] = useState([]);

  // Tab section - count of program, benefeciary, Member, Ncd, Country
  const [programCount, setProgramCount] = useState();
  const [beneficiaryCount, setBeneficiaryCount] = useState("");
  const [memberCount, setMemberCount] = useState();
  const [ncdCount, setNcdCount] = useState();
  const [countryCount, setCountryCount] = useState();

  const stateRef = useRef();
  const [updateNCDDist, setUpdateNCDdist] = useState(0);
  const [mapDataArr, getMapDataArr] = useState([]);
  const [filterArrChart, getFilteredArr] = useState([]);
  const [filterStrategies, getFilterStrategies] = useState([]);
  const [flterProgSlider, setFlterProgSlider] = useState(null);

  const [totalNcdCount, setTotalNcdCount] = useState();

  // Partners Map State
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [partMapData, setPartMapData] = useState([]);
  const [partnersData, setPartnersData] = useState([]);
  const [allPartner, setallPartner] = useState("");
  const [mapKey, setMapKey] = useState(1);
  const [activeCountry, setActiveCountry] = useState({})
  const [thameticDataArr, setThameticDataArr] = useState([])
  const [thameticNames, setThameticNames] = useState([])
  const [filterIsoCode, setFilterIsoCode] = useState("");
  const [showPartnersMap, setShowPartnersMap] = useState(false)
  // Partners Map State

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth
  })

  // Parent NCD array
  const [parentNCDArr, setParentNCDArr] = useState([]);
  const [showHideDiv, setShowHideDiv] = useState(true);
  const screenOrientation = useScreenOrientation()

  function toggle(tab) {
    setProgramCount(programList.length);
    setBeneficiaryCount(TotalVal(totalBene));
    setMemberCount(memberList.length);
    setNcdCount(totalNcdCount);
    setCountryCount(countryList.length);

    setFilterCountryArr([]);//reset country slider
    setFilterBeneficiaryArr([]);

    setProgramId(null);
    setFilterCountryMap([]);
    setFilterCountryBeneMap([]);
    setFilterNCDMap([]);
    setMemberId(null);
    setMemberName("");

    if (dimensions && dimensions.width < 1000) {
      toggleDropDownMob(tab);
    }
    if (activeTab !== tab)
      setActiveTab(tab);

    if (tab === "1") {
      setCloseBeneDetail(closeBeneDetail + 1);
      setCloseMemberDetail(closeMemberDetail + 1);
      setCloseNcdDetail(closeNcdDetail + 1);
      setCloseCountryDetail(closeCountryDetail + 1);
      resetCheckbox();
    }
    if (tab === "2") {
      setCloseProgramDetail(closeProgramDetail + 1);
      setCloseMemberDetail(closeMemberDetail + 1);
      setCloseNcdDetail(closeNcdDetail + 1);
      setCloseCountryDetail(closeCountryDetail + 1);
      resetNCDCheckbox();
      resetCountryCheckbox();

    }
    if (tab === "3") {
      setCloseProgramDetail(closeProgramDetail + 1);
      setCloseBeneDetail(closeBeneDetail + 1);
      setCloseNcdDetail(closeNcdDetail + 1);
      setCloseCountryDetail(closeCountryDetail + 1);
      resetCheckbox();
    }
    if (tab === "4") {
      setCloseProgramDetail(closeProgramDetail + 1);
      setCloseBeneDetail(closeBeneDetail + 1);
      setCloseMemberDetail(closeMemberDetail + 1);
      setCloseCountryDetail(closeCountryDetail + 1);
      resetCountryCheckbox();
      resetBeneCheckbox();
    }
    if (tab === "5") {
      setCloseProgramDetail(closeProgramDetail + 1);
      setCloseBeneDetail(closeBeneDetail + 1);
      setCloseMemberDetail(closeMemberDetail + 1);
      setCloseNcdDetail(closeNcdDetail + 1);
      resetNCDCheckbox();
      resetBeneCheckbox();
    }
  }

  const callBackFun = (getCallback) => {
    let getVal = getCallback;
    let getMapData = stateRef.current;
    let filteredArr;

    if (getMapData.length > 0) {
      filteredArr = getMapData.filter((data) => {
        return data.iso3_code == getVal;
      });
      setUpdateNCDdist(updateNCDDist + 1);
      getFilteredArr(filteredArr);
      getFilterStrategies(filteredArr);
    }
  };

  const callBackProg = (id) => {
    setActiveTab("1");
    setFlterProgSlider(id)
    setCloseBeneDetail(closeBeneDetail + 1);
    setCloseMemberDetail(closeMemberDetail + 1);
    setCloseNcdDetail(closeNcdDetail + 1);
    setCloseCountryDetail(closeCountryDetail + 1);
    window.scrollTo(0, 0);
  }

  const history = useHistory();

  // const routeChange = ('dashboard') => {
  //   let path = `/programs`;
  //   history.push(path);
  // };

  const setActivePage = (page) => {
    localStorage.setItem("activePage", page);
  };

  const routeChange = (page) => {
    let path = `/programs`;
    localStorage.setItem("landingPageIs", page);
    localStorage.setItem("onClick", "navbarClick");
    if (page === "dashboard") {
      setActivePage("ncdDashboard");
      history.push(path);
    } else {
      setActivePage("home");
    }
  };

  const callFun = (ele) => {
    let eleID = ele;
    let text = eleID.replace(/[^1-9a-zA-Z ]/g, "").replaceAll(" ", "_");
    let element = document.getElementById(text);
    element.classList.toggle("show");
    let getfontIcon = element.previousSibling.children[0];
    if (getfontIcon.classList.contains("fa-plus")) {
      getfontIcon.classList.remove("fa-plus");
      getfontIcon.classList.add("fa-minus");
    } else {
      getfontIcon.classList.remove("fa-minus");
      getfontIcon.classList.add("fa-plus");
    }
  };

  const selectAllFun = (ele, isChecked, parent = "", countryFiter = "", mobView) => {
    let eleID = ele;
    let text;
    if (countryFiter === "countryArr") {
      text = eleID.replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "_").concat(mobView) + "2";
    } else {
      text = eleID.replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "_").concat(mobView);
    }

    if (parent === "parent") {
      resetBeneCheckbox();
      resetCountryCheckbox();
      let ele = document.querySelector(`.${text}`);
      // console.log("ele elect", ele)
      ele.checked = true;
    }

    let element = document.querySelectorAll(`#${text} input`);
    if (isChecked) {
      for (var i = 0; i < element.length; i++) {
        if (element[i].type == "checkbox") element[i].checked = true;

        if (element[i].type == "radio") element[i].checked = true;
      }
    } else {
      for (var i = 0; i < element.length; i++) {
        if (element[i].type == "checkbox") element[i].checked = false;

        if (element[i].type == "radio") element[i].checked = false;
      }
    }
  };

  const unSelectParent = (val) => {
    ncdList.forEach((ncd) => {
      if (ncd["sub types"].includes(val)) {
        let parentName = ncd.ncd_name;
        let element = document.querySelector(
          `.${parentName.replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "_")}`
        );
        element.checked = false;
      }
    });
  };

  const fullForm = (title) => {
    if (title == "JnJ") {
      return "Johnson & Johnson";
    } else if (title == "BMS") {
      return "Bristol-Myers Squibb";
    } else {
      return title;
    }
  };

  useEffect(() => {
    if (dropdownOpenMob) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      document.getElementById("addRemoveScroll").style.overflow = "hidden";
    } else {
      document.getElementById("addRemoveScroll").style.overflow = "auto";
    }
  }, [dropdownOpenMob])

  useEffect(() => {
    if (dropdownOpenMob2) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      document.getElementById("addRemoveScroll").style.overflow = "hidden";
    } else {
      document.getElementById("addRemoveScroll").style.overflow = "auto";
    }
  }, [dropdownOpenMob2])

  useEffect(() => {
    if (dropdownOpenMob3) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      document.getElementById("addRemoveScroll").style.overflow = "hidden";
    } else {
      document.getElementById("addRemoveScroll").style.overflow = "auto";
    }
  }, [dropdownOpenMob3])

  useEffect(() => {
    if (dropdownOpenMob4) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      document.getElementById("addRemoveScroll").style.overflow = "hidden";
    } else {
      document.getElementById("addRemoveScroll").style.overflow = "auto";
    }
  }, [dropdownOpenMob4])

  useEffect(() => {
    if (dropdownOpenMob5) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      document.getElementById("addRemoveScroll").style.overflow = "hidden";
    } else {
      document.getElementById("addRemoveScroll").style.overflow = "auto";
    }
  }, [dropdownOpenMob5])

  const toggleDropDownMob = (tab) => {
    if (tab === "1") setDropdownOpenMob(!dropdownOpenMob);
      if (tab === "2") setDropdownOpenMob2(!dropdownOpenMob2);
      if (tab === "3") setDropdownOpenMob3(!dropdownOpenMob3);
      if (tab === "4") setDropdownOpenMob4(!dropdownOpenMob4);
      if (tab === "5") setDropdownOpenMob5(!dropdownOpenMob5);
  }

  window.addEventListener('scroll', function() {
    setDropdownOpenMob(false);
      setDropdownOpenMob2(false);
      setDropdownOpenMob3(false);
      setDropdownOpenMob4(false);
      setDropdownOpenMob5(false);
  });

  const toggleDropDown = (tab) => {
    if (tab === "1") setDropdownOpen(!dropdownOpen);
    if (tab === "2") setDropdownOpen2(!dropdownOpen2);
    if (tab === "3") setDropdownOpen3(!dropdownOpen3);
    if (tab === "4") setDropdownOpen4(!dropdownOpen4);
    if (tab === "5") setDropdownOpen5(!dropdownOpen5);

    if (activeTab !== tab) setActiveTab(tab);

    if (tab === "1") {
      setCloseBeneDetail(closeBeneDetail + 1);
      setCloseMemberDetail(closeMemberDetail + 1);
      setCloseNcdDetail(closeNcdDetail + 1);
      setCloseCountryDetail(closeCountryDetail + 1);
    }
    if (tab === "2") {
      setCloseProgramDetail(closeProgramDetail + 1);
      setCloseMemberDetail(closeMemberDetail + 1);
      setCloseNcdDetail(closeNcdDetail + 1);
      setCloseCountryDetail(closeCountryDetail + 1);
    }
    if (tab === "3") {
      setCloseProgramDetail(closeProgramDetail + 1);
      setCloseBeneDetail(closeBeneDetail + 1);
      setCloseNcdDetail(closeNcdDetail + 1);
      setCloseCountryDetail(closeCountryDetail + 1);
    }
    if (tab === "4") {
      setCloseProgramDetail(closeProgramDetail + 1);
      setCloseBeneDetail(closeBeneDetail + 1);
      setCloseMemberDetail(closeMemberDetail + 1);
      setCloseCountryDetail(closeCountryDetail + 1);
    }
    if (tab === "5") {
      setCloseProgramDetail(closeProgramDetail + 1);
      setCloseBeneDetail(closeBeneDetail + 1);
      setCloseMemberDetail(closeMemberDetail + 1);
      setCloseNcdDetail(closeNcdDetail + 1);
    }
  };

  const ProgramDetailCall = () => {
    const removeProg = [191, 185, 187, 189]
    fetch(`${apiBaseUrl}getProgramProfiles`)
      .then((response) => response.json())
      .then((data) => {
        const Program = data[0].programs.Program;
        const sortProgram = Program.sort((x, y) =>
          x.program_name.localeCompare(y.program_name)
        );
        const sortBenefaciaryProg = sortProgram.filter(
          (prog) => prog.catchement_Population_served != null
        );

        const allProgramList = sortProgram.filter((prog) => {
          return !removeProg.includes(prog.Program_id)
        })
        setProgramList(allProgramList);
        setProgramCount(allProgramList.length);
        setBeneficiaryPrograms(sortBenefaciaryProg);
        CountryDetailCall();
        MemberDetailCall();
        NcdDetailCall();
        RegionListCall();
        KeyPopulationCall();
        MapDataCall(); //calling map data
        StrategyCall();
        // setSpinnerLoading(false); // Hide Loader
      })
      .catch((err) => { });
  };

  const TotalBeneData = (
    data,
    setTotalBene,
    setPartnerTotal,
    setMemberTotal
  ) => {
    let total = 0,
      memmberTotal = 0,
      partnerTotal = 0;
    data.map((val) => {
      total =
        total +
        val["benificiary population"][0]["total benificiary population"];
      memmberTotal =
        memmberTotal +
        val["benificiary population"][0]["Member benificiary population"];
      partnerTotal =
        partnerTotal +
        val["benificiary population"][0]["Partner benificiary population"];
    });

    setBeneficiaryCount(TotalVal(memmberTotal)); // added only members total count
    setTotalBene(memmberTotal);// added only members total count
    setPartnerTotal(partnerTotal);
    setMemberTotal(memmberTotal);
  };

  const CountryDetailCall = () => {
    fetch(`${apiBaseUrl}getCountryData`)
      .then((response) => response.json())
      .then((data) => {
        const Country = data[0].country_json_load;
        const filterCountry = Country.filter(
          (ele) =>
            (ele.low_income === "Y" || ele.middle_income === "Y") &&
            ele["total no of program"]
        );
        TotalBeneData(Country, setTotalBene, setPartnerTotal, setMemberTotal);
        setCountryCount(filterCountry.length);
        setCountryList(filterCountry);
        setAllCountry(Country);
        // setSpinnerLoading(false); // Hide Loader
      })
      .catch((err) => { });
  };

  const MapDataCall = () => {
    fetch(`${apiBaseUrl}getMapData`)
      .then((response) => response.json())
      .then((data) => {
        const mapData = data[0].map_json_load.filter(
          (map) => map.low_income === "Y" || map.middle_income === "Y"
        );
        getMapDataArr(mapData);
        stateRef.current = mapData;
      })
      .catch((err) => { });
  };

  const MemberDetailCall = () => {
    fetch(`${apiBaseUrl}getMemberData`)
      .then((response) => response.json())
      .then((data) => {
        const Members = data[0].country_json_load;
        setMemberList(Members);
        setMemberCount(Members.length);
      })
      .catch((err) => { });
  };

  // getRegionList
  const NcdDetailCall = () => {
    fetch(`${apiBaseUrl}getNCDData`)
      .then((response) => response.json())
      .then((data) => {
        const NCDs = data[0].ncd_json_load;
        let progCount = NCDs.map((a) => a["total no of program"]);
        let ncd_Names = NCDs.map((b) => b.ncd_name);
        let subNcdCount = NCDs.reduce(function (accumulator, item) {
          return accumulator + item["sub types"].length;
        }, 0);
        setNCDProgramCount(progCount);
        setNCDNames(ncd_Names);
        setNCDList(NCDs);
        setTotalNcdCount(subNcdCount);
        setNcdCount(subNcdCount);
      })
      .catch((err) => { });
  };

  const SortedRegionArr = (a, b) => {
    let fa = a.Region_name.toLowerCase(),
      fb = b.Region_name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  };

  const RegionListCall = () => {
    fetch(`${apiBaseUrl}getRegionList`)
      .then((response) => response.json())
      .then((data) => {
        const Regions = data[0].country_tag;
        const sortedArr = Regions.sort(SortedRegionArr);
        setRegionList(sortedArr);
        setRegionList2(sortedArr);
      })
      .catch((err) => { });
  };

  const KeyPopulationCall = () => {
    fetch(`${apiBaseUrl}getKeyPopulation`)
      .then((response) => response.json())
      .then((data) => {
        const KeyPopulation = data[0].key_pop_json;
        setKeyPop(KeyPopulation);
      })
      .catch((err) => { });
  };

  const StrategyCall = () => {
    fetch(`${apiBaseUrl}getStrategyList`)
      .then((response) => response.json())
      .then((data) => {
        const strategy = data[0].strategy_json_load;
        setStrategy(strategy);
        setSpinnerLoading(false); // Hide Loader
        window.scrollTo(0, 0);
      })
      .catch((err) => { });
  };

  const searchProgramData = (e) => {
    let queryData = [];
    if (e.target.value != "") {
      programList.forEach(function (prog) {
        if (
          prog.program_name
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) != -1
        ) {
          if (queryData.length < 10) {
            queryData.push(prog);
          }
        }
      });
    }
    setSearchProgramList(queryData);
  };

  const searchBeneficiaryFun = (e) => {
    let FilterCountryArr = JSON.parse(JSON.stringify(regionList));
    let queryData = [];
    if (e.target.value != "") {
      FilterCountryArr.forEach(function (prog) {
        let countryArr = [];
        prog.Countries.map((country) => {
          if (
            country.Country_name.toLowerCase().indexOf(
              e.target.value.toLowerCase()
            ) != -1
          ) {
            countryArr.push(country); // adding only matching country
            if (queryData.length < 10) {
              prog.Countries = [...new Set(countryArr)];
              queryData.push(prog);
            }
          }
        });
      });
    }
    setSearchBeneficiaryList([...new Set(queryData)]);
  };

  const searchCountryFun = (e) => {
    let FilterCountryArr = JSON.parse(JSON.stringify(regionList2));
    let queryData = [];
    if (e.target.value != "") {
      FilterCountryArr.forEach(function (prog) {
        let countryArr = [];
        prog.Countries.map((country) => {
          if (
            country.Country_name.toLowerCase().indexOf(
              e.target.value.toLowerCase()
            ) != -1
          ) {
            countryArr.push(country); // adding only matching country
            if (queryData.length < 10) {
              prog.Countries = [...new Set(countryArr)];
              queryData.push(prog);
            }
          }
        });
      });
    }
    setSearchCountryList([...new Set(queryData)]);
  };

  const searchNCDFun = (e) => {
    let FilterNcdArr = JSON.parse(JSON.stringify(ncdList));
    let queryData = [];
    if (e.target.value != "") {
      FilterNcdArr.forEach(function (prog) {
        let ncdArr = [];
        prog["sub types"].map((ncd) => {
          if (ncd.toLowerCase().indexOf(e.target.value.toLowerCase()) != -1) {
            ncdArr.push(ncd); // adding only matching ncd
            if (queryData.length < 10) {
              prog["sub types"] = [...new Set(ncdArr)];
              queryData.push(prog);
            }
          }
        });
      });
    }
    setSearchNCDList([...new Set(queryData)]);
  };

  const TotalVal = (num) => {
    if (num > 999 && num < 1000000) {
      return Math.round((num / 1000).toFixed(1)) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return Math.round((num / 1000000).toFixed(1)) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return Math.round(num); // if value < 1000, nothing to do
    }
  };

  const BeneficiaryPop = (getProgram) => {
    if (getProgram && getProgram.length > 0) {
      if (getProgram[0].catchement_Population_served !== null) {
        let num =
          getProgram[0].catchement_Population_served.CatchmentAreas[0]
            .total_pop_served;

        setBeneficiaryCount(TotalVal(num)); //Pasing Total beneficiary State
      } else {
        setBeneficiaryCount("NA");
      }
    }
  };

  const resetTabValues = () => {
    setProgramCount(programList.length);
    setBeneficiaryCount(TotalVal(totalBene));
    setMemberCount(memberList.length);
    setNcdCount(totalNcdCount);
    setCountryCount(countryList.length);

    // reset all Slider
    setFilterCountryArr([]);//reset country slider
    setFilterBeneficiaryArr([]);

    setProgramId(null);
    setFilterCountryMap([]);
    setFilterCountryBeneMap([]);
    setFilterNCDMap([]);
    resetCheckbox();

    setSearchBeneficiaryList([]);
    setSearchCountryList([]);
    setSearchNCDList([]);
    setMemberName("");

    setSelectedNcds([]);
    setSelectBeneCountry([]);
    setSelectCountry([]);

    // reset parent dropdown selection input list
    setParentNCDArr([]);

    // reset chart Arr
    getFilteredArr([]);
    getFilterStrategies([]);
    NcdDetailCall();
    document.getElementById('exampleEmail').value = '';
    document.getElementById('beneSearch').value = '';
    document.getElementById('ncdSearchField').value = '';
    document.getElementById('countrySearchField').value = '';
    setUpdateNCDdist(updateNCDDist + 1);
  };

  const goPartnersMap = (val) => {
    setShowPartnersMap(val)
  }

  const resetCheckbox = () => {
    // unchecking all the selected checkbox
    let items = document.getElementsByTagName("input");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox") items[i].checked = false;

      if (items[i].type == "radio") items[i].checked = false;
    }
  };

  const resetNCDCheckbox = () => {
    // unchecking all the selected NCD checkbox
    let items = document.querySelectorAll(".ncdDropdown input");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox") items[i].checked = false;
    }
  };
  
  const resetCountryCheckbox = (selectedVal = "", mobView = "") => {
    // unchecking all the selected Country checkbox
    let items = document.querySelectorAll(".countryDropdown input");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox") items[i].checked = false;
      if (items[i].type == "radio") items[i].checked = false;
    }
    if (selectedVal != "") {
      let item = document.querySelector(
        `.${selectedVal.replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "_").concat(mobView)}2`
      );
      item.checked = true;
    }
  };

  const resetBeneCheckbox = (selectedVal = "", mobView = "") => {
    // unchecking all the selected Country checkbox
    let items = document.querySelectorAll(".beneficiaryDropdown input");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox") items[i].checked = false;
      if (items[i].type == "radio") items[i].checked = false;
    }
    if (selectedVal != "") {
      let item = document.querySelector(
        `.${selectedVal.replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "_").concat(mobView)}`
      );
      item.checked = true;
    }
  };

  const filterBasedRegion = (countryArr) => {
    let programArr = [];
    let allNcd = [],
      allMembers = [],
      allPrograms = [];

    allCountry.forEach(function (prog) {
      let program_countries = prog.Country_name;
      if (countryArr.includes(program_countries)) {
        programArr.push(prog);
      }
    });

    // Filter unique data based on NCD, Member and program
    programArr.forEach((contry) => {
      //filtering NCD for the count
      if (contry.NCDs && contry.NCDs.length > 0) allNcd.push(...contry.NCDs);
    });

    programArr.forEach((contry) => {
      //filtering Member for the count
      if (contry.AA_members && contry.AA_members.length > 0)
        allMembers.push(...contry.AA_members);
    });

    programArr.forEach((contry) => {
      //filtering programs for the count
      if (contry.programs && contry.programs.length > 0)
        allPrograms.push(...contry.programs);
    });

    let uniqueNcdArray = [
      ...new Set(allNcd.map((obj) => obj["NCDs sub type"])),
    ];
    let uniqueMemberArray = [
      ...new Set(allMembers.map((obj) => obj["member_id"])),
    ];
    let uniqueProgramArray = [
      ...new Set(allPrograms.map((obj) => obj["program_id"])),
    ];

    let allBeneCount = programArr.reduce(function (accumulator, item) {
      return (
        accumulator +
        item["benificiary population"][0]["total benificiary population"]
      );
    }, 0);

    setProgramCount(uniqueProgramArray.length);
    setBeneficiaryCount(TotalVal(allBeneCount));
    setMemberCount(uniqueMemberArray.length);
    setNcdCount(uniqueNcdArray.length);
    setCountryCount(programArr.length);
  };

  const filterBeneficiary = (e, parentVal = "", mobView = "") => {
    if (mobView == "") {
      toggle("2"); //toggle to the beneficiary tab
    }
    //clearing all the filters selection and data
    // resetting NCD map data and filter
    setFilterNCDMap([]);
    setSelectedNcds([]);
    resetNCDCheckbox();

    // resetting Country map data and filter
    setFilterCountryMap([]);
    setSelectCountry([]);
    resetCountryCheckbox();

    // resetting Members map data and filter
    setMemberId(null);
    setMemberName("");

    // resetting Program map data and filter
    setProgramId(null);

    setParentNCDArr([]);
    setFilterCountryArr([]);//reset country slider

    let isChecked = e.target.checked;
    let value = e.target.value;
    let programArr = [],
      arr = [];
    if (isChecked) {
      if (parentVal === "parent") {
        regionList.forEach((country) => {
          if (country.Region_name === value) {
            let allCountry = country.Countries;
            allCountry.forEach((coun) => {
              arr.push(coun.Country_name);
            });
          }
        });

        selectBeneCountry.splice(0, selectBeneCountry.length);
        selectBeneCountry.push(...arr);
        selectAllFun(value, isChecked, parentVal, "", mobView);
      } else {
        resetBeneCheckbox(value, mobView);
        selectBeneCountry.splice(0, selectBeneCountry.length);
        selectBeneCountry.push(value);
      }
      filterBasedRegion(selectBeneCountry); //calling to filter the tabs on main page
      programList.forEach(function (prog) {
        let program_countries = prog.program_countries;
        if (program_countries && program_countries.length > 0)
          program_countries.map((country) => {
            if (selectBeneCountry.includes(country.Country_name)) {
              programArr.push(prog);
            }
          });
      });
      setFilterBeneficiaryArr([...new Set(programArr)]);
      setFilterCountryBeneMap(selectBeneCountry);
    } else {
      let getIndex = selectBeneCountry.indexOf(value);
      if (getIndex > -1) {
        selectBeneCountry.splice(getIndex, 1);
      }

      filterBasedRegion(selectBeneCountry); //calling to filter the tabs on main page
      programList.forEach(function (prog) {
        let program_countries = prog.program_countries;
        if (program_countries && program_countries.length > 0)
          program_countries.map((country) => {
            if (selectBeneCountry.includes(country.Country_name)) {
              programArr.push(prog);
            }
          });
      });
      setFilterBeneficiaryArr([...new Set(programArr)]);
      if (selectBeneCountry && selectBeneCountry.length < 1) {
        resetTabValues();
        return;
      }
    }
  };

  const filterCountry = (e, parentVal = "", mobView = "") => {
    if (mobView == "") {
      toggle("5"); // active Country tab once filter selected from country dropdown
    }
    //clearing all the filters selection and data
    // resetting NCD map data and filter
    setFilterNCDMap([]);
    setSelectedNcds([]);
    resetNCDCheckbox();

    // resetting Beneficiary map data and filter
    setFilterCountryBeneMap([]);
    setSelectBeneCountry([]);
    setFilterBeneficiaryArr([]);
    resetBeneCheckbox();

    // resetting Members map data and filter
    setMemberId(null);
    setMemberName("");

    // resetting Program map data and filter
    setProgramId(null);

    setParentNCDArr([]);

    let isChecked = e.target.checked;
    let value = e.target.value;
    let programArr = [],
      arr = [];
    if (isChecked) {
      if (parentVal === "parent") {
        regionList2.forEach((country) => {
          if (country.Region_name === value) {
            let allCountry = country.Countries;
            allCountry.forEach((coun) => {
              arr.push(coun.Country_name);
            });
          }
        });

        selectCountry.splice(0, selectCountry.length);
        selectCountry.push(...arr);
        selectAllFun(value, isChecked, parentVal, "countryArr", mobView);
      } else {
        resetCountryCheckbox(value, mobView);
        selectCountry.splice(0, selectCountry.length);
        selectCountry.push(value);
      }
      filterBasedRegion(selectCountry);
      allCountry.forEach(function (prog) {
        let program_countries = prog.Country_name;
        if (selectCountry.includes(program_countries)) {
          programArr.push(prog);
        }
      });
      setFilterCountryArr([...new Set(programArr)]);
      setFilterCountryMap(selectCountry); // to pass values to the country map filter
    } else {
      let getIndex = selectCountry.indexOf(value);
      if (getIndex > -1) {
        selectCountry.splice(getIndex, 1);
      }

      filterBasedRegion(selectCountry);
      allCountry.forEach(function (prog) {
        let program_countries = prog.Country_name;
        if (selectCountry.includes(program_countries)) {
          programArr.push(prog);
        }
      });
      setFilterCountryArr([...new Set(programArr)]);
      if (selectCountry && selectCountry.length === 0) {
        resetTabValues();
        return;
      }
    }
  };

  const filterNCD = (e, parentVal = "", mobView = "") => {
    if (mobView == "") {
      toggle("4"); // acrive NCD tab once filter is clicked/changed
    }
    // resetting Country map data and filter
    setFilterCountryMap([]);
    setSelectCountry([]);
    resetCountryCheckbox();

    // resetting Beneficiary map data and filter
    setFilterCountryBeneMap([]);
    setSelectBeneCountry([]);
    setFilterBeneficiaryArr([]);
    resetBeneCheckbox();

    // resetting Members map data and filter
    setMemberId(null);
    setMemberName("");

    // resetting Program map data and filter
    setProgramId(null);

    setFilterCountryArr([]); //reset country slider

    let isChecked = e.target.checked;
    let value = e.target.value;
    let programArr = [],
      countryArr = [],
      memberArr = [],
      benePrograms = [];
    let programCount,
      countryCount,
      memberCount,
      beneCount = 0,
      arr,
      uniqueVal;

    if (isChecked) {
      if (parentVal === "parent") {
        ncdList.forEach((ncd) => {
          if (ncd.ncd_name === value) {
            arr = [...new Set(ncd["sub types"])];
          }
        });

        parentNCDArr.push(value);
        if (selectNcds.length > 0) {
          // To add only unique values to array
          arr.forEach((unique) => {
            if (!selectNcds.includes(unique)) {
              selectNcds.push(unique);
            }
          });
        } else {
          selectNcds.push(...arr);
        }
        selectAllFun(value, isChecked, "", "", mobView);
      } else {
        selectNcds.push(value);
      }
      // selectNcds.push(value);
      setFilterNCDMap([...new Set(selectNcds)]);
      uniqueVal = [...new Set(selectNcds)];
      programList.forEach(function (prog) {
        let NcdsArr = prog.NCDs;
        if (NcdsArr && NcdsArr.length > 0)
          NcdsArr.map((ncds) => {
            if (uniqueVal.includes(ncds["NCDs sub type"])) {
              programArr.push(prog);
            }
          });
      });

      allCountry.forEach(function (prog) {
        //for country counts
        let NcdsArr = prog.NCDs;
        if (NcdsArr && NcdsArr.length > 0)
          NcdsArr.map((ncds) => {
            if (uniqueVal.includes(ncds["NCDs sub type"])) {
              countryArr.push(prog);
            }
          });
      });

      memberList.forEach(function (prog) {
        //for member counts
        let NcdsArr = prog.NCDs;
        if (NcdsArr && NcdsArr.length > 0)
          NcdsArr.map((ncds) => {
            if (uniqueVal.includes(ncds["NCDs sub type"])) {
              memberArr.push(prog);
            }
          });
      });

      benePrograms = [...new Set(programArr)]; //removing duplicates from program array

      if (benePrograms.length > 0) {
        benePrograms.forEach((prog) => {
          if (prog.catchement_Population_served) {
            beneCount =
              beneCount +
              prog.catchement_Population_served.CatchmentAreas[0]
                .total_pop_served;
          }
        });
      }

      programCount = [...new Set(programArr)];
      countryCount = [...new Set(countryArr)];
      memberCount = [...new Set(memberArr)];
    } else {
      if (parentVal === "parent") {
        ncdList.forEach((ncd) => {
          if (ncd.ncd_name === value) {
            arr = [...new Set(ncd["sub types"])];
          }
        });

        arr.forEach((arrVal) => {
          let getIndex = selectNcds.indexOf(arrVal);
          if (getIndex > -1) {
            selectNcds.splice(getIndex, 1);
          }
        });
        selectAllFun(value, isChecked, "", "", mobView);

        let getIndex = parentNCDArr.indexOf(value);
        if (getIndex > -1) {
          parentNCDArr.splice(getIndex, 1);
        }
      } else {
        let getIndex = selectNcds.indexOf(value);
        if (getIndex > -1) {
          selectNcds.splice(getIndex, 1);
        }

        unSelectParent(value);
      }

      // filterBasedRegion(selectNcds);
      programList.forEach(function (prog) {
        let NcdsArr = prog.NCDs;
        if (NcdsArr && NcdsArr.length > 0)
          NcdsArr.map((ncds) => {
            if (selectNcds.includes(ncds["NCDs sub type"])) {
              programArr.push(prog);
            }
          });
      });

      allCountry.forEach(function (prog) {
        let NcdsArr = prog.NCDs;
        if (NcdsArr && NcdsArr.length > 0)
          NcdsArr.map((ncds) => {
            if (selectNcds.includes(ncds["NCDs sub type"])) {
              countryArr.push(prog);
            }
          });
      });

      memberList.forEach(function (prog) {
        //for member counts
        let NcdsArr = prog.NCDs;
        if (NcdsArr && NcdsArr.length > 0)
          NcdsArr.map((ncds) => {
            if (selectNcds.includes(ncds["NCDs sub type"])) {
              memberArr.push(prog);
            }
          });
      });

      benePrograms = [...new Set(programArr)]; //removing duplicates from program array

      if (benePrograms.length > 0) {
        benePrograms.forEach((prog) => {
          if (prog.catchement_Population_served) {
            beneCount =
              beneCount +
              prog.catchement_Population_served.CatchmentAreas[0]
                .total_pop_served;
          }
        });
      }

      programCount = [...new Set(programArr)];
      countryCount = [...new Set(countryArr)];
      memberCount = [...new Set(memberArr)];

      if (selectNcds && selectNcds.length === 0) {
        resetTabValues();
        return;
      }
    }

    setProgramCount(programCount.length);
    setBeneficiaryCount(TotalVal(beneCount));
    setMemberCount(memberCount.length);
    setNcdCount(selectNcds.length);
    setCountryCount(countryCount.length);
  };

  const filterProgram = (id) => {
    toggle("1"); //toggle to the program tab
    //clearing all the filters selection and data
    // resetting NCD map data and filter
    setFilterNCDMap([]);
    setSelectedNcds([]);
    resetNCDCheckbox();

    // resetting Country map data and filter
    setFilterCountryMap([]);
    setSelectCountry([]);
    resetCountryCheckbox();

    // resetting Beneficiary map data and filter
    setFilterCountryBeneMap([]);
    setSelectBeneCountry([]);
    setFilterBeneficiaryArr([]);
    resetBeneCheckbox();

    // resetting Members map data and filter
    setMemberId(null);
    setMemberName("");
    // filterMember(null,'')
    setFilterCountryArr([]); //reset country slider

    setParentNCDArr([]);

    let getProgram = programList.filter((prog) => {
      return prog.Program_id === id;
    });

    let val = getProgram[0].NCDs
      ? getProgram[0].NCDs.map((pro) => pro["NCDs sub type"])
      : [];

    setProgramId(id);

    setProgramCount(1);
    BeneficiaryPop(getProgram);
    // setBeneficiaryCount(22);
    setMemberCount(
      getProgram[0].Members && getProgram[0].Members.length > 0
        ? getProgram[0].Members.length
        : 0
    );
    setNcdCount(val && val.length > 0 ? [...new Set(val)].length : 0);
    setCountryCount(
      getProgram[0].program_countries &&
        getProgram[0].program_countries.length > 0
        ? getProgram[0].program_countries.length
        : 0
    );
  };

  const filterMember = (id, name) => {
    toggle("3"); //active member tab
    //clearing all the filters selection and data
    // resetting NCD map data and filter
    setFilterNCDMap([]);
    setSelectedNcds([]);
    resetNCDCheckbox();

    // resetting Country map data and filter
    setFilterCountryMap([]);
    setSelectCountry([]);
    resetCountryCheckbox();

    // resetting Beneficiary map data and filter
    setFilterCountryBeneMap([]);
    setSelectBeneCountry([]);
    setFilterBeneficiaryArr([]);
    resetBeneCheckbox();

    // resetting Program map data and filter
    setProgramId(null);

    setParentNCDArr([]);

    setMemberId(id);
    setMemberName(name);
    let filteredMem = memberList.filter((mem) => mem.member_id === id);

    setProgramCount(
      filteredMem[0] && filteredMem[0]["total no of program"]
        ? filteredMem[0]["total no of program"]
        : "NA"
    );
    setBeneficiaryCount(
      filteredMem[0] && filteredMem[0]["benificiary population"]
        ? TotalVal(filteredMem[0]["benificiary population"])
        : "NA"
    );
    setMemberCount(1);
    setNcdCount(
      filteredMem[0] && filteredMem[0].ncd_count
        ? filteredMem[0].ncd_count
        : "NA"
    );
    setCountryCount(
      filteredMem[0] && filteredMem[0].country_count
        ? filteredMem[0].country_count
        : "NA"
    );
  };

  useEffect(() => {
    ProgramDetailCall();

    function handleResize() {
      setDimensions({
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)
    getMapData();
    // return _ => {
    //   window.removeEventListener('resize', handleResize) 
    // }
  }, []);

  useEffect(() => {
    if (navigator.userAgent.indexOf("Macintosh") === -1 && screenOrientation !== 'landscape-primary') {
      swal({
        title: "",
        text: "Please view in landscape mode for better visualization",
        icon: "warning",
        // buttons: false,
        showCancelButton: false,
        closeOnClickOutside: false
      });
      let state = swal.getState();
      state.isOpen = false
    } else {
      let state = swal.getState();
      if (state.isOpen) {
        swal.close();
      }
    }
  }, [screenOrientation])

  const collapsed = (e) => {
    if (e === true) {
      setShowHideDiv(false);
    } else {
      setShowHideDiv(true);
    }
  }

  const callBackFilter = (data,name) => {
    // NetworkChart(data,thameticNames);
    // setNetworkOption({
    //   chart: {
    //       type: 'networkgraph'
    //   },
    //   title: {
    //       text: ""
    //   },
    //   plotOptions: {
    //       networkgraph: {
    //           // keys: ["from", "to"], //Error line
    //           layoutAlgorithm: {
    //               enableSimulation: false,
    //               // friction: -0.9,
    //               integration: "verlet",
    //               linkLength: 80 // in pixels
    //           },
    //           id: 'lang-tree',
    //           // link: {
    //           //     color: 'red'
    //           // }
    //       }
    //   },
    //   credits: {
    //         enabled: false,
    //   },
    //   series: [{
    //       marker: {
    //         radius: 13,
    //       },
    //       dataLabels: {
    //         enabled: true,
    //         allowOverlap: false,
    //         linkFormat: ''
    //       },
    //       data: [
    //           ['Advocacy and communications', 'C/Can'],
    //           ['Advocacy and communications', 'NCDA'],
    //           ['Advocacy and communications', 'WB'],
    //           ['Advocacy and communications', 'WHF'],
  
    //           ['priority-setting', 'WHF'],
    //           ['priority-setting', 'C/Can'],
    //           ['priority-setting', 'WB'],
    //           ['priority-setting', 'PATH'],
    //           // ['priority-setting', 'Funding'],
    //           ['Funding', 'C/Can'],
    //           ['Funding', 'WB'],
    //           ['Funding', 'NCDA'],
    //           // ['Funding', 'Evidence'],
    //           ['Evidence generation', 'C/Can'],
    //           ['Evidence generation', 'PATH'],
    //           ['Evidence generation', 'WHF'],
    //           ['Evidence generation', 'NCDA'],
    //           ['Evidence generation', 'WB'],
  
    //           ['Engagement with PLWNCDs', 'C/Can'],
    //           ['Engagement with PLWNCDs', 'WHF'],
  
    //           ['Strengthening or expansion of health services', 'WHF'],
    //           ['Strengthening or expansion of health services', 'C/Can'],
    //           ['Strengthening or expansion of health services', 'NCDA'],
    //           ['Strengthening or expansion of health services', 'WB'],
    //           ['Strengthening or expansion of health services', 'PATH'],
    //       ],
    //       // ['Advocacy and communications', 'Engagement with PLWNCDs', 'Evidence generation', 'Strengthening or expansion of health services', 'Expansion or strengthening of health services', 'Funding', 'Partnering and stakeholder coordination', 'Priority-setting']
    //       nodes: [
    //           {
    //               id: "priority-setting",
    //               marker: {
    //                   radius: 25
    //               },
    //               color: '#f58b21'
    //           },
    //           {
    //               id: "Evidence generation",
    //               marker: {
    //                   radius: 25
    //               },
    //               color: '#3c69bb'
    //           },
    //           {
    //             id: "Engagement with PLWNCDs",
    //             marker: {
    //                 radius: 25
    //             },
    //             color: '#bced21'
    //           },
    //           {
    //             id: "Strengthening or expansion of health services",
    //             marker: {
    //                 radius: 25
    //             },
    //             color: '#742d7e'
    //           },
    //           {
    //             id: "Funding",
    //             marker: {
    //                 radius: 25
    //             },
    //             color: '#f5ed21'
    //           },
    //           {
    //             id: "Advocacy and communications",
    //             marker: {
    //                 radius: 25
    //             },
    //             color: '#8cc640'
    //           }
    //       ]
    //   }]
    // })
    setFilterIsoCode("")
  }

  const getMapData = () => {
    fetch("https://aaopenplatform.accessaccelerated.org/world.geo.json")
      .then((response) => response.json())
      .then((data) => {
        setGeoJsonData(data);

        fetch(`${apiBaseUrl}getPartnersMap`)
          .then((response) => response.json())
          .then((data) => {
            let thematicNames = [];
            let allData = data[0].json_agg;
            let filterPartners = allData.filter((val) => val.Partners && val.Partners.length > 1 );
            let thematicData = allData.filter((val) => val.Network_diagram_tag && val.Network_diagram_tag.length > 0 );
            thematicData.forEach((val) => {
              if(val.Network_diagram_tag && val.Network_diagram_tag.length > 0) {
                val.Network_diagram_tag.forEach((names) => {
                  if(!thematicNames.includes(names.thematic_area)) {
                    thematicNames.push(names.thematic_area);
                  }
                })
              }
            })
            setThameticDataArr(thematicData);
            setThameticNames(thematicNames);
            // let uniquNames = [...new Set(thematicNames)];
            // console.log("thematicData",thematicData, thematicNames);
            setPartMapData(allData);
            setPartnersData(filterPartners);
            // PartnersList(filterPartners);
            setMapKey(mapKey + 1);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

   // To filter Network Chart
   const callBackFilterNetwork = () => {
   
  }


  return (
    <>
      <Helmet>
        <title>Overview Dashboard - Access Accelerated</title>
        <meta
          name="description"
          content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
        />
        {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
      </Helmet>

      {spinnerLoading ? (
        <div
          style={{
            textAlign: "center",
            height: "calc(100vh - 6.5rem)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "6.5rem",
          }}
        >
          <Loader
            type="TailSpin"
            color="#f26432"
            height={100}
            width={100}
            visible={spinnerLoading}
          />
        </div>
      ) : (
        <div className="overview_dashboard_page">
          <Container fluid={true}>
            <Row>
              <Col lg="12" className="pl-0 pr-0">
                <div>
                  <Container fluid={true} className="filter_header_spacing">
                    <Nav tabs>
                      <NavItem
                        className={classnames({ active: activeTab === "1" })}
                      >
                        <NavLink
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          <div className="header_nav_tabholder_container member_programs">
                            <p className="filter-title">Programs</p>
                            <Row noGutters className="filter_img_text_holder">
                              <Col lg="5" sm="5">
                                <div className="filter-image-holder">
                                  <img
                                    className="stoptblogoimg"
                                    alt="stopTB"
                                    src={imgUrl.member_prog_logo}
                                  />
                                </div>
                              </Col>
                              <Col
                                lg="7" sm="7"
                                className="d-flex align-items-center justify-content-center"
                              >
                                <p className="mb-0 filter-img-description">
                                  {programCount}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </NavLink>
                        <div className="dropdown-list">
                          <Dropdown
                            isOpen={dropdownOpen}
                            toggle={() => toggleDropDown("1")}
                            className="mb-0 filter-dropdown-control dropdown-for-desktop"
                          >
                            <DropdownToggle
                              tag="p"
                              onClick={() => toggleDropDown("1")}
                              data-toggle="dropdown"
                              aria-expanded={dropdownOpen}
                            >
                              <img
                                className="stoptblogoimg dropdown_arrow"
                                alt="stopTB"
                                src={imgUrl.slider_arrow_right}
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <div className="mb-1">
                                <Form>
                                  <FormGroup className="mb-0 searchholder w-100">
                                    <Label for="exampleEmail" hidden>
                                      Email
                                    </Label>

                                    <i className="fas fa-search"></i>
                                    <Input
                                      id="exampleEmail"
                                      name="email"
                                      placeholder="Search"
                                      type="text"
                                      onChange={searchProgramData}
                                    />
                                  </FormGroup>
                                </Form>
                              </div>
                              <div className="prog_list_holder_main">
                                <p
                                  className="mb-1"
                                  onClick={() => {
                                    setSearchProgramList([]);
                                    resetTabValues();
                                  }}
                                >
                                  Show All Programs
                                </p>
                                {searchProgramList &&
                                  searchProgramList.length > 0
                                  ? searchProgramList &&
                                  searchProgramList.length > 0 &&
                                  searchProgramList.map((title) => (
                                    <p
                                      key={title.Program_id}
                                      className="mb-1"
                                      id={title.Program_id}
                                      onClick={() =>
                                        filterProgram(title.Program_id)
                                      }
                                    >
                                      {title.program_name}
                                    </p>
                                  ))
                                  : programList &&
                                  programList.length > 0 &&
                                  programList.map((title) => (
                                    <p
                                      key={title.Program_id}
                                      className="mb-1"
                                      id={title.Program_id}
                                      onClick={() =>
                                        filterProgram(title.Program_id)
                                      }
                                    >
                                      {title.program_name}
                                    </p>
                                  ))}
                              </div>

                              <div className="member_programs_dropdown_uparrow_holder">
                                <img
                                  className="stoptblogoimg dropdown_uparrow"
                                  alt="stopTB"
                                  src={imgUrl.slider_arrow_right}
                                />
                              </div>
                            </DropdownMenu>
                          </Dropdown>
                          <Dropdown
                            isOpen={dropdownOpenMob}
                            // toggle={() => toggleDropDownMob("1")}
                            className="mb-0 filter-dropdown-control dropdown-for-mobile"
                          >
                            <DropdownToggle
                              tag="p"
                              onClick={() => toggleDropDownMob("1")}
                              data-toggle="dropdown"
                              aria-expanded={dropdownOpenMob}
                              className="toggleDropdownTag"

                            >
                              <img
                                className="stoptblogoimg dropdown_arrow"
                                alt="stopTB"
                                src={imgUrl.slider_arrow_right}
                              />
                            </DropdownToggle>

                            <DropdownMenu>
                              <Row>
                                <Col sm="6" className="pr-0 mobile-bg-color-col">
                                  <div className="dropdown-for-mobile dropdown-topHeader">

                                    <Row>
                                      <Col sm="3">
                                        <div className="filter-image-holder member-img-div">
                                          <img
                                            className="stoptblogoimg"
                                            alt="stopTB"
                                            src={imgUrl.member_prog_logo}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <p className="filter-title text-white">
                                          Programs
                                        </p>
                                      </Col>
                                      <Col sm="3">
                                        <p className="mb-0 filter-img-description">
                                          {programCount}
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="mb-1">
                                    <Form>
                                      <FormGroup className="mb-0 searchholder w-100">
                                        <Label for="exampleEmail" hidden>
                                          Email
                                        </Label>

                                        <i className="fas fa-search"></i>
                                        <Input
                                          id="exampleEmail"
                                          name="email"
                                          placeholder="Search"
                                          type="text"
                                          onChange={searchProgramData}
                                          autoComplete="off"
                                        />
                                      </FormGroup>
                                    </Form>
                                  </div>
                                  <div className="prog_list_holder_main">
                                    <p
                                      className="mb-1"
                                      onClick={() => {
                                        setSearchProgramList([]);
                                        resetTabValues();
                                      }}
                                    >
                                      All programs
                                    </p>
                                    {searchProgramList &&
                                      searchProgramList.length > 0
                                      ? searchProgramList &&
                                      searchProgramList.length > 0 &&
                                      searchProgramList.map((title) => (
                                        <p
                                          key={title.Program_id}
                                          className="mb-1"
                                          id={title.Program_id}
                                          onClick={() =>
                                            filterProgram(title.Program_id)
                                          }
                                        >
                                          {title.program_name}
                                        </p>
                                      ))
                                      : programList &&
                                      programList.length > 0 &&
                                      programList.map((title) => (
                                        <p
                                          key={title.Program_id}
                                          className="mb-1"
                                          id={title.Program_id}
                                          onClick={() =>
                                            filterProgram(title.Program_id)
                                          }
                                        >
                                          {title.program_name}
                                        </p>
                                      ))}
                                  </div>
                                </Col>
                                <div className="pl-0">
                                  <div className="member_programs_dropdown_uparrow_holder" onClick={() => {
                                    toggleDropDownMob("1");
                                  }}>
                                    <img
                                      className="stoptblogoimg dropdown_uparrow"
                                      alt="stopTB"
                                      src={imgUrl.slider_arrow_right}
                                    />
                                  </div>
                                </div>
                              </Row>


                            </DropdownMenu>

                          </Dropdown>
                        </div>
                        <div className="active_arrow_holder">
                          <p></p>
                        </div>
                      </NavItem>
                      <NavItem
                        className={classnames({ active: activeTab === "2" })}
                      >
                        <NavLink
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          <div className="header_nav_tabholder_container bene_population">
                            <p className="filter-title">
                              Beneficiary Population
                            </p>
                            <Row noGutters className="filter_img_text_holder">
                              <Col lg="5" sm="5">
                                <div className="filter-image-holder">
                                  <img
                                    className="stoptblogoimg"
                                    alt="stopTB"
                                    src={imgUrl.bene_pop}
                                  />
                                </div>
                              </Col>
                              <Col
                                lg="7" sm="7"
                                className="d-flex align-items-end justify-content-center"
                              >
                                <p className="mb-1 filter-img-description">
                                  {beneficiaryCount.toString()}
                                  {/* <span>{beneficiaryCount.toString().includes('M') ? 'illion' : ''}</span> */}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </NavLink>
                        <div className="dropdown-list">

                          <Dropdown
                            isOpen={dropdownOpen2}
                            toggle={() => toggleDropDown("2")}
                            className="mb-0 filter-dropdown-control dropdown-for-desktop"
                          >
                            <DropdownToggle
                              tag="p"
                              onClick={() => toggleDropDown("2")}
                              data-toggle="dropdown"
                              aria-expanded={dropdownOpen2}
                            >
                              <img
                                className="stoptblogoimg dropdown_arrow"
                                alt="stopTB"
                                src={imgUrl.slider_arrow_right}
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <div className="mb-1">
                                <Form>
                                  <FormGroup className="mb-0 searchholder w-100">
                                    <Label for="beneSearch" hidden>
                                      Email
                                    </Label>
                                    <i className="fas fa-search"></i>
                                    <Input
                                      id="beneSearch"
                                      name="email"
                                      placeholder="Search"
                                      type="text"
                                      onChange={searchBeneficiaryFun}
                                      autoComplete="off"
                                    />
                                  </FormGroup>
                                </Form>
                              </div>
                              <div className="prog_list_holder_main beneficiaryDropdown">
                                <p
                                  className="mb-1"
                                  onClick={() => {
                                    resetTabValues();
                                  }}
                                >
                                  <i className="fas fa-map-marker-alt mr-1"></i>
                                  All included programs
                                </p>
                                {searchBeneficiaryList &&
                                  searchBeneficiaryList.length > 0
                                  ? searchBeneficiaryList &&
                                  searchBeneficiaryList.length > 0 &&
                                  searchBeneficiaryList.map((title) => (
                                    <div className="accordion_list_holder">
                                      <p
                                        className="mb-1"
                                        onClick={() =>
                                          callFun(title.Region_name)
                                        }
                                      >
                                        <i className="fas fa-plus mr-1"></i>
                                        <Input
                                          type="radio"
                                          className={`parentBeneCheckbox ${title.Region_name.replace(
                                            /[^a-zA-Z ]/g,
                                            ""
                                          ).replaceAll(" ", "_")}`}
                                          value={title.Region_name}
                                          onChange={(e) =>
                                            filterBeneficiary(e, "parent")
                                          }
                                        />
                                        <span>{title.Region_name}</span>
                                      </p>
                                      <Collapse
                                        id={title.Region_name.replace(
                                          /[^a-zA-Z ]/g,
                                          ""
                                        ).replaceAll(" ", "_")}
                                      >
                                        <Card>
                                          <CardBody>
                                            {title.Countries &&
                                              title.Countries.length > 0 &&
                                              title.Countries.map(
                                                (country) => (
                                                  <FormGroup
                                                    key={country.Country_name}
                                                    check
                                                  >
                                                    <Label check>
                                                      <Input
                                                        type="radio"
                                                        className={`${country.Country_name.replace(
                                                          /[^a-zA-Z ]/g,
                                                          ""
                                                        ).replaceAll(
                                                          " ",
                                                          "_"
                                                        )}`}
                                                        value={
                                                          country.Country_name
                                                        }
                                                        onChange={(e) =>
                                                          filterBeneficiary(e)
                                                        }
                                                      />
                                                      {country.Country_name}
                                                    </Label>
                                                  </FormGroup>
                                                )
                                              )}
                                          </CardBody>
                                        </Card>
                                      </Collapse>
                                    </div>
                                  ))
                                  : regionList &&
                                  regionList.length > 0 &&
                                  regionList.map((title) => (
                                    <div className="accordion_list_holder">
                                      <p
                                        className="mb-1"
                                        onClick={() =>
                                          callFun(title.Region_name)
                                        }
                                      >
                                        <i className="fas fa-plus mr-1"></i>
                                        <Input
                                          type="radio"
                                          className={`parentBeneCheckbox ${title.Region_name.replace(
                                            /[^a-zA-Z ]/g,
                                            ""
                                          ).replaceAll(" ", "_")}`}
                                          value={title.Region_name}
                                          onChange={(e) =>
                                            filterBeneficiary(e, "parent")
                                          }
                                        />
                                        <span>{title.Region_name}</span>
                                      </p>
                                      <Collapse
                                        id={title.Region_name.replace(
                                          /[^a-zA-Z ]/g,
                                          ""
                                        ).replaceAll(" ", "_")}
                                      >
                                        <Card>
                                          <CardBody>
                                            {title.Countries &&
                                              title.Countries.length > 0 &&
                                              title.Countries.map(
                                                (country) => (
                                                  <FormGroup
                                                    key={country.Country_name}
                                                    check
                                                  >
                                                    <Label check>
                                                      <Input
                                                        type="radio"
                                                        className={`${country.Country_name.replace(
                                                          /[^a-zA-Z ]/g,
                                                          ""
                                                        ).replaceAll(
                                                          " ",
                                                          "_"
                                                        )}`}
                                                        value={
                                                          country.Country_name
                                                        }
                                                        onChange={(e) =>
                                                          filterBeneficiary(e)
                                                        }
                                                      />
                                                      {country.Country_name}
                                                    </Label>
                                                  </FormGroup>
                                                )
                                              )}
                                          </CardBody>
                                        </Card>
                                      </Collapse>
                                    </div>
                                  ))}
                              </div>

                              <div className="bene_population_dropdown_uparrow_holder">
                                <img
                                  className="stoptblogoimg dropdown_uparrow"
                                  alt="stopTB"
                                  src={imgUrl.slider_arrow_right}
                                />
                              </div>
                            </DropdownMenu>
                          </Dropdown>
                          <Dropdown
                            isOpen={dropdownOpenMob2}
                            // toggle={() => toggleDropDownMob("2")}
                            className="mb-0 filter-dropdown-control dropdown-for-mobile"
                          >

                            <DropdownToggle
                              tag="p"
                              onClick={() => toggleDropDownMob("2")}
                              data-toggle="dropdown"
                              aria-expanded={dropdownOpenMob2}
                              className="toggleDropdownTag"
                            >
                              <img
                                className="stoptblogoimg dropdown_arrow"
                                alt="stopTB"
                                src={imgUrl.slider_arrow_right}
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <Row>
                                <Col sm="6" className="pr-0 mobile-bg-color-col">
                                  <div className="dropdown-for-mobile dropdown-topHeader">

                                    <Row>
                                      <Col sm="3">
                                        <div className="filter-image-holder beneficiary-img-div">
                                          <img
                                            className="stoptblogoimg"
                                            alt="stopTB"
                                            src={imgUrl.bene_pop}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <p className="filter-title text-white">
                                          Beneficiary Population
                                        </p>
                                      </Col>
                                      <Col sm="3">
                                        <p className="mb-1 filter-img-description">
                                          {beneficiaryCount.toString()}
                                          {/* <span>{beneficiaryCount.toString().includes('M') ? 'illion' : ''}</span> */}
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="mb-1">
                                    <Form>
                                      <FormGroup className="mb-0 searchholder w-100">
                                        <Label for="exampleEmail" hidden>
                                          Email
                                        </Label>
                                        <i className="fas fa-search"></i>
                                        <Input
                                          id="exampleEmail"
                                          name="email"
                                          placeholder="Search"
                                          type="text"
                                          onChange={searchBeneficiaryFun}
                                          autoComplete="off"
                                        />
                                      </FormGroup>
                                    </Form>
                                  </div>
                                  <div className="prog_list_holder_main beneficiaryDropdown">
                                    <p
                                      className="mb-1"
                                      onClick={() => {
                                        resetTabValues();
                                      }}
                                    >
                                      <i className="fas fa-map-marker-alt mr-1"></i>
                                      All included programs
                                    </p>
                                    {searchBeneficiaryList &&
                                      searchBeneficiaryList.length > 0
                                      ? searchBeneficiaryList &&
                                      searchBeneficiaryList.length > 0 &&
                                      searchBeneficiaryList.map((title) => (
                                        <div className="accordion_list_holder">
                                          <p
                                            className="mb-1"
                                            onClick={() =>
                                              callFun(title.Region_name.concat("mob"))
                                            }
                                          >
                                            <i className="fas fa-plus mr-1"></i>
                                            <Input
                                              type="radio"
                                              className={`parentBeneCheckbox ${title.Region_name.replace(
                                                /[^a-zA-Z ]/g,
                                                ""
                                              ).replaceAll(" ", "_").concat("mob")}`}
                                              value={title.Region_name}
                                              onChange={(e) =>
                                                filterBeneficiary(e, "parent", "mob")
                                              }
                                            />
                                            <span>{title.Region_name}</span>
                                          </p>
                                          <Collapse
                                            id={title.Region_name.replace(
                                              /[^a-zA-Z ]/g,
                                              ""
                                            ).replaceAll(" ", "_").concat("mob")}
                                          >
                                            <Card>
                                              <CardBody>
                                                {title.Countries &&
                                                  title.Countries.length > 0 &&
                                                  title.Countries.map(
                                                    (country) => (
                                                      <FormGroup
                                                        key={country.Country_name.concat("mob")}
                                                        check
                                                      >
                                                        <Label check>
                                                          <Input
                                                            type="radio"
                                                            className={`${country.Country_name.replace(
                                                              /[^a-zA-Z ]/g,
                                                              ""
                                                            ).replaceAll(
                                                              " ",
                                                              "_"
                                                            ).concat("mob")}`}
                                                            value={
                                                              country.Country_name
                                                            }
                                                            onChange={(e) =>
                                                              filterBeneficiary(e, "", "mob")
                                                            }
                                                          />
                                                          {country.Country_name}
                                                        </Label>
                                                      </FormGroup>
                                                    )
                                                  )}
                                              </CardBody>
                                            </Card>
                                          </Collapse>
                                        </div>
                                      ))
                                      : regionList &&
                                      regionList.length > 0 &&
                                      regionList.map((title) => (
                                        <div className="accordion_list_holder">
                                          <p
                                            className="mb-1"
                                            onClick={() =>
                                              callFun(title.Region_name.concat("mob"))
                                            }
                                          >
                                            <i className="fas fa-plus mr-1"></i>
                                            <Input
                                              type="radio"
                                              className={`parentBeneCheckbox ${title.Region_name.replace(
                                                /[^a-zA-Z ]/g,
                                                ""
                                              ).replaceAll(" ", "_").concat("mob")}`}
                                              value={title.Region_name}
                                              onChange={(e) =>
                                                filterBeneficiary(e, "parent", "mob")
                                              }
                                            />
                                            <span>{title.Region_name}</span>
                                          </p>
                                          <Collapse
                                            id={title.Region_name.replace(
                                              /[^a-zA-Z ]/g,
                                              ""
                                            ).replaceAll(" ", "_").concat("mob")}
                                          >
                                            <Card>
                                              <CardBody>
                                                {title.Countries &&
                                                  title.Countries.length > 0 &&
                                                  title.Countries.map(
                                                    (country) => (
                                                      <FormGroup
                                                        key={country.Country_name.concat("mob")}
                                                        check
                                                      >
                                                        <Label check>
                                                          <Input
                                                            type="radio"
                                                            className={`${country.Country_name.replace(
                                                              /[^a-zA-Z ]/g,
                                                              ""
                                                            ).replaceAll(
                                                              " ",
                                                              "_"
                                                            ).concat("mob")}`}
                                                            value={
                                                              country.Country_name
                                                            }
                                                            onChange={(e) =>
                                                              filterBeneficiary(e, "", "mob")
                                                            }
                                                          />
                                                          {country.Country_name}
                                                        </Label>
                                                      </FormGroup>
                                                    )
                                                  )}
                                              </CardBody>
                                            </Card>
                                          </Collapse>
                                        </div>
                                      ))}
                                  </div>
                                </Col>
                                <div className="pl-0">
                                  <div className="bene_population_dropdown_uparrow_holder" onClick={() => {
                                    toggleDropDownMob("2");
                                  }}>
                                    <img
                                      className="stoptblogoimg dropdown_uparrow"
                                      alt="stopTB"
                                      src={imgUrl.slider_arrow_right}
                                    />
                                  </div>
                                </div>
                              </Row>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className="active_arrow_holder">
                          <p></p>
                        </div>
                      </NavItem>
                      <NavItem
                        className={classnames({ active: activeTab === "3" })}
                      >
                        <NavLink
                          onClick={() => {
                            toggle("3");
                          }}
                        >
                          <div className="header_nav_tabholder_container member_companies">
                            <p className="filter-title">Member Companies</p>
                            <Row noGutters className="filter_img_text_holder">
                              <Col lg="5" sm="5">
                                <div className="filter-image-holder">
                                  <img
                                    className="stoptblogoimg"
                                    alt="stopTB"
                                    src={imgUrl.mem_companies}
                                  />
                                </div>
                              </Col>
                              <Col
                                lg="7" sm="7"
                                className="d-flex align-items-center justify-content-center"
                              >
                                <p className="mb-0 filter-img-description">
                                  {memberCount}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </NavLink>
                        <div className="dropdown-list">
                          <Dropdown
                            isOpen={dropdownOpen3}
                            toggle={() => toggleDropDown("3")}
                            className="mb-0 filter-dropdown-control dropdown-for-desktop"
                          >
                            <DropdownToggle
                              tag="p"
                              onClick={() => toggleDropDown("3")}
                              data-toggle="dropdown"
                              aria-expanded={dropdownOpen3}
                            >
                              <img
                                className="stoptblogoimg dropdown_arrow"
                                alt="stopTB"
                                src={imgUrl.slider_arrow_right}
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <div className="prog_list_holder_main member_company_list">
                                {memberList &&
                                  memberList.length > 0 &&
                                  memberList.map((title) => (
                                    <Row
                                      noGutters
                                      className="mb-1"
                                      id={title.member_id}
                                      onClick={() =>
                                        filterMember(
                                          title.member_id,
                                          title.member_name
                                        )
                                      }
                                    >
                                      <Col xs="4" className="border-right-grey">
                                        <p className="mb-0">
                                          <img
                                            className="stoptblogoimg img-fluid"
                                            alt="stopTB"
                                            src={
                                              title.member_dropdown_logo
                                                ? title.member_dropdown_logo
                                                : ""
                                            }
                                          />
                                        </p>
                                      </Col>
                                      <Col xs="8">
                                        <p className="mb-0">
                                          {fullForm(title.member_name)}
                                        </p>
                                      </Col>
                                    </Row>
                                  ))}
                              </div>

                              <div className="member_companies_dropdown_uparrow_holder">
                                <img
                                  className="stoptblogoimg dropdown_uparrow"
                                  alt="stopTB"
                                  src={imgUrl.slider_arrow_right}
                                />
                              </div>
                            </DropdownMenu>
                          </Dropdown>
                          <Dropdown
                            isOpen={dropdownOpenMob3}
                            // toggle={() => toggleDropDownMob("3")}
                            className="mb-0 filter-dropdown-control dropdown-for-mobile"
                          >
                            <DropdownToggle
                              tag="p"
                              onClick={() => toggleDropDownMob("3")}
                              data-toggle="dropdown"
                              aria-expanded={dropdownOpenMob3}
                              className="toggleDropdownTag"
                            >
                              <img
                                className="stoptblogoimg dropdown_arrow"
                                alt="stopTB"
                                src={imgUrl.slider_arrow_right}
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <Row>
                                <Col sm="6" className="pr-0 mobile-bg-color-col">
                                  <div className="dropdown-for-mobile dropdown-topHeader">

                                    <Row>
                                      <Col sm="3">
                                        <div className="filter-image-holder member-comp-img-div">
                                          <img
                                            className="stoptblogoimg"
                                            alt="stopTB"
                                            src={imgUrl.mem_companies}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <p className="filter-title text-white">
                                          Member Companies
                                        </p>
                                      </Col>
                                      <Col sm="3">
                                        <p className="mb-0 filter-img-description">
                                          {memberCount}
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="prog_list_holder_main member_company_list">
                                    {memberList &&
                                      memberList.length > 0 &&
                                      memberList.map((title) => (
                                        <Row
                                          noGutters
                                          className="mb-1"
                                          id={title.member_id}
                                          onClick={() =>
                                            filterMember(
                                              title.member_id,
                                              title.member_name
                                            )
                                          }
                                        >
                                          <Col xs="4" className="border-right-grey">
                                            <p className="mb-0">
                                              <img
                                                className="stoptblogoimg img-fluid"
                                                alt="stopTB"
                                                src={
                                                  title.member_dropdown_logo
                                                    ? title.member_dropdown_logo
                                                    : ""
                                                }
                                              />
                                            </p>
                                          </Col>
                                          <Col xs="8">
                                            <p className="mb-0">
                                              {fullForm(title.member_name)}
                                            </p>
                                          </Col>
                                        </Row>
                                      ))}
                                  </div>
                                </Col>
                                <div className="pl-0">
                                  <div className="member_companies_dropdown_uparrow_holder" onClick={() => toggleDropDownMob("3")}>
                                    <img
                                      className="stoptblogoimg dropdown_uparrow"
                                      alt="stopTB"
                                      src={imgUrl.slider_arrow_right}
                                    />
                                  </div>
                                </div>
                              </Row>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className="active_arrow_holder">
                          <p></p>
                        </div>
                      </NavItem>
                      <NavItem
                        className={classnames({ active: activeTab === "4" })}
                      >
                        <NavLink
                          onClick={() => {
                            toggle("4");
                          }}
                        >
                          <div className="header_nav_tabholder_container add_ncds">
                            <p className="filter-title">
                              Addressed NCD
                              <span className="text-lowercase">s</span>
                            </p>
                            <Row noGutters className="filter_img_text_holder">
                              <Col lg="5" sm="5">
                                <div className="filter-image-holder">
                                  <img
                                    className="stoptblogoimg"
                                    alt="stopTB"
                                    src={imgUrl.add_ncds}
                                  />
                                </div>
                              </Col>
                              <Col
                                lg="7" sm="7"
                                className="d-flex align-items-center justify-content-center"
                              >
                                <p className="mb-0 filter-img-description">
                                  {ncdCount}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </NavLink>
                        <div className="dropdown-list">
                          <Dropdown
                            isOpen={dropdownOpen4}
                            toggle={() => toggleDropDown("4")}
                            className="mb-0 filter-dropdown-control dropdown-for-desktop"
                          >
                            <DropdownToggle
                              tag="p"
                              onClick={() => toggleDropDown("4")}
                              data-toggle="dropdown"
                              aria-expanded={dropdownOpen4}
                            >
                              <img
                                className="stoptblogoimg dropdown_arrow"
                                alt="stopTB"
                                src={imgUrl.slider_arrow_right}
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <div className="mb-1">
                                <Form>
                                  <FormGroup className="mb-0 searchholder w-100">
                                    <Label for="ncdSearchField" hidden>
                                      Email
                                    </Label>
                                    <i className="fas fa-search"></i>
                                    <Input
                                      id="ncdSearchField"
                                      name="email"
                                      placeholder="Search"
                                      type="text"
                                      onChange={searchNCDFun}
                                      autoComplete="off"
                                    />
                                  </FormGroup>
                                </Form>
                              </div>
                              <div className="prog_list_holder_main ncdDropdown">
                                <p
                                  className="mb-1"
                                  onClick={() => {
                                    resetTabValues();
                                  }}
                                >
                                  All NCDs
                                </p>
                                {searchNCDList && searchNCDList.length > 0
                                  ? searchNCDList &&
                                  searchNCDList.length > 0 &&
                                  searchNCDList.map((title) => (
                                    <div className="accordion_list_holder">
                                      <p
                                        className="mb-1"
                                        onClick={() =>
                                          callFun(title.ncd_name)
                                        }
                                      >
                                        <i className="fas fa-plus mr-1"></i>
                                        <Input
                                          type="checkbox"
                                          className={`parentNCDCheckbox ${title.ncd_name
                                            .replace(/[^a-zA-Z ]/g, "")
                                            .replaceAll(" ", "_")}`}
                                          value={title.ncd_name}
                                          onChange={(e) =>
                                            filterNCD(e, "parent")
                                          }
                                        />
                                        <span>{title.ncd_name}</span>
                                      </p>
                                      <Collapse
                                        id={title.ncd_name
                                          .replace(/[^a-zA-Z ]/g, "")
                                          .replaceAll(" ", "_")}
                                      >
                                        <Card>
                                          <CardBody>
                                            {title["sub types"] &&
                                              title["sub types"].length > 0 &&
                                              title["sub types"].map(
                                                (sub_type) => (
                                                  <FormGroup
                                                    key={sub_type}
                                                    check
                                                  >
                                                    <Label check>
                                                      <Input
                                                        type="checkbox"
                                                        value={sub_type}
                                                        onChange={(e) =>
                                                          filterNCD(e)
                                                        }
                                                      />
                                                      {sub_type == 'General Cardiovascular' ? 'General CVDs' : sub_type}
                                                    </Label>
                                                  </FormGroup>
                                                )
                                              )}
                                          </CardBody>
                                        </Card>
                                      </Collapse>
                                    </div>
                                  ))
                                  : ncdList &&
                                  ncdList.length > 0 &&
                                  ncdList.map((title) => (
                                    <div className="accordion_list_holder">
                                      <p
                                        className="mb-1"
                                        onClick={() =>
                                          callFun(title.ncd_name)
                                        }
                                      >
                                        <i className="fas fa-plus mr-1"></i>
                                        <Input
                                          type="checkbox"
                                          className={`parentNCDCheckbox ${title.ncd_name
                                            .replace(/[^a-zA-Z ]/g, "")
                                            .replaceAll(" ", "_")}`}
                                          value={title.ncd_name}
                                          onChange={(e) =>
                                            filterNCD(e, "parent")
                                          }
                                        />
                                        <span>{title.ncd_name}</span>
                                      </p>
                                      <Collapse
                                        id={title.ncd_name
                                          .replace(/[^a-zA-Z ]/g, "")
                                          .replaceAll(" ", "_")}
                                      >
                                        <Card>
                                          <CardBody>
                                            {title["sub types"] &&
                                              title["sub types"].length > 0 &&
                                              title["sub types"].map(
                                                (sub_type) => (
                                                  <FormGroup
                                                    key={sub_type}
                                                    check
                                                  >
                                                    <Label check>
                                                      <Input
                                                        type="checkbox"
                                                        value={sub_type}
                                                        onChange={(e) =>
                                                          filterNCD(e)
                                                        }
                                                      />
                                                      {sub_type == 'General Cardiovascular' ? 'General CVDs' : sub_type}
                                                    </Label>
                                                  </FormGroup>
                                                )
                                              )}
                                          </CardBody>
                                        </Card>
                                      </Collapse>
                                    </div>
                                  ))}
                              </div>

                              <div className="add_ncds_dropdown_uparrow_holder">
                                <img
                                  className="stoptblogoimg dropdown_uparrow"
                                  alt="stopTB"
                                  src={imgUrl.slider_arrow_right}
                                />
                              </div>
                            </DropdownMenu>
                          </Dropdown>
                          <Dropdown
                            isOpen={dropdownOpenMob4}
                            // toggle={() => toggleDropDownMob("4")}
                            className="mb-0 filter-dropdown-control dropdown-for-mobile"
                          >
                            <DropdownToggle
                              tag="p"
                              onClick={() => toggleDropDownMob("4")}
                              data-toggle="dropdown"
                              aria-expanded={dropdownOpenMob4}
                              className="toggleDropdownTag"
                            >
                              <img
                                className="stoptblogoimg dropdown_arrow"
                                alt="stopTB"
                                src={imgUrl.slider_arrow_right}
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <Row>
                                <Col sm="6" className="pr-0 mobile-bg-color-col">
                                  <div className="dropdown-for-mobile dropdown-topHeader">

                                    <Row>
                                      <Col sm="3">
                                        <div className="filter-image-holder address-ncd-img-div">
                                          <img
                                            className="stoptblogoimg"
                                            alt="stopTB"
                                            src={imgUrl.add_ncds}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <p className="filter-title text-white">
                                          Addressed NCD
                                        </p>
                                      </Col>
                                      <Col sm="3">
                                        <p className="mb-0 filter-img-description">
                                          {ncdCount}
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="mb-1">
                                    <Form>
                                      <FormGroup className="mb-0 searchholder w-100">
                                        <Label for="exampleEmail" hidden>
                                          Email
                                        </Label>
                                        <i className="fas fa-search"></i>
                                        <Input
                                          id="exampleEmail"
                                          name="email"
                                          placeholder="Search"
                                          type="text"
                                          onChange={searchNCDFun}
                                          autoComplete="off"
                                        />
                                      </FormGroup>
                                    </Form>
                                  </div>
                                  <div className="prog_list_holder_main ncdDropdown">
                                    <p
                                      className="mb-1"
                                      onClick={() => {
                                        resetTabValues();
                                      }}
                                    >
                                      All NCDs
                                    </p>
                                    {searchNCDList && searchNCDList.length > 0
                                      ? searchNCDList &&
                                      searchNCDList.length > 0 &&
                                      searchNCDList.map((title) => (
                                        <div className="accordion_list_holder">
                                          <p
                                            className="mb-1"
                                            onClick={() =>
                                              callFun(title.ncd_name.concat("mob"))
                                            }
                                          >
                                            <i className="fas fa-plus mr-1"></i>
                                            <Input
                                              type="checkbox"
                                              className={`parentNCDCheckbox ${title.ncd_name
                                                .replace(/[^a-zA-Z ]/g, "")
                                                .replaceAll(" ", "_").concat("mob")}`}
                                              value={title.ncd_name}
                                              onChange={(e) =>
                                                filterNCD(e, "parent", "mob")
                                              }
                                            />
                                            <span>{title.ncd_name}</span>
                                          </p>
                                          <Collapse
                                            id={title.ncd_name
                                              .replace(/[^a-zA-Z ]/g, "")
                                              .replaceAll(" ", "_").concat("mob")}
                                          >
                                            <Card>
                                              <CardBody>
                                                {title["sub types"] &&
                                                  title["sub types"].length > 0 &&
                                                  title["sub types"].map(
                                                    (sub_type) => (
                                                      <FormGroup
                                                        key={sub_type.concat("mob")}
                                                        check
                                                      >
                                                        <Label check>
                                                          <Input
                                                            type="checkbox"
                                                            value={sub_type}
                                                            onChange={(e) =>
                                                              filterNCD(e, "", "mob")
                                                            }
                                                          />
                                                          {sub_type == 'General Cardiovascular' ? 'General CVDs' : sub_type}
                                                        </Label>
                                                      </FormGroup>
                                                    )
                                                  )}
                                              </CardBody>
                                            </Card>
                                          </Collapse>
                                        </div>
                                      ))
                                      : ncdList &&
                                      ncdList.length > 0 &&
                                      ncdList.map((title) => (
                                        <div className="accordion_list_holder">
                                          <p
                                            className="mb-1"
                                            onClick={() =>
                                              callFun(title.ncd_name.concat("mob"))
                                            }
                                          >
                                            <i className="fas fa-plus mr-1"></i>
                                            <Input
                                              type="checkbox"
                                              className={`parentNCDCheckbox ${title.ncd_name
                                                .replace(/[^a-zA-Z ]/g, "")
                                                .replaceAll(" ", "_").concat("mob")}`}
                                              value={title.ncd_name}
                                              onChange={(e) =>
                                                filterNCD(e, "parent", "mob")
                                              }
                                            />
                                            <span>{title.ncd_name}</span>
                                          </p>
                                          <Collapse
                                            id={title.ncd_name
                                              .replace(/[^a-zA-Z ]/g, "")
                                              .replaceAll(" ", "_").concat("mob")}
                                          >
                                            <Card>
                                              <CardBody>
                                                {title["sub types"] &&
                                                  title["sub types"].length > 0 &&
                                                  title["sub types"].map(
                                                    (sub_type) => (
                                                      <FormGroup
                                                        key={sub_type.concat("mob")}
                                                        check
                                                      >
                                                        <Label check>
                                                          <Input
                                                            type="checkbox"
                                                            value={sub_type}
                                                            onChange={(e) =>
                                                              filterNCD(e, "", "mob")
                                                            }
                                                          />
                                                          {sub_type == 'General Cardiovascular' ? 'General CVDs' : sub_type}
                                                        </Label>
                                                      </FormGroup>
                                                    )
                                                  )}
                                              </CardBody>
                                            </Card>
                                          </Collapse>
                                        </div>
                                      ))}
                                  </div>
                                </Col>
                                <div className="pl-0">
                                  <div className="add_ncds_dropdown_uparrow_holder" onClick={() => toggleDropDownMob("4")}>
                                    <img
                                      className="stoptblogoimg dropdown_uparrow"
                                      alt="stopTB"
                                      src={imgUrl.slider_arrow_right}
                                    />
                                  </div>
                                </div>
                              </Row>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className="active_arrow_holder">
                          <p></p>
                        </div>
                      </NavItem>
                      <NavItem
                        className={classnames({ active: activeTab === "5" })}
                      >
                        <NavLink
                          onClick={() => {
                            toggle("5");
                          }}
                        >
                          <div className="header_nav_tabholder_container lmi_country">
                            <p className="filter-title">LMI Countries</p>
                            <Row noGutters className="filter_img_text_holder">
                              <Col lg="5" sm="5">
                                <div className="filter-image-holder">
                                  <img
                                    className="stoptblogoimg"
                                    alt="stopTB"
                                    src={imgUrl.lmi_country}
                                  />
                                </div>
                              </Col>
                              <Col
                                lg="7" sm="7"
                                className="d-flex align-items-center justify-content-center"
                              >
                                <p className="mb-0 filter-img-description">
                                  {countryCount}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </NavLink>
                        <div className="dropdown-list">
                          <Dropdown
                            isOpen={dropdownOpen5}
                            toggle={() => toggleDropDown("5")}
                            className="mb-0 filter-dropdown-control dropdown-for-desktop"
                          >
                            <DropdownToggle
                              tag="p"
                              onClick={() => toggleDropDown("5")}
                              data-toggle="dropdown"
                              aria-expanded={dropdownOpen5}
                            >
                              <img
                                className="stoptblogoimg dropdown_arrow"
                                alt="stopTB"
                                src={imgUrl.slider_arrow_right}
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <div className="mb-1">
                                <Form>
                                  <FormGroup className="mb-0 searchholder w-100">
                                    <Label for="countrySearchField" hidden>
                                      Email
                                    </Label>
                                    <i className="fas fa-search"></i>
                                    <Input
                                      id="countrySearchField"
                                      name="email"
                                      placeholder="Search"
                                      type="text"
                                      onChange={searchCountryFun}
                                      autoComplete="off"
                                    />
                                  </FormGroup>
                                </Form>
                              </div>
                              <div className="prog_list_holder_main countryDropdown">
                                <p
                                  className="mb-1"
                                  onClick={() => {
                                    resetTabValues();
                                  }}
                                >
                                  All countries
                                </p>
                                {searchConuntryList &&
                                  searchConuntryList.length > 0
                                  ? searchConuntryList &&
                                  searchConuntryList.length > 0 &&
                                  searchConuntryList.map((title) => (
                                    <div className="accordion_list_holder">
                                      <p
                                        className="mb-1"
                                        onClick={() =>
                                          callFun(`${title.Region_name}2`)
                                        }
                                      >
                                        <i className="fas fa-plus mr-1"></i>
                                        <Input
                                          type="radio"
                                          className={`parentCountryCheckbox ${title.Region_name.replace(
                                            /[^a-zA-Z ]/g,
                                            ""
                                          ).replaceAll(" ", "_")}2`}
                                          value={title.Region_name}
                                          onChange={(e) =>
                                            filterCountry(e, "parent")
                                          }
                                        />
                                        <span>{title.Region_name}</span>
                                      </p>
                                      <Collapse
                                        id={`${title.Region_name.replace(
                                          /[^a-zA-Z ]/g,
                                          ""
                                        ).replaceAll(" ", "_")}2`}
                                      >
                                        <Card>
                                          <CardBody>
                                            {title.Countries &&
                                              title.Countries.length > 0 &&
                                              title.Countries.map(
                                                (country) => (
                                                  <FormGroup
                                                    key={country.Country_name}
                                                    check
                                                  >
                                                    <Label check>
                                                      <Input
                                                        type="radio"
                                                        className={`${country.Country_name.replace(
                                                          /[^a-zA-Z ]/g,
                                                          ""
                                                        ).replaceAll(
                                                          " ",
                                                          "_"
                                                        )}2`}
                                                        value={
                                                          country.Country_name
                                                        }
                                                        onChange={(e) =>
                                                          filterCountry(e)
                                                        }
                                                      />
                                                      {country.Country_name}
                                                    </Label>
                                                  </FormGroup>
                                                )
                                              )}
                                          </CardBody>
                                        </Card>
                                      </Collapse>
                                    </div>
                                  ))
                                  : regionList2 &&
                                  regionList2.length > 0 &&
                                  regionList2.map((title) => (
                                    <div className="accordion_list_holder">
                                      <p
                                        className="mb-1"
                                        onClick={() =>
                                          callFun(`${title.Region_name}2`)
                                        }
                                      >
                                        <i className="fas fa-plus mr-1"></i>
                                        <Input
                                          type="radio"
                                          className={`parentCountryCheckbox ${title.Region_name.replace(
                                            /[^a-zA-Z ]/g,
                                            ""
                                          ).replaceAll(" ", "_")}2`}
                                          value={title.Region_name}
                                          onChange={(e) =>
                                            filterCountry(e, "parent")
                                          }
                                        />
                                        <span>{title.Region_name}</span>
                                      </p>
                                      <Collapse
                                        id={`${title.Region_name.replace(
                                          /[^a-zA-Z ]/g,
                                          ""
                                        ).replaceAll(" ", "_")}2`}
                                      >
                                        <Card>
                                          <CardBody>
                                            {title.Countries &&
                                              title.Countries.length > 0 &&
                                              title.Countries.map(
                                                (country) => (
                                                  <FormGroup
                                                    key={country.Country_name}
                                                    check
                                                  >
                                                    <Label check>
                                                      <Input
                                                        type="radio"
                                                        className={`${country.Country_name.replace(
                                                          /[^a-zA-Z ]/g,
                                                          ""
                                                        ).replaceAll(
                                                          " ",
                                                          "_"
                                                        )}2`}
                                                        value={
                                                          country.Country_name
                                                        }
                                                        onChange={(e) =>
                                                          filterCountry(e)
                                                        }
                                                      />
                                                      {country.Country_name}
                                                    </Label>
                                                  </FormGroup>
                                                )
                                              )}
                                          </CardBody>
                                        </Card>
                                      </Collapse>
                                    </div>
                                  ))}
                              </div>

                              <div className="lmi_countries_dropdown_uparrow_holder">
                                <img
                                  className="stoptblogoimg dropdown_uparrow"
                                  alt="stopTB"
                                  src={imgUrl.slider_arrow_right}
                                />
                              </div>
                            </DropdownMenu>
                          </Dropdown>
                          <Dropdown
                            isOpen={dropdownOpenMob5}
                            // toggle={() => toggleDropDownMob("5")}
                            className="mb-0 filter-dropdown-control dropdown-for-mobile"
                          >
                            <DropdownToggle
                              tag="p"
                              onClick={() => toggleDropDownMob("5")}
                              data-toggle="dropdown"
                              aria-expanded={dropdownOpenMob5}
                              className="toggleDropdownTag"
                            >
                              <img
                                className="stoptblogoimg dropdown_arrow"
                                alt="stopTB"
                                src={imgUrl.slider_arrow_right}
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              <Row>
                                <Col sm="6" className="pr-0 mobile-bg-color-col">
                                  <div className="dropdown-for-mobile dropdown-topHeader">

                                    <Row>
                                      <Col sm="3">
                                        <div className="filter-image-holder lmi-count-img-div">
                                          <img
                                            className="stoptblogoimg"
                                            alt="stopTB"
                                            src={imgUrl.lmi_country}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <p className="filter-title text-white">
                                          LMI Countries
                                        </p>
                                      </Col>
                                      <Col sm="3">
                                        <p className="mb-0 filter-img-description">
                                          {countryCount}
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="mb-1">
                                    <Form>
                                      <FormGroup className="mb-0 searchholder w-100">
                                        <Label for="exampleEmail" hidden>
                                          Email
                                        </Label>
                                        <i className="fas fa-search"></i>
                                        <Input
                                          id="exampleEmail"
                                          name="email"
                                          placeholder="Search"
                                          type="text"
                                          onChange={searchCountryFun}
                                          autoComplete="off"
                                        />
                                      </FormGroup>
                                    </Form>
                                  </div>
                                  <div className="prog_list_holder_main countryDropdown">
                                    <p
                                      className="mb-1"
                                      onClick={() => {
                                        resetTabValues();
                                      }}
                                    >
                                      All countries
                                    </p>
                                    {searchConuntryList &&
                                      searchConuntryList.length > 0
                                      ? searchConuntryList &&
                                      searchConuntryList.length > 0 &&
                                      searchConuntryList.map((title) => (
                                        <div className="accordion_list_holder">
                                          <p
                                            className="mb-1"
                                            onClick={() =>
                                              callFun(`${title.Region_name.concat("mob")}2`)
                                            }
                                          >
                                            <i className="fas fa-plus mr-1"></i>
                                            <Input
                                              type="radio"
                                              className={`parentCountryCheckbox ${title.Region_name.replace(
                                                /[^a-zA-Z ]/g,
                                                ""
                                              ).replaceAll(" ", "_").concat("mob")}2`}
                                              value={title.Region_name}
                                              onChange={(e) =>
                                                filterCountry(e, "parent", "mob")
                                              }
                                            />
                                            <span>{title.Region_name}</span>
                                          </p>
                                          <Collapse
                                            id={`${title.Region_name.replace(
                                              /[^a-zA-Z ]/g,
                                              ""
                                            ).replaceAll(" ", "_").concat("mob")}2`}
                                          >
                                            <Card>
                                              <CardBody>
                                                {title.Countries &&
                                                  title.Countries.length > 0 &&
                                                  title.Countries.map(
                                                    (country) => (
                                                      <FormGroup
                                                        key={country.Country_name.concat("mob2")}
                                                        check
                                                      >
                                                        <Label check>
                                                          <Input
                                                            type="radio"
                                                            className={`${country.Country_name.replace(
                                                              /[^a-zA-Z ]/g,
                                                              ""
                                                            ).replaceAll(
                                                              " ",
                                                              "_"
                                                            ).concat("mob")}2`}
                                                            value={
                                                              country.Country_name
                                                            }
                                                            onChange={(e) =>
                                                              filterCountry(e, "", "mob")
                                                            }
                                                          />
                                                          {country.Country_name}
                                                        </Label>
                                                      </FormGroup>
                                                    )
                                                  )}
                                              </CardBody>
                                            </Card>
                                          </Collapse>
                                        </div>
                                      ))
                                      : regionList2 &&
                                      regionList2.length > 0 &&
                                      regionList2.map((title) => (
                                        <div className="accordion_list_holder">
                                          <p
                                            className="mb-1"
                                            onClick={() =>
                                              callFun(`${title.Region_name.concat("mob")}2`)
                                            }
                                          >
                                            <i className="fas fa-plus mr-1"></i>
                                            <Input
                                              type="radio"
                                              className={`parentCountryCheckbox ${title.Region_name.replace(
                                                /[^a-zA-Z ]/g,
                                                ""
                                              ).replaceAll(" ", "_").concat("mob")}2`}
                                              value={title.Region_name}
                                              onChange={(e) =>
                                                filterCountry(e, "parent", "mob")
                                              }
                                            />
                                            <span>{title.Region_name}</span>
                                          </p>
                                          <Collapse
                                            id={`${title.Region_name.replace(
                                              /[^a-zA-Z ]/g,
                                              ""
                                            ).replaceAll(" ", "_").concat("mob")}2`}
                                          >
                                            <Card>
                                              <CardBody>
                                                {title.Countries &&
                                                  title.Countries.length > 0 &&
                                                  title.Countries.map(
                                                    (country) => (
                                                      <FormGroup
                                                        key={country.Country_name.concat("mob2")}
                                                        check
                                                      >
                                                        <Label check>
                                                          <Input
                                                            type="radio"
                                                            className={`${country.Country_name.replace(
                                                              /[^a-zA-Z ]/g,
                                                              ""
                                                            ).replaceAll(
                                                              " ",
                                                              "_"
                                                            ).concat("mob")}2`}
                                                            value={
                                                              country.Country_name
                                                            }
                                                            onChange={(e) =>
                                                              filterCountry(e, "", "mob")
                                                            }
                                                          />
                                                          {country.Country_name}
                                                        </Label>
                                                      </FormGroup>
                                                    )
                                                  )}
                                              </CardBody>
                                            </Card>
                                          </Collapse>
                                        </div>
                                      ))}
                                  </div>
                                </Col>
                                <div className="pl-0">
                                  <div className="lmi_countries_dropdown_uparrow_holder" onClick={() => toggleDropDownMob("5")}>
                                    <img
                                      className="stoptblogoimg dropdown_uparrow"
                                      alt="stopTB"
                                      src={imgUrl.slider_arrow_right}
                                    />
                                  </div>
                                </div>
                              </Row>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className="active_arrow_holder">
                          <p></p>
                        </div>
                      </NavItem>
                    </Nav>
                  </Container>
                  <Container fluid={true} className="fullwidth_tabsbg">
                    <div>
                      <Container fluid={true} className="filter_header_spacing">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1">
                            <ProgramSlider
                              key={1}
                              programList={programList ? programList : []}
                              programID={programId ? programId : ""}
                              closeCountryDetail={closeProgramDetail}
                              flterProgSlider={flterProgSlider}
                              isCollapsed={collapsed}
                            />
                          </TabPane>
                          <TabPane tabId="2">
                            <ProgramSlider
                              key={2}
                              programList={
                                filterBeneficiaryArr &&
                                  filterBeneficiaryArr.length > 0
                                  ? filterBeneficiaryArr
                                  : BeneficiaryPrograms
                                    ? BeneficiaryPrograms
                                    : []
                              }
                              closeCountryDetail={closeBeneDetail}
                              isCollapsed={collapsed}
                            />
                          </TabPane>
                          <TabPane tabId="3">
                            <MemberSlider
                              memberList={memberList ? memberList : []}
                              memberID={memberId ? memberId : ""}
                              closeCountryDetail={closeMemberDetail}
                              callParentFun={callBackProg}
                              isCollapsed={collapsed}
                            />
                          </TabPane>
                          <TabPane tabId="4">
                            {/* <NcdDetail /> */}
                            <NcdSlider
                              ncdList={ncdList ? ncdList : []}
                              closeCountryDetail={closeNcdDetail}
                              callParentFun={callBackProg}
                              isCollapsed={collapsed}
                            />
                          </TabPane>
                          <TabPane tabId="5">
                            <CountrySlider
                              countryList={
                                countryList && countryList.length > 0
                                  ? countryList
                                  : []
                              }
                              filterCountryArr={
                                filterCountryArr && filterCountryArr.length > 0
                                  ? filterCountryArr
                                  : []
                              }
                              closeCountryDetail={closeCountryDetail}
                              callParentFun={callBackProg}
                              isCollapsed={collapsed}
                            />
                          </TabPane>
                        </TabContent>
                      </Container>
                    </div>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid={true} className="overview-map-container">
            {showHideDiv &&
              <div className="px-2">
                { !showPartnersMap ? 
                <>
                  <div className="overMapIndiacator">
                    {/* <span
                      className="partnersData"
                      title="Partners Map"
                      onClick={() => goPartnersMap(true)}
                    >
                      Partners Map
                    </span> */}
                    <span
                      className="refreshMap"
                      title="Reset Filters"
                      onClick={() => resetTabValues()}
                    >
                      <i className="fas fa-redo-alt"></i>
                    </span>
                  </div>
                  <OverviewMap
                    parentCallFun={callBackFun}
                    activeTab={activeTab}
                    programId={programId}
                    memberName={memberName}
                    filterCountryMap={filterCountryMap}
                    filterCountryBeneMap={filterCountryBeneMap}
                    filterNCDMap={filterNCDMap}
                    partenersData={thameticDataArr}
                  />
                </> : 
                <Row>
                  <Col lg="12">
                      {Object.keys(geoJsonData).length > 0 && partMapData.length > 0 ?
                        <>
                        <div className="overMapIndiacator remove">
                          <span
                            className="partnersData"
                            title="Partners Map"
                            onClick={() => goPartnersMap(false)}
                          >
                            Remove Partners Map
                          </span>
                        </div>
                        <div id="map-container" style={{position: "relative"}}>
                            <LeafletMap
                              //key={mapKey}
                              mapKey={mapKey}
                              setMapKey={setMapKey}
                              //mapHeight={el.h + 2.375}
                              mapData={partMapData}
                              geoJsonData={geoJsonData}
                              mapView={ "partners" }
                              activeTab={"10"}
                              // parentCall={callback}
                              activeCountry={activeCountry}
                              filterMap={callBackFilter}
                              filterIsoCode={filterIsoCode}
                              filterNetwork={callBackFilterNetwork}
                            />
                        </div>
                      
                        </> : '' }
                  </Col>
                </Row> }
              </div>
            }
          </Container>
          {showHideDiv && activeTab === "1" ? (
            <Container fluid={true} className="">
              <NcdDistribution
                key={updateNCDDist}
                ncdProgramCount={ncdProgramCount}
                ncdNames={ncdNames}
                filterNCDs={filterArrChart}
              />
            </Container>
          ) : (
            ""
          )}
          {showHideDiv && activeTab === "2" ? (
            <Container fluid={true} className="">
              <BeneficiaryDistribution
                totalBene={totalBene}
                partnerTotal={partnerTotal}
                memberTotal={memberTotal}
              />
            </Container>
          ) : (
            ""
          )}
          {showHideDiv && activeTab === "3" ? (
            <Container fluid={true} className="">
              <StrategyDistribution
                key={updateNCDDist}
                strategies={strategies}
                filterStrategies={filterStrategies}
              />
            </Container>
          ) : (
            ""
          )}
          {showHideDiv && activeTab === "4" ? (
            <Container fluid={true} className="">
              <KeyPopulation keyPop={keyPop} />
            </Container>
          ) : (
            ""
          )}
          {showHideDiv && activeTab === "5" ? (
            <Container fluid={true} className="">
              <RegionDistribution regionList={regionList} />
            </Container>
          ) : (
            ""
          )}
          <Container fluid={true} className="">
            <Row>
              <Col lg="12">
                <div className="p-3 d-none d-lg-block d-xl-block d-md-block">
                  <p
                    className="analytical-db-link mb-0 text-right"
                    onClick={() => routeChange("dashboard")}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className="stoptblogoimg"
                      alt="stopTB"
                      src={imgUrl.ab_orange}
                    />
                    Analytical Dashboard
                    <img
                      className="stoptblogoimg ml-2 w-6px"
                      alt="stopTB"
                      src={imgUrl.right_arrow_black}
                    />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      {/* <div className="feedbackIconDiv">
        <a
          href="https://forms.office.com/r/dkKdjFgGeK"
          target="_blank"
        >
          <img
            className="img-fluid"
            alt="runningman"
            src={imgUrl.feedback_icon}
            style={{ width: "90%" }}
          />
        </a>
      </div> */}
    </>
  );
}

export default OverviewDashboard;
