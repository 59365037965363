import { string } from "prop-types";
import React, { useState } from "react";
import { Redirect } from "react-router";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import imgUrl from "../assets/images/imgUrl.js";
import apiBaseUrl from "../config";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login({ setAuth }) {
  const [userName, setUserName] = useState('');
  const [passWord, setUserPass] = useState('');

  const toastBox = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: '',
    }

  const loginUser = async (e) => {
    e.preventDefault();

    if(userName && passWord) {

      try {
           const res = await fetch(`${apiBaseUrl}loginUser`, {
              method: 'POST',
              headers: {
              'Content-Type': 'application/json',
              },
              body: JSON.stringify({userName, passWord}),
          }).then(response => {
              return response.text();
          })
          .then(data => {
              const obj = JSON.parse(data);
              console.log('obj login', obj)
                if ('jwtToken' in obj) {
                  localStorage.setItem("token", obj.jwtToken);
                  localStorage.setItem("userName", userName);
                  localStorage.setItem("DataObj", JSON.stringify(obj));
                  setAuth(true);
                  toast.success("Login Successfully",toastBox);
                } else {
                  toast(obj.res, toastBox);
                  setAuth(false);
                  // toast.error(data);
                }
          });
      } catch(error) {
        console.log('err',error);
        if(error) {
          toast('There was problem with the server',toastBox);
        } else {
            console.log(error.response.data.msg);
        }
      }
      // setInfoMsg(false);           
    } else {
      console.log("Please fill all fields");
      // setInfoMsg(true);
    }
  }

  
  return (
    <>
      <div className="mastheader-holder loginPage">
        <Container>
          <Row>
            
            <Col lg="12">
              <div className="loginForm pb-5">
                <div className="login-card-title">
                  <h3 className="text-center w-50 mx-auto mb-1">Please login</h3>
                  <h3 className="text-center w-50 mx-auto">to upload data</h3>
                </div>
                <Form className="d-block w-75 mx-auto">
                  <FormGroup className="my-4">
                    {/* <Label for="email" className="modalFormLabel">
                      USERNAME*
                    </Label> */}
                    <Input
                      type="text"
                      name="username"
                      id="username"
                      className="side_form_formContol"
                      placeholder="Username"
                      onChange = {e => setUserName(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="my-4">
                    {/* <Label for="email" className="modalFormLabel">
                      Password*
                    </Label> */}
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      className="side_form_formContol"
                      placeholder="Password"
                      autoComplete="off"
                      onChange = {e => setUserPass(e.target.value)}
                    />
                  </FormGroup>
                  <Button className="explorealltempbtn btnexploresmall btn text-white downloadPDF_btn text-uppercase btn-block" onClick={loginUser}>
                    Login
                  </Button>
                </Form>
              </div>
            </Col>
            <Col sm="6" className="mx-auto">
              <div className="logoImageDiv d-flex">
                <img
                  className="stoptblogoimg mx-auto d-block mb-4"
                  alt="logo"
                  src={imgUrl.aalogo}
                  style={{ width: "145px" }}
                />
                <img
                  className="stoptblogoimg mx-auto d-block mb-4"
                  alt="logo"
                  src={imgUrl.dureLogo}
                  style={{ width: "" }}
                />
              </div>
            </Col>
            
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
