import React, { Component, useEffect, useState } from "react";
import { Fetch } from "react-request";
import L from "leaflet";

function ProgramWorldMap({ mapData }) {
  const [geoJsonData, setGeoJsonData] = useState([]); // All Programs Data
  const [mapObj, setMapObj] = useState([]);

  useEffect(() => {
    fetch("https://aaopenplatform.accessaccelerated.org/world.geo.json")
      .then((response) => response.json())
      .then((data) => {
        setGeoJsonData(data);
        loadMap(data);
      });
  }, []);

  const loadMap = (geoData) => {
    // let mymap = L.map('mapid').setView([31.819231,50.752249], 5);//center={[31.819231,50.752249]}

    let mymap = new L.Map("mapid", {
      center: [33.7243396617476, 6.013053729738964],
      zoom: 1,
      minZoom: 1,
      maxZoom: 18,
      doubleClickZoom: false,
      zoomControl: false,
      noWrap: false,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: "topright",
      },
    });

    setMapObj(mymap);

    let layerObj = L.geoJson(geoData, {
      style: mapGeoJsonStyle.bind(this),
      onEachFeature: onEachFeatureMap.bind(this),
    });

    mymap.addLayer(layerObj);
  };

  let mapGeoJsonStyle = (feature) => {
    let mapColor = "#fff";
    if (mapData.includes(feature.properties.iso_a3)) {
      mapColor = "#f26432";
    }

    return {
      fillColor: mapColor,
      weight: 0.5,
      opacity: 1,
      color: "#fff",
      //dashArray: "3",
      fillOpacity: 1,
    };
  };

  const onEachFeatureMap = (feature, layer) => {
    if (feature.properties && feature.properties.name) {
      let toolTipContent = `<b>${feature.properties.name}</b>`;

      layer.bindTooltip(toolTipContent, {
        direction: "auto",
        sticky: true,
      });

      layer.on({
        mouseover: (e) => {
          let thisLayer = e.target;
          thisLayer.setStyle({
            weight: 1.5,
            opacity: 1,
            color: "#fff",
            //dashArray: '',
            fillOpacity: 0.7,
          });
        },
        mouseout: (e) => {
          let thisLayer = e.target;

          thisLayer.setStyle({
            weight: 0.5,
            opacity: 1,
            color: "#fff",
            //dashArray: '',
            fillOpacity: 1,
          });
          //info.update();
        },
        click: function (e) {
          //   console.log(e);
          //   mapDrilldown(e); // commented for Migrant TB
        },
        dblclick: function (e) {
          //console.log('dbl click');
        },
      });
    }
  };

  return <div id="mapid" style={{ width: "100%", height: "250px" }}></div>;
}

export default ProgramWorldMap;
