import React, { useState ,useEffect } from "react";

import { Container, Row, Col, Button } from "reactstrap";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

function StrategyDistribution({ strategies, filterStrategies }) {
    const strategiesData = strategies && strategies.length > 0 ? strategies : [];
    // const filterStrategyArr = filterStrategies && filterStrategies.length > 0 ? filterStrategies : [] 
    const [chartData,setChartData] = useState([]);
    const [chartFilterData,setChartFilterData] = useState([]);
    const colors = ['#045a8d', '#045a8d', '#045a8d', '#2b8cbe', '#2b8cbe', '#74a9cf', '#74a9cf', '#74a9cf', '#a6bddb', '#a6bddb', '#a6bddb', '#d0d1e6'];

    const passData = () => {
        let newArr = [];
        strategiesData.sort((a, b) => {
            return b["no_of_programs"] - a["no_of_programs"];
        });
        strategiesData.map((val,index) => {
            newArr.push({ name: val.Strategy_name, y: val["no_of_programs"], color: colors[index] ? colors[index] : '#d0d1e6' })
        });
        setChartData(newArr);
    }

    const passFilterData = (filterStrategies) => {
        let newArr = [];
        if(filterStrategies[0] && filterStrategies[0].Strategies) {
            const filterArr = filterStrategies[0].Strategies;
            filterArr.sort((a, b) => {
                return b["no_of_programs"] - a["no_of_programs"];
            });
            filterArr.map((val,index) => {
                newArr.push({ name: val.Strategy_name, y: val["no_of_programs"], color: colors[index] ? colors[index] : '#d0d1e6' })
            });
            console.log('newArr filter',newArr)
            setChartFilterData(newArr);
        }
        console.log("newArr",newArr);
    }

    const options = {
        chart: {
            backgroundColor: '#343332',
            type: 'column',
            margin: [60, 120, 120, 120]
        },
        title: {
            text: ''
        },

        xAxis: {
            type: 'category',
            labels: {
                rotation: -45,
                style: {
                    color: '#ffffff',
                    fontSize: '12px',
                    fontFamily: 'BrandonGrotesque-Regular'
                }
            }
        },
        yAxis: {
            min: 0,
            tickInterval: 50,
            title: {
                text: 'No. of programs',
                style: {
                    color: '#ffffff',
                    fontSize: '14px',
                    fontFamily: 'BrandonGrotesque-Regular'
                }
            },
            labels: {
                style: {
                    color: '#ffffff'
                }
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },

        series: [{
            name: 'Number of programs',
            data: chartData ? chartData : [],
            dataLabels: {
                enabled: true,

            }
        }]
    }

    const filterOptions = {
        chart: {
            backgroundColor: '#343332',
            type: 'column',
            margin: [60, 120, 120, 120]
        },
        title: {
            text: ''
        },

        xAxis: {
            type: 'category',
            labels: {
                rotation: -45,
                style: {
                    color: '#ffffff',
                    fontSize: '12px',
                    fontFamily: 'BrandonGrotesque-Regular'
                }
            }
        },
        yAxis: {
            min: 0,
            tickInterval: 50,
            title: {
                text: 'No. of programs',
                style: {
                    color: '#ffffff',
                    fontSize: '14px',
                    fontFamily: 'BrandonGrotesque-Regular'
                }
            },
            labels: {
                style: {
                    color: '#ffffff'
                }
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },

        series: [{
            name: 'Number of programs',
            data: chartFilterData ? chartFilterData : [],
            dataLabels: {
                enabled: true,
            }
        }]
    }

    useEffect(() => {
        passData();
    },[strategies])

    useEffect(() => {
        passFilterData(filterStrategies);
    },[filterStrategies])

    return (
        <>
            <Row>
                <Col lg="12" className="chartHeaderText">
                    <div className="">
                        <p className="chart-title mb-0">PROGRAM DISTRIBUTION BY STRATEGIES</p>
                    </div>
                </Col>
                <Col lg="12" className="chart-holder">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartFilterData && chartFilterData.length ? filterOptions : options}
                    />
                </Col>
            </Row>
        </>
    )
}

export default StrategyDistribution;
