import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./App.css";
import "./assets/css/w3.css";
import "animate.css/animate.min.css";
import "./assets/css/customstyles.css";
import "./assets/css/responsive.css";

//import Routes from "./routes/Routes";
import LandingPage from "./pages/LandingPage";
import ProgramDashboard from "./pages/ProgramDashboard";
import CovidPortal from "./pages/CovidPortal";
import CountryProfiles from "./pages/CountryProfiles";
import NotFound from "./pages/NotFound";
import ProgramProfiles from "./pages/ProgramProfiles";
import ProgramProfile from "./pages/ProgramProfile";
import CountryProfilePage from "./pages/CountryProfilePage";
import MemberProfiles from "./pages/MemberProfiles";
import TopicsProfiles from "./pages/TopicsProfiles";
import PreselectIndicators from "./pages/PreselectIndicators";
import ViewPresetsGallery from "./pages/ViewPresetsGallery";

import ResourcesPage from "./pages/ResourcesPage";
import FaqsPage from "./pages/FaqsPage";
import Login from "./pages/Login";
import UploadData from "./pages/UploadData";
import OverviewDashboard from "./pages/OverviewDashboard";
import PartnerModule  from "./pages/PartnerModule";
import CollectiveImpact from "./pages/CollectiveImpact";

import CookieConsent from "react-cookie-consent";

import AppNavbar from "./components/navbar/AppNavbar";
import SecondaryNavbar from "./components/navbar/SecondaryNavbar";
import SecondaryNavbarNCDOverviewDash from "./components/navbar/SecondaryNavbarNCDOverviewDash";
import Footer from "./components/navbar/Footer";
import imgUrl from "./assets/images/imgUrl.js";
import apiBaseUrl from "./config";
import ModalExample from "./components/ModalExample";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { isAuthenticated: false };
  }

  checkAuthenticated = async () => {
    try {
      const res = await fetch(`${apiBaseUrl}verify`, {
        method: "POST",
        headers: { jwt_token: localStorage.token }
      });

      const parseRes = await res.json();

      parseRes === true ? this.setState({ isAuthenticated: true }) : this.setState({ isAuthenticated: false });
      // if(parseRes === true) {
      //   this.setState({ isAuthenticated: true })
      // } else {
      //   this.setState({ isAuthenticated: false });
      //   alert("You are not authorize");
      // }
      console.log('parseRes',parseRes)
    } catch (err) {
      console.log('verification Error')
      console.error(err.message);
    }
  };

  setAuth = boolean => {
    this.setState({ isAuthenticated: boolean })
  };

  toggleCookiePolicy = (e) => {
    //e.preventDefault();
    // document.getElementById("termsOfUseModal").;
    if (
      document
        .getElementById("cookiePolicyModalOverlay")
        .classList.contains("hide-modal-popup")
    ) {
      document
        .getElementById("cookiePolicyModalOverlay")
        .classList.replace("hide-modal-popup", "show-modal-popup");
    } else {
      document
        .getElementById("cookiePolicyModalOverlay")
        .classList.replace("show-modal-popup", "hide-modal-popup");
    }
  };

  closeCookiesModal = () => {
    document
      .getElementById("cookiePolicyModalOverlay")
      .classList.replace("show-modal-popup", "hide-modal-popup");
  };

  pageReload = () => {
    //Reload function
    window.location.reload();
  };

  componentDidMount() {
    //To reload the page after each 20 mins
    this.interval = setInterval(() => this.pageReload(), 1200000);
    this.checkAuthenticated();
  }

  componentWillUnmount() {
    clearInterval(this.interval); //Clear the intervals
  }

  render() {
    return (
      <div className="App">
        <Helmet>
          <title>Access Accelerated</title>
          <meta
            name="description"
            content="Implementing scalable and sustainable NCD solutions in low and middle-income countries by helping the public and private sectors work better together."
          />
          {/* <meta name="keywords" content="" />
          <meta name="og:title" content="" />
          <meta name="og:url" content="" /> */}
        </Helmet>
        <BrowserRouter basename="/">
          <Switch>
            <Route exact path="/">
              {/* <AppNavbar /> */}
              <LandingPage />
              <Footer />
            </Route>
            <Route path="/home">
              {/* <AppNavbar /> */}
              <LandingPage />
              <Footer />
            </Route>
            <Route path="/programs">
              <LandingPage />
              <Footer />
            </Route>
            <Route path="/members">
              <LandingPage />
              <Footer />
            </Route>
            <Route path="/ncds">
              <LandingPage />
              <Footer />
            </Route>
            <Route path="/countries">
              <LandingPage />
              <Footer />
            </Route>
            {/* <Route path="/program-dashboard">
              <AppNavbar />
              <SecondaryNavbar />
              <ProgramDashboard />
              <Footer />
            </Route> */}
            <Route path="/covid-19-portal">
              <AppNavbar />
              <SecondaryNavbar setAuth={this.setAuth} />
              <CovidPortal />
              <Footer />
            </Route>
            <Route path="/country-profiles">
              <AppNavbar />
              <SecondaryNavbar setAuth={this.setAuth} />
              <CountryProfiles />
              <Footer />
            </Route>

            <Route path="/preselect-indicators">
              {/* <AppNavbar />
              <SecondaryNavbar /> */}
              <PreselectIndicators />
              <Footer />
            </Route>
            <Route path="/view-presets-gallery">
              {/* <AppNavbar />
              <SecondaryNavbar /> */}
              <ViewPresetsGallery />
              <Footer />
            </Route>

            <Route path="/program-profile/:programName">
              <ProgramProfile />
            </Route>

            <Route path="/partner-module">
              <AppNavbar/>
              <SecondaryNavbar/>
              <PartnerModule/>   
              <Footer />
            </Route>

            <Route path="/collective-impact">
              <AppNavbar/>
              <SecondaryNavbar/>
              <CollectiveImpact/>   
              <Footer />
            </Route>


            {/* <Route path="/country-profile-page">
              <AppNavbar />
              <SecondaryNavbar />
              <CountryProfilePage />
              <Footer />
            </Route> */}

            {/* <Route path="/member-profiles">
              <AppNavbar />
              <SecondaryNavbar />
              <MemberProfiles />
              <Footer />
            </Route> */}

            {/* <Route path="/topics-profiles">
              <AppNavbar />
              <SecondaryNavbar />
              <TopicsProfiles />
              <Footer />
            </Route> */}

            {/* <Route path="/resources-page">
              <AppNavbar />
              <SecondaryNavbar />
              <ResourcesPage />
              <Footer />
            </Route> */}

            <Route path="/faqs">
              <AppNavbar />
              <SecondaryNavbar setAuth={this.setAuth} />
              <FaqsPage />
              <Footer />
            </Route>

             <Route path="/overview-dashboard">
              <AppNavbar />
              <SecondaryNavbarNCDOverviewDash setAuth={this.setAuth} />
              <OverviewDashboard />
              <Footer />
            </Route>

            <Route exact path="/login" render={props =>
                !this.state.isAuthenticated ? (
                  <>
                    <AppNavbar />
                    <SecondaryNavbar setAuth={this.setAuth}/>
                    <Login {...props} setAuth={this.setAuth} />
                  </>
                ) : (
                  <Redirect to="/upload-data" />
                )
              } />

            <Route exact path="/upload-data" render={props =>
                !this.state.isAuthenticated ? (
                  <>
                    <AppNavbar />
                    <SecondaryNavbar setAuth={this.setAuth} />
                    <Login {...props} setAuth={this.setAuth} />
                  </>
                ) : (
                  <>
                    <AppNavbar />
                    <SecondaryNavbar setAuth={this.setAuth}/>
                    <UploadData />
                    <Footer />
                  </>
                )
              } />
              
              {/* <Login {...props} setAuth={this.setAuth}/>
            </Route> */}

            {/* <Route path="/upload-data">
              <AppNavbar />
              <SecondaryNavbar />
              <UploadData />
              <Footer />
            </Route> */}

          
            <Route path="*">
              <AppNavbar />
              <SecondaryNavbar setAuth={this.setAuth} />
              <NotFound />
              <Footer />
            </Route>
          </Switch>
        </BrowserRouter>

        {/* <CookieConsent
            location="bottom"
            buttonText="ACCEPT COOKIES"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >This website uses cookies to ensure you get the best experience on our website.</CookieConsent>
           */}
        <CookieConsent
          enableDeclineButton
          location="bottom"
          buttonText="ACCEPT COOKIES"
          declineButtonText="DECLINE"
        >
          <p className="cookies-strip-text">
            This website uses cookies to ensure you get the best experience on
            our website.{" "}
            <a
              className="cookie-policy"
              href={void 0}
              onClick={() => this.toggleCookiePolicy()}
            >
              LEARN MORE
            </a>
          </p>
        </CookieConsent>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
        <div
          className="sidebar-overlay hide-modal-popup"
          id="cookiePolicyModalOverlay"
        >
          <div
            className="w3-sidebar w3-bar-block w3-border-right sidemodalpopup"
            style={{ width: "660px", right: "0" }}
            id="cookiePolicyModal"
          >
            <button
              onClick={() => this.closeCookiesModal()}
              className="w3-bar-item w3-large sidebar-close-btn"
            >
              <img
                className="img-fluid"
                alt="runningman"
                src={imgUrl.close_icon}
                style={{ width: "25px" }}
              />
            </button>
            <div className="sidepopup-content-wrap">
              <h1 className="sidePopup-maintitle">COOKIE POLICY</h1>
              <p className="sidePopup-content">
                Some of our web pages utilise “cookies”. A “cookie” is a small
                text file that may be used, for example, to collect information
                about web site activity. Some cookies and other technologies may
                serve to recall Personal Information previously indicated by a
                web user. Most browsers allow you to control cookies, including
                whether or not to accept them and how to remove them.
              </p>
              <p className="sidePopup-content">
                Some cookies are necessary for the operation of our website, if
                you choose to block them some aspects of the site may not work
                for you. Non necessary cookies are only set when you have given
                your explicit consent to their use. Such cookies included those
                set by our statistics package Google Analytics.
              </p>

              <p className="sidePopup-content">
                We are committed to privacy and support current industry
                initiatives to preserve individual privacy rights on the
                Internet. Protecting your privacy on-line is an evolving area
                and this website will constantly evolve to meet these demands.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
