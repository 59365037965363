import React, { useState, useRef } from "react";

import { Container, Row, Col, Button } from "reactstrap";

import "../../assets/css/customstyles.css";
import "../../assets/css/responsive.css";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import imgUrl from "../../assets/images/imgUrl";

import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";

highchartsMore(Highcharts);
solidGauge(Highcharts);

function BeneficiaryDistribution(props) {
	const totalBene = props.totalBene ? props.totalBene : 0; 
	const partnerTotal = props.partnerTotal ? props.partnerTotal : 0; 
	const memberTotal = props.memberTotal ? props.memberTotal : 0; 

	const TotalVal = (num) => {
		if (num > 999 && num < 1000000) {
			return Math.round((num / 1000).toFixed(1)) + " K"; // convert to K for number from > 1000 < 1 million
		} else if (num > 1000000) {
			return Math.round((num / 1000000).toFixed(1)) + " Million"; // convert to M for number from > 1 million
		} else if (num < 900) {
			return Math.round(num); // if value < 1000, nothing to do
		}
	};

	const options = {
		chart: {
			backgroundColor: '#343332',
			type: "solidgauge",
			height: 225,
			width:250
		},

		title: {
			text: "",
		},

		tooltip: {
			enabled: false,
			borderWidth: 0,
			backgroundColor: "none",
			shadow: false,
			style: {
				fontSize: "16px"
			},
			valueSuffix: "%",
			pointFormat:
				'{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
			positioner: function (labelWidth) {
				return {
					x: (this.chart.chartWidth - labelWidth) / 2,
					y: this.chart.plotHeight / 2 + 15
				};
			}
		},

		pane: {
			startAngle: 0,
			endAngle: 360,
			background: [
				{
					// Track for Move
					outerRadius: '100%',
					innerRadius: '55%',
					backgroundColor: Highcharts.Color('grey')
						.setOpacity(0.3)
						.get(),
					borderWidth: 0
				}
			]
		},

		credits: {
			enabled: false
		},

		yAxis: {
			min: 0,
			max: totalBene,
			lineWidth: 0,
			tickPositions: []
		},

		plotOptions: {
			solidgauge: {
				dataLabels: {
					enabled: false
				},
				linecap: "round",
				stickyTracking: false,
				rounded: false
			}
		},

		series: [
			{
				name: "Move",
				type: "solidgauge",
				data: [
					{
						color: '#35B4BD',
						radius: '100%',
						innerRadius: '55%',
						y: memberTotal
					}
				]
			}
		]
	};

	const options2 = {
		chart: {
			backgroundColor: '#343332',
			type: "solidgauge",
			//height: "90%"
			height: 225,
			width:250
		},

		title: {
			text: "",
		},

		tooltip: {
			enabled: false,
			borderWidth: 0,
			backgroundColor: "none",
			shadow: false,
			style: {
				fontSize: "16px"
			},
			valueSuffix: "%",
			pointFormat:
				'{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
			positioner: function (labelWidth) {
				return {
					x: (this.chart.chartWidth - labelWidth) / 2,
					y: this.chart.plotHeight / 2 + 15
				};
			}
		},

		pane: {
			startAngle: 0,
			endAngle: 360,
			background: [
				{
					// Track for Move
					outerRadius: '100%',
					innerRadius: '55%',
					backgroundColor: Highcharts.Color('grey')
						.setOpacity(0.3)
						.get(),
					borderWidth: 0
				}
			]
		},

		credits: {
			enabled: false
		},

		yAxis: {
			min: 0,
			max: totalBene,
			lineWidth: 0,
			tickPositions: []
		},

		plotOptions: {
			solidgauge: {
				dataLabels: {
					enabled: false
				},
				linecap: "round",
				stickyTracking: false,
				rounded: false
			}
		},

		series: [
			{
				name: "Move",
				type: "solidgauge",
				data: [
					{
						color: '#FFBA18',
						radius: '100%',
						innerRadius: '55%',
						y: partnerTotal
					}
				]
			}
		]
	};

	return (
		<>
			<Row>
				<Col lg="12" className="chartHeaderText">
					<div className="">
						<p className="chart-title mb-0">DISTRIBUTION BY OF THE BENEFICIARY POPULATION</p>
					</div>
				</Col>
				<Col lg="12" className="chart-holder">
					<div className="">
						<Row>
							<Col lg="6">
								<Row>
									<Col lg="7" md="7" sm="7" className="left-description">
										<p className="beneficiary_chart_title">ESTIMATED BENEFICIARY POPULATION (MEMBER)</p>
										<p className="beneficiary_chart_number_stats color-blue">{TotalVal(memberTotal)}</p>
										<p className="beneficiary_chart_description_text">People living within the catchment areas of <br /><span className="color-blue">member company programs</span></p>
									</Col>
									<Col lg="5" md="5" sm="5" className="chart-border">
										<HighchartsReact
											highcharts={Highcharts}
											options={options}
											callback={ function(Highcharts) {
												// Render the Image
												Highcharts.renderer.image(`${imgUrl.bene_pop}`, 105,92,40,40).add();
											  }}
										/>
									</Col>
								</Row>
							</Col>
							<Col lg="6">
								<Row>
									<Col lg="5" md="5" sm="5" style={{ marginTop: "20px" }}>
										<HighchartsReact
											highcharts={Highcharts}
											options={options2}
											callback={ function(Highcharts) {
												// Render the Image
												Highcharts.renderer.image(`${imgUrl.bene_pop}`, 105,92,40,40).add();
											  }}
										/>
									</Col>
									<Col lg="7" md="7" sm="7" className="right-description">
										<p className="beneficiary_chart_title">ESTIMATED BENEFICIARY POPULATION (PARTNER)</p>
										<p className="beneficiary_chart_number_stats color-yellow">{TotalVal(partnerTotal)}</p>
										<p className="beneficiary_chart_description_text">People living within the catchment areas of <br /><span className="color-yellow">partner programs</span></p>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</>
	)
}

export default BeneficiaryDistribution;
